import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { FormControl } from "@mui/material";
import searchcorrect from "../../../src/assets/images/Searchcorrect.png";
import TableWithPagination from "../../components/Notifications/TableWithPagination";
import { filterOptions } from "./data";
import { useFetch } from "hooks/useFetch";
import { BASE_PATH } from "utils";
import usePagination from "hooks/usePagination";

function Notifications() {
  const Image = ({ image }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} size="xs" />
    </MDBox>
  );

  const { page, limit, setPage, setLimit } = usePagination();
  const { data, loading } = useFetch(`${BASE_PATH}/notification?offset=${page * limit}&limit=${limit}`);
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search");
  const type = searchParams.get("type");
  const handleSearch = (value) => {
    searchParams.set("search", value);
    setSearchParams(searchParams);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={"30.5px"} pb={3} mt={0}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mx={2}
                mt={-6}
                py={"17px"}
                px={"24px"}
                bgColor="#227BE9"
                borderRadius="13px"
                coloredShadow="info"
                boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)"}
              >
                <MDTypography color="white" fontSize="20px" fontWeight="medium" lineHeight="24px">
                  Notification
                  {/* | Total : {metadata.totalDocs} */}
                </MDTypography>
                <MDBox
                  px={"20px"}
                  py={"8px"}
                  border="1px solid white"
                  color="white"
                  borderRadius="8px"
                  fontSize="20px"
                  lineHeight="17px"
                  fontWeight="regular"
                  sx={{ cursor: "pointer" }}
                  component={Link}
                  to="/sendnotification/"
                >
                  Send Notification
                </MDBox>
              </MDBox>
              <MDBox pt={3} display="flex" flexDirection="row">
                <MDBox pt={2} display="flex" alignItems="center" mt={-4} py={3} px={2} sx={{ position: "relative" }}>
                  <MDInput
                    value={search}
                    sx={{ backgroundColor: "#ffffff", marginRight: "1rem", width: "100%" }}
                    type="search"
                    label="Enter ID"
                    // onFocus={handleInputFocus}
                    onChange={(e) => handleSearch(e.target.value)}
                    multiline
                    required
                    mr={"0px !important"}
                  />
                  <MDButton
                    disabled={!search}
                    onClick={handleSearch}
                    variant="gradient"
                    color="dark"
                    sx={{ position: "absolute", right: "10px", padding: "0", minWidth: "3.3rem", minHeight: "2.8rem", opacity: "1 !important", cursor: "pointer" }}
                  >
                    <Image image={searchcorrect} />
                    &nbsp;
                  </MDButton>
                </MDBox>
                <MDBox pt={2} display="flex" alignItems="center" mt={-4} py={3}>
                  <MDTypography color="#19114A" lineHeight="17px" sx={{ fontWeight: 600 }} mr={"12px"} fontSize="15px" ml={"5px"}>
                    Filter
                  </MDTypography>
                  <FormControl>
                    {/* <FormLabel id="demo-row-radio-buttons-group-label">Filter Users</FormLabel> */}
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      {filterOptions.map((f) => (
                        <div key={f.value} style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                          <input
                            checked={type ? type === f.value : f.value === "all"}
                            type="radio"
                            onChange={() => {
                              searchParams.set("type", f.value);
                              setSearchParams(searchParams);
                            }}
                            id={f.value}
                            style={{ width: "16px", height: "16px", cursor: "pointer" }}
                          />
                          <label style={{ fontSize: "13px", color: "#19114A", fontWeight: "400", cursor: "pointer" }} htmlFor={f.value}>
                            {f.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </FormControl>
                </MDBox>
              </MDBox>
              <TableWithPagination type={type} loading={loading} setPage={setPage} setLimit={setLimit} page={page} limit={limit} search={search} data={data} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Notifications;
