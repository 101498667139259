import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import App from "App";
// import { registerLicense } from "@syncfusion/ej2-base";

import { MaterialUIControllerProvider } from "context";
// registerLicense(
//   "Mgo+DSMBaFt+QHFqVkNrWU5Fc0BAXWFKblF8RmJTflhgFChNYlxTR3ZbQ1hiTX1Sd0JiXH5W;Mgo+DSMBPh8sVXJ1S0d+X1RPc0BDWHxLflF1VWFTfF96dVNWACFaRnZdQV1nSHtSckZiWHhfdnJd;ORg4AjUWIQA/Gnt2VFhhQlJBfVpdXGZWfFN0RnNbdV1xflRAcDwsT3RfQF5jTX9Wd0FgWH5ZcXxVTg==;MTczNTQzMUAzMjMxMmUzMTJlMzMzNVBwakZSeVdsUDd6eWcyelRaSXNMS25GeXpXd2xDa0pwaitqeGFpUjZvdk09;MTczNTQzMkAzMjMxMmUzMTJlMzMzNWxMaU5kT1lOMzJYZzdrM3F4OXl0b3d0dkJVTm5oaDdaK0NqTnZaZlNRc3M9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RHQmJMYVF2R2BJfVRzdV9HZkwxOX1dQl9gSXpSc0RnWn5fd3ZWT2g=;MTczNTQzNEAzMjMxMmUzMTJlMzMzNUZRTmhlSFhRVDBmait5T05qVjg5bWV4Um05S3N3cWQyRU9FeGtIb2Y0N1U9;MTczNTQzNUAzMjMxMmUzMTJlMzMzNUxMb0Njekg3Q0JUVllGT2dIVDhNYzBBRi9SYVpTbGxaOTlZbi9DVkhlcm89;Mgo+DSMBMAY9C3t2VFhhQlJBfVpdXGZWfFN0RnNbdV1xflRAcDwsT3RfQF5jTX9Wd0FgWH5Zcn1QTg==;MTczNTQzN0AzMjMxMmUzMTJlMzMzNWpreGtBYVZ3cjVNR1ZpOTZOL2pYdzUrd3ZGZU4xVTlydGVub0ZwclRIZUk9;MTczNTQzOEAzMjMxMmUzMTJlMzMzNVprbmlIa1hTWldKd0hwZjZIVHE0TGxEVnpESm80OHFNSFp0cEtUbmJiOUE9;MTczNTQzOUAzMjMxMmUzMTJlMzMzNUZRTmhlSFhRVDBmait5T05qVjg5bWV4Um05S3N3cWQyRU9FeGtIb2Y0N1U9"
// );

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Toaster />
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
