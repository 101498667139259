import { Box, MenuItem, Pagination, Select, Skeleton, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { supportTabs, timingOptions } from "../data";
import moment from "moment";
// import range from "utils/range";
// import { ArrowForwardIos } from "@mui/icons-material";

const ChatList = ({ data, loading, limit, handleSetChat, tab, setTab, search, setSearch, timing, setTiming, setPage }) => {
  // const pages = range(1, 4);
  // const [selectedPage, setSelectedPage] = useState(pages[0]);
  const loaderArray = new Array(limit).fill("a");
  const chats = data?.data?.docs;
  useEffect(() => {
    if (chats?.length) {
      handleSetChat(chats?.[0]?._id);
    }
  }, [chats]);

  const calculateStartDate = (timing) => {
    const now = new Date();
    const startDate = new Date(now);

    if (timing.endsWith("d")) {
      const days = parseInt(timing, 10);
      startDate.setDate(startDate.getDate() - days);
    } else if (timing.endsWith("m")) {
      const months = parseInt(timing, 10);
      startDate.setMonth(startDate.getMonth() - months);
    }

    return startDate;
  };
  const getChatList = () => {
    let chatList = [];
    if (chats) {
      chatList = chats;
    }
    if (tab === "priority") {
      const high = chatList.filter((c) => c.priority === "high");
      const medium = chatList.filter((c) => c.priority === "medium");
      const low = chatList.filter((c) => c.priority === "low");
      chatList = [...high, ...medium, ...low];
    }
    if (tab === "closed") {
      const closedChats = chatList.filter((c) => c.status === "closed");
      chatList = closedChats;
    }
    if (search) {
      const matched = chatList.filter((ch) => ch?.title?.toLowerCase()?.includes(search?.toLowerCase()));
      chatList = matched;
    }
    if (timing) {
      const startDate = calculateStartDate(timing);
      const filteredChats = chatList.filter((c) => {
        const createdAt = new Date(c?.createdAt);
        if (createdAt >= startDate) {
          return c;
        }
      });
      chatList = filteredChats;
    }
    return chatList;
  };
  console.log(getChatList());
  return (
    <div className="chatList">
      <Box sx={{ width: "100%", padding: "0 10px", borderBottom: "1px solid #8d8888", height: "72px" }}>
        <Stack direction={"row"} spacing={"25px"} height={"72px"} alignItems={"flex-end"}>
          {supportTabs.map((f) => {
            return (
              <Box onClick={() => setTab(f.value)} sx={{ cursor: "pointer" }} key={f.label}>
                <Typography className="font-inter" sx={{ color: "#19114A", fontSize: "12px", fontStyle: "normal", fontWeight: "500", lineHeight: "normal", marginBottom: "4px" }}>
                  {f.label}
                </Typography>
                <Box sx={{ height: "4px", width: "100%", background: tab === f.value ? "#227BE9" : "transparent" }}></Box>
              </Box>
            );
          })}
        </Stack>
      </Box>
      <Stack sx={{ margin: "13px 10px 10px 10px" }} direction={"row"} spacing={"22px"}>
        <Box display="flex" alignItems="center" sx={{ position: "relative" }}>
          <TextField
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Enter email"
            inputProps={{
              style: {
                fontSize: "14px",
                paddingLeft: "10px",
                paddingRight: "20px",
                borderRadius: "8px",
              },
            }}
          />
          <Box sx={{ background: "#292929", borderRadius: "8px", width: "47px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "-10px", zIndex: "9" }}>
            <img src="images/search-correct.png" />
          </Box>
        </Box>{" "}
        <Select value={timing} onChange={(e) => setTiming(e.target.value)}>
          {timingOptions.map((t, i) => (
            <MenuItem
              sx={{
                background: "white",
                color: "black",
                "&:hover": {
                  background: "#f5f5f5",
                },
              }}
              key={i}
              value={t.value}
            >
              Last {t.count} {t.unit}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Box sx={{ overflowY: "auto", flexGrow: "1" }}>
        {loading ? (
          loaderArray.map((l, i) => {
            return <Skeleton key={i} variant="rectangular" sx={{ width: "100%", height: "80px", background: i % 2 === 0 ? "#F5F5F5" : "#FFFFFF" }} />;
          })
        ) : getChatList().length ? (
          getChatList()?.map((chat, i) => {
            return (
              <Box
                onClick={() => {
                  handleSetChat(chat._id);
                }}
                key={i}
                sx={{ width: "100%", padding: "6px 10px", cursor: "pointer", background: i % 2 === 0 ? "#F5F5F5" : "#FFFFFF" }}
              >
                <Typography
                  className="font-inter"
                  sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", fontSize: "14px", color: "#054089", fontWeight: "500", marginBottom: "2px" }}
                >
                  {chat.subject}
                </Typography>
                <Typography className="font-inter" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", fontSize: "12px", color: "#000", fontWeight: "500", marginBottom: "2px" }}>
                  Ticket ID: #{chat._id}
                </Typography>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography className="font-inter" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", fontSize: "11px", color: "#292929", fontWeight: "300" }}>
                    {moment(chat.createdAt).format("ddd, DD MMM [at] h:mm A")}
                  </Typography>
                  <Typography className="font-inter" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", fontSize: "13px", color: "#292929", fontWeight: "400" }}>
                    Status: <span style={{ color: "#119226" }}>{chat.status}</span>
                  </Typography>
                </Stack>
              </Box>
            );
          })
        ) : (
          <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography sx={{ fontSize: "18px" }}>No tickets found</Typography>
          </Box>
        )}
      </Box>
      {/* <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} padding={"10px"} gap={"10px"} classes={{ root: "chat-list-chats" }}>
        {pages.map((p) => (
          <Box
            onClick={() => setSelectedPage(p)}
            key={p}
            sx={{
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: selectedPage === p ? "#227BE9" : "#FFF",
              color: selectedPage === p ? "#FFF" : "#000",
              borderRadius: "50%",
              border: `1px solid ${selectedPage === p ? "#227BE9" : "#E1D9D9"}`,
              gap: "10px",
              fontSize: "15px",
              cursor: "pointer",
            }}
          >
            {p}
          </Box>
        ))}
        <Box
          sx={{
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#FFF",
            color: "#000",
            borderRadius: "50%",
            border: `1px solid #E1D9D9`,
            gap: "10px",
            fontSize: "15px",
            cursor: "pointer",
          }}
        >
          <ArrowForwardIos />
        </Box>
      </Stack> */}
      <Box sx={{ padding: "10px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <Pagination
          onChange={(e, v) => setPage(v - 1)}
          count={data?.data?.totalPages || 10}
          size="small"
          variant="outlined"
          siblingCount={1}
          boundaryCount={1}
          color="primary"
          sx={{
            gap: "10px",
            "& .Mui-selected": {
              backgroundColor: "#227BE9 !important",
              color: "#fff !important",
              borderColor: "#227BE9 !important",
            },
          }}
        />
      </Box>
    </div>
  );
};

export default ChatList;
