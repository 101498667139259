import { TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

export default function InputController ({ control, name, errors, rules, InputLabelProps, value, required, placeholder, id, style, variant, label, multiline, rows, handleOnChange = () => null }) {
  return (
    <div>
      <Controller
        control={control}
        name={name}
        value={value ?? ""}
        rules={{
          ...rules,
          required: required ? "This field is required." : false,
        }}
        render={({ field: { onChange, value, ref } }) => (
          <TextField
            placeholder={placeholder ?? ""}
            error={!!errors[name]}
            onChange={(e) => {
              onChange(e.target.value);
              handleOnChange(e.target.value);
            }}
            multiline={multiline}
            rows={rows}
            value={value}
            ref={ref}
            InputLabelProps={InputLabelProps}
            variant={variant}
            style={style}
            id={id}
            label={label}
            helperText={!!errors[name] ? errors[name]?.message : ""}
          />
        )}
      />
    </div>
  );
};
