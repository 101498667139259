import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { FormControlLabel, Radio, RadioGroup, Skeleton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from "@mui/material";
import usePagination from "hooks/usePagination";
import EditIcon from "@mui/icons-material/Edit";
import { useFetch } from "hooks/useFetch";
import { BASE_PATH } from "utils";
import getFullName from "utils/getFullName";
import moment from "moment";
import { axiosRequestPromise } from "Apis";
import { Toast } from "components/common/Toast";

const rowsPerPageOptions = [10, 25, 50];
function AllUsers() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const [toastData, setToastData] = useState({ type: "", message: "", open: false });
  const { page, limit, setPage, setLimit } = usePagination();
  const { data, loading, refetch } = useFetch(`${BASE_PATH}/users?limit=${limit}&offset=${limit * page}`, { refetch: null });
  const users = data?.data?.docs;
  const navigate = useNavigate();
  const filters = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Editor",
      value: "editor",
    },
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Super Admin",
      value: "super_admin",
    },
  ];
  const filter = searchParams.get("filter") || filters[0].value;

  // const Image = ({ image }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     <MDAvatar src={image} sx={{ width: "46px", height: "46px" }} />
  //   </MDBox>
  // );

  const headers = [
    {
      type: "text",
      title: "Name",
    },
    {
      type: "text",
      title: "Email",
    },
    {
      type: "text",
      title: "Role",
    },
    {
      type: "text",
      title: "Last Active",
    },
    {
      type: "text",
      title: "Status",
    },
  ];

  const loaderArray = new Array(limit).fill(0);

  const getUsersList = () => {
    let _users = users;
    if (search) {
      const newUsers = _users.filter((u) => {
        const isEmail = u.email.toLowerCase().includes(search.toLowerCase());
        const isName = getFullName(u.first_name, u.last_name).toLowerCase().includes(search.toLowerCase());
        return isEmail || isName;
      });
      _users = newUsers;
    } else if (filter) {
      const filteredUsers = _users.filter((u) => {
        if (filter === "all") {
          return u;
        }
        if (u.role === filter) {
          return u;
        }
      });
      _users = filteredUsers;
    } else {
      _users = users;
    }
    return _users;
  };

  const handleDisableEnableUser = async (user) => {
    try {
      const actionType = user.is_disabled ? "enable" : "disable";
      if (actionType === "disable") {
        return navigate(`/settings/disable-user/${user._id}`);
      }
      const res = await axiosRequestPromise({ url: `${BASE_PATH}/users/${user._id}/toggle-disable-enable`, body: { value: "enable" }, method: "post" });
      if (res.data.success) {
        setToastData({ open: true, message: `User enabled successfully`, type: "success" });
        refetch();
      }
    } catch (error) {
      setToastData({ open: true, message: error?.response?.data?.message, type: "success" });
    }
  };

  return (
    <DashboardLayout>
      <Toast open={toastData.open} type={toastData.type} message={toastData.message} onClose={() => setToastData({ message: "", open: false, type: "" })} />
      <DashboardNavbar />
      <MDBox pt={"30.5px"} pb={3} mt={0}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mx={2}
                mt={-6}
                py={"27px"}
                px={"24px"}
                bgColor="#227BE9"
                borderRadius="13px"
                coloredShadow="info"
                boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)"}
              >
                <MDTypography color="white" fontSize="20px" fontWeight="medium" lineHeight="24px">
                  All Users and Role | Total : {data?.data.totalDocs}
                </MDTypography>
              </MDBox>
              <MDBox sx={{ padding: "20px", display: "flex", alignItems: "center", justifyContent: "start" }}>
                <div style={{ position: "relative", width: "fit-content" }}>
                  <MDInput
                    onChange={(e) => setSearch(e.target.value)}
                    id="search_input"
                    sx={{ backgroundColor: "#ffffff", width: 290 }}
                    type="search"
                    multiline
                    required
                    placeholder="Enter Name or Email*"
                    mr={"0px !important"}
                    fullWidth
                  />
                  <MDButton variant="gradient" color="dark" sx={{ position: "absolute", right: "0px", padding: "0", minWidth: "3.3rem", minHeight: "2.8rem", opacity: "1 !important" }}>
                    <img src={"images/search-correct.png"} style={{ width: "24px", height: "24px" }} />
                  </MDButton>
                </div>
                <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "20px" }}>
                  <Typography sx={{ fontSize: "18px", fontWeight: "500", marginLeft: "18px", marginRight: "12px" }}>Filter</Typography>
                  <RadioGroup
                    value={filter}
                    onChange={(e) => {
                      searchParams.set("filter", e.target.value);
                      setSearchParams(searchParams);
                    }}
                    sx={{ display: "flex", flexDirection: "row", gap: "9px", alignItems: "center" }}
                  >
                    {filters.map((f) => {
                      return (
                        <FormControlLabel
                          sx={{ fontSize: "13px", fontWeight: "400" }}
                          key={f.label}
                          control={<Radio sx={{ padding: "0", marginRight: "3px", width: "14px", height: "14px" }} />}
                          value={f.value}
                          label={f.label}
                        />
                      );
                    })}
                  </RadioGroup>
                </MDBox>
              </MDBox>

              <TableContainer sx={{ padding: "0 20px" }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      {headers.map((h, i) => (
                        <TableCell key={i}>
                          <Typography sx={{ fontSize: "15px", fontWeight: "700", color: "#19114A" }}>{h.title}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                    {loading
                      ? loaderArray.map((l, i) => (
                          <TableRow key={i}>
                            {headers.map((h, i) => {
                              const variant = h.type === "image" ? "circular" : "rounded";
                              const styles = h.type === "image" ? { width: "30px", height: "30px" } : { width: "100%", height: "20px" };
                              return (
                                <TableCell key={i}>
                                  <Skeleton animation="wave" sx={styles} variant={variant} />
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))
                      : getUsersList()?.map((user, index) => {
                          return (
                            <TableRow
                              sx={{
                                "&:hover": {
                                  background: "#f5f5f5",
                                },
                              }}
                              key={index}
                            >
                              <TableCell>
                                <Typography sx={{ color: "#49A3F1", fontSize: "14px", fontWeight: "700" }}>{getFullName(user.first_name, user.last_name)}</Typography>
                              </TableCell>
                              <TableCell sx={{ maxWidth: "230px" }}>
                                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>{user.email}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>{user.role}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>{moment(user.last_login?.updatedAt).fromNow()}</Typography>
                              </TableCell>
                              <TableCell sx={{ width: "fit-content", display: "flex", alignItems: "center", justifyContent: "start", gap: "21px", marginRight: "-45px" }}>
                                <Typography onClick={() => handleDisableEnableUser(user)} style={{ fontSize: "14px", fontWeight: "700", color: "#19114A", cursor: "pointer", width: "70px" }}>
                                  {user.is_disabled ? "ENABLE" : "DISABLE"}
                                </Typography>
                                <div onClick={() => navigate(`/settings/edit-user/${user._id}`)}>
                                  <EditIcon />
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* need to implement with pagination common component */}
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={data?.data?.totalDocs}
                rowsPerPage={limit}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={(e) => {
                  setLimit(e.target.value);
                }}
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AllUsers;
