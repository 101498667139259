import React from "react";

const CategoryDropdown = ({ data, changeCategory }) => {
  return (
    <div style={{ background: "white", display: "flex", flexDirection: "column", marginLeft: `${!data.parent ? "5px" : "20px"}`, maxWidth: "fit-content", minWidth: "100%" }}>
      <p className="dropdown_category" onClick={() => changeCategory(data._id, data.name)}>
        {data.name}
      </p>
      {data?.children.length ? data.children?.map((el) => <CategoryDropdown key={el._id} data={el} changeCategory={changeCategory} />) : ""}
    </div>
  );
};

export default CategoryDropdown;
