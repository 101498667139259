import { createContext } from "react";

export const UserContext = createContext({
  valueC: false,
  setValueC: () => {},
  skills: [],
  setSkills: () => {},
  state: false,
  setState: () => {},
  pagination: [],
  setPagination: () => {},
});
