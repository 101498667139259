import axios from "axios";
import store from "store";
import keys from "./config";

const api = axios.create({ baseURL: `${keys.API_BASE_URL}/api` });
api.interceptors.request.use((config) => {
  const token = store.get("token");

  if (config.url.includes("/auth")) {
    return config;
  }

  if (!token) {
    throw new Error("token not found");
  }

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export const blogApi = axios.create({ baseURL: `${keys.API_BASE_URL}` });

blogApi.interceptors.request.use((config) => {
  const token = store.get("token");

  if (config.url.includes("/auth")) {
    return config;
  }

  if (!token) {
    throw new Error("token not found");
  }

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default api;

// export const get = (path, params = {}, headers = {}) =>
//   api.get(path, { params, headers }).then((res) => res.data)

// export const post = (path, payload, params = {}, headers = {}) =>
//   api.post(path, payload, { params, headers }).then((res) => res.data.response)

// export const put = (path, payload, params = {}, headers = {}) =>
//   api.put(path, payload, { params, headers }).then((res) => res.data.response)

// export const patch = (path, payload, params = {}, headers = {}) =>
//   api.patch(path, payload, { params, headers }).then((res) => res.data.response)

// export const del = (path, params = {}, headers = {}) =>
//   api.delete(path, { params, headers }).then((res) => res.data.response)
