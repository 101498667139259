export const styles = {
  deleteButton: {
    padding: "7.5px 50px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)",
    marginTop: "20px",
    marginBottom: "-20px",
  },
  deleteIcon: {
    marginLeft: -10,
    textAlign: "center",
    marginTop: -3,
  },
  notificationTab: {
    backgroundColor: "#3A94EE",
    marginRight: "11px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)",
    borderRadius: "10px",
    width: "153px",
    padding: "14px 10px",
  },
  notificationTabText: {
    color: "white",
    fontSize: "15px",
    lineHeight: "18px",
  },
  freeLancerTab: {
    backgroundColor: "#E2E2E2",
    color: "#292929",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1), 0px 7px 10px -5px rgba(0, 0, 0, 0.17)",
    borderRadius: "10px",
    width: "203px",
    padding: "14px 0px",
  },
  freeLancerTabText: {
    color: "#292929",
    fontSize: "15px",
    lineHeight: "18px",
  },
};
