import React, { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MDButton from "components/MDButton";
import Tablecell from "./Tablecell";
import InsertLinkModal from "./InsertLinkModal";
import { toast } from "react-hot-toast";

const DynamicTable = ({ data, getTableData }) => {
  const [tableData, setTableData] = useState([]);
  const [columnCount, setColumnCount] = useState(data[0].length);
  const [addRow, setAddRow] = useState(0);
  const [addCol, setAddCol] = useState(0);
  const [addLinkModal, setAddLinkModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [maxcol, setMaxCol] = useState(0);
  const handleCellSelect = (row, col, data) => {
    setSelectedCell({ row, col, data });
  };
  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    let maximum = 0;
    tableData.forEach((el) => {
      if (el.length > maximum) {
        maximum = el.length;
      }
    });
    setMaxCol(maximum);
  }, [tableData]);

  const handleAddRow = () => {
    const newData = [];
    for (let j = 0; j < addRow; j++) {
      let arr = [];
      for (let i = 0; i < columnCount; i++) {
        arr.push({ text: "", link: "" });
      }
      newData.push(arr);
    }
    setTableData((prevData) => [...prevData, ...newData]);
    setAddRow(0);
  };
  const handleAddColumn = () => {
    let arr = [];
    for (let i = 0; i < addCol; i++) {
      arr.push({ text: "", link: "" });
    }
    setTableData((prevData) => prevData.map((row) => [...row, ...arr]));
    setColumnCount((prevCount) => +prevCount + +addCol);
    setAddCol(0);
  };

  const handleChangeInCell = (rowIndex, columnIndex, text, link) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex][columnIndex] = { text, link };
      return newData;
    });
  };
  const getAlphabet = (index) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return alphabet[index % alphabet.length];
  };
  const closeModal = () => {
    setAddLinkModal(false);
  };
  const handleSaveChanges = () => {
    let filtredData = tableData.filter((el) => {
      const allEmpty = el.every((cell) => cell.text === "" && cell.link === "");
      if (allEmpty) return false;
      return true;
    });
    getTableData(filtredData);
  };
  const handleInsertLink = () => {
    if (!selectedCell) {
      toast.error("Please select a cell to insert Link");
    } else {
      setAddLinkModal(true);
    }
  };
  return (
    <>
      <MDBox px={2} py={3} style={{ border: "1px solid #A9A8A8" }}>
        <TableContainer sx={{ borderRadius: "0px" }} className="custom_scrollbar">
          <Table>
            <TableBody>
              <TableRow sx={{ border: "1px solid", backgroundColor: "#E5E5E5" }}>
                <TableHead sx={{ width: "55px", border: "px solid red" }}></TableHead>
                {tableData.length &&
                  new Array(maxcol).fill(0).map((_, columnIndex) => (
                    <TableCell align="center" key={columnIndex} sx={{ fontWeight: "500", border: "0.5px solid" }}>
                      {getAlphabet(columnIndex)}
                    </TableCell>
                  ))}
              </TableRow>
              {tableData?.map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell sx={{ fontSize: "16px", alignItems: "center", border: "1px solid", fontWeight: "500", height: "40px", width: "50px" }}>{rowIndex + 1}</TableCell>
                  {tableData[rowIndex].map((cell, columnIndex) => (
                    <Tablecell
                      key={columnIndex}
                      rowIndex={rowIndex}
                      columnIndex={columnIndex}
                      cell={cell}
                      handleChangeInCell={handleChangeInCell}
                      isSelected={selectedCell?.row === rowIndex && selectedCell?.col === columnIndex}
                      onselect={handleCellSelect}
                    />
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MDBox>
      {/* table manipulation section  */}
      <MDTypography px={3} mt="20px" fontSize={"16px"} sx={{ fontWeight: 500, height: "48px", border: "1px solid #A9A8A8", display: "flex", alignItems: "center", borderBottom: "none" }}>
        Table Manipulation
      </MDTypography>
      <MDBox p={3} fontSize={"16px"} sx={{ display: "flex", border: "1px solid #A9A8A8", alignItems: "flex-end", fontSize: "16px" }}>
        <MDBox>
          <MDBox sx={{ display: "flex", alignItems: "center", margin: "5px auto" }}>
            <p>Selected cells:</p>{" "}
            <MDBox>
              <button className="table_manipulation_btn" onClick={handleInsertLink}>
                Insert Link
              </button>
            </MDBox>
          </MDBox>
          <MDBox sx={{ display: "flex", alignItems: "center", margin: "5px auto" }}>
            <p>Selected rows:</p>
            <div>
              <button className="table_manipulation_btn">Duplicate</button> <button className="table_manipulation_btn">Insert</button> <button className="table_manipulation_btn">Delete</button>
            </div>
          </MDBox>
          <MDBox sx={{ display: "flex", alignItems: "center", margin: "5px auto" }}>
            <p>Selected rows:</p>{" "}
            <div>
              <button className="table_manipulation_btn">Move up</button>
              <button className="table_manipulation_btn">Move Down</button>
            </div>
          </MDBox>
          <MDBox sx={{ display: "flex", alignItems: "center", margin: "5px auto" }}>
            <p>Selected rows:</p>
            <div>
              <button className="table_manipulation_btn">Hide</button>
              <button className="table_manipulation_btn">Show</button>
            </div>
          </MDBox>
          <MDBox sx={{ display: "flex", alignItems: "center", margin: "5px auto" }}>
            <p style={{ margin: "auto 5px" }}>Add</p>
            <div>
              <input
                type="number"
                min={0}
                style={{ width: "62px", height: "25px", textAlign: "center" }}
                onChange={(e) => {
                  setAddRow(e.target.value);
                }}
                value={addRow}
              />
              <span style={{ margin: "auto 5px" }}>Row(s)</span>
              <button className="table_manipulation_btn" onClick={handleAddRow}>
                Add
              </button>
            </div>
          </MDBox>
        </MDBox>

        <MDBox mx={"100px"}>
          <MDBox sx={{ display: "flex", alignItems: "center", margin: "5px auto" }}>
            <p>Selected columns:</p>
            <div>
              <button className="table_manipulation_btn">Duplicate</button> <button className="table_manipulation_btn">Insert</button> <button className="table_manipulation_btn">Delete</button>
            </div>
          </MDBox>
          <MDBox sx={{ display: "flex", alignItems: "center", margin: "5px auto" }}>
            <p>Selected columns:</p>{" "}
            <div>
              <button className="table_manipulation_btn">Move up</button>
              <button className="table_manipulation_btn">Move Down</button>
            </div>
          </MDBox>
          <MDBox sx={{ display: "flex", alignItems: "center", margin: "5px auto" }}>
            <p>Selected columns:</p>
            <div>
              <button className="table_manipulation_btn">Hide</button>
              <button className="table_manipulation_btn">Show</button>
            </div>
          </MDBox>
          <MDBox sx={{ display: "flex", alignItems: "center", margin: "5px auto" }}>
            <p style={{ margin: "auto 5px" }}>Add</p>
            <div>
              <input
                type="number"
                min={0}
                style={{ width: "62px", height: "25px", textAlign: "center" }}
                onChange={(e) => {
                  setAddCol(e.target.value);
                }}
                value={addCol}
              />
              <span style={{ margin: "auto 5px" }}>columns(s)</span>
              <button className="table_manipulation_btn" onClick={handleAddColumn}>
                Add
              </button>
            </div>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox p={3}>
        <MDButton onClick={handleSaveChanges} style={{ backgroundColor: "#227BE9", marginTop: 2 }}>
          <MDTypography sx={{ color: "#fff", fontSize: 15, fontWeight: "bold" }}>Save Changes</MDTypography>
        </MDButton>
      </MDBox>
      {addLinkModal && selectedCell && <InsertLinkModal closeModal={closeModal} data={selectedCell} handleChangeInCell={handleChangeInCell} />}
    </>
  );
};
export default DynamicTable;
