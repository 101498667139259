import React, { useEffect, useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import { collapseItem, collapseIconBox, collapseIcon, collapseText } from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

// Material Dashboard Menu
import Menu from "@mui/material/Menu";

// Material Dashboard MenuItem
import MenuItem from "@mui/material/MenuItem";

// navlink and useLocation for routing
import { NavLink, useLocation } from "react-router-dom";

function SidenavCollapse({ icon, name, active, sideDropdown, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [activeMenu, setActiveMenu] = useState(active);
  const location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setActiveMenu(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setActiveMenu(false);
  };

  useEffect(() => {
    !location.pathname.slice(1).includes("/") && setSelectedMenu(0);
  }, [location]);

  return (
    <>
      {active && sideDropdown ? (
        <div>
          <ListItem
            component="li"
            onClick={(e) => {
              e.preventDefault();
              handleClick(e);
            }}
          >
            <MDBox
              {...rest}
              sx={(theme) =>
                collapseItem(theme, {
                  active: active || activeMenu,
                  transparentSidenav,
                  whiteSidenav,
                  darkMode,
                  sidenavColor,
                })
              }
            >
              <ListItemIcon sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })}>
                {typeof icon === "string" ? <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon> : icon}
              </ListItemIcon>
              <ListItemText
                onClick={() => {
                  setSelectedMenu(0);
                }}
                primary={name}
                sx={(theme) =>
                  collapseText(theme, {
                    miniSidenav,
                    transparentSidenav,
                    whiteSidenav,
                    active,
                  })
                }
              />
            </MDBox>
          </ListItem>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                width: 200,
                padding: 0,
                borderRadius: 0,
                cursor: "pointer",
              },
            }}
            MenuListProps={{ onMouseLeave: handleMenuClose }}
            className="dashboardSubMenu"
          >
            {sideDropdown.map((elem, index) => {
              return (
                <MenuItem
                  onClick={() => {
                    setSelectedMenu(index);
                  }}
                  style={{
                    borderLeft: index == selectedMenu && "3px solid #3A94EE",
                    marginBottom: index == selectedMenu ? "12px" : "11px",
                  }}
                  className={"dashboardSubMenuDropdowns"}
                  key={index}
                >
                  <NavLink
                    to={elem?.path ? elem?.path : "#"}
                    style={{
                      color: index == selectedMenu ? "#3A94EE" : "white",
                    }}
                  >
                    {elem.name ? elem.name : elem}
                  </NavLink>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      ) : (
        <ListItem component="li">
          <MDBox
            {...rest}
            sx={(theme) =>
              collapseItem(theme, {
                active,
                transparentSidenav,
                whiteSidenav,
                darkMode,
                sidenavColor,
              })
            }
          >
            <ListItemIcon sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })}>
              {typeof icon === "string" ? <Icon sx={(theme) => collapseIcon(theme, active)}>{icon}</Icon> : icon}
            </ListItemIcon>
            <ListItemText
              onClick={() => setSelectedMenu(0)}
              primary={name}
              sx={(theme) =>
                collapseText(theme, {
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                  active,
                })
              }
            />
          </MDBox>
        </ListItem>
      )}
    </>
  );
}

SidenavCollapse.defaultProps = {
  active: false,
};

SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
