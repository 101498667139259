import ReactPaginate from "react-paginate";
import React from "react";
import "./pagination.css";
export const Pagination = ({ itemOffset, handlePageClick, itemsPerPage, pageCount, currentPage, totalCount, setItemsPerPage, rowsPerPageOptions = [10, 25, 50] }) => {
  return (
    <div className="pagination-holder cus-inter" style={{ display: "flex", alignItems: "center", justifyContent: "end", padding: "20px 20px 20px 0px" }}>
      <div className="pagination-text" style={{ display: "flex" }}>
        <span>Rows per page:</span>
        <select id="pages" style={{ border: "none" }} onChange={(e) => setItemsPerPage(e.target.value)} value={itemsPerPage}>
          {rowsPerPageOptions.map((r) => (
            <option key={r} value={r}>
              {r}
            </option>
          ))}
        </select>
      </div>
      <span>
        {itemOffset + 1}-{itemsPerPage * currentPage < totalCount ? itemsPerPage * currentPage : totalCount} of {totalCount}
      </span>
      <ReactPaginate
        breakLabel="..."
        nextLabel={">"}
        className="pagination-wrapper"
        style={{ display: "flex", listStyleType: "none" }}
        pageLinkClassName="pagination-page-link"
        previousClassName="prev-page-arrow"
        nextClassName="next-page-arrow"
        onPageChange={handlePageClick}
        disabledClassName="page-action-disabled"
        pageRangeDisplayed={0}
        marginPagesDisplayed={0}
        pageCount={pageCount}
        previousLabel={"<"}
        renderOnZeroPageCount={null}
      />
    </div>
  );
};
