import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import Header from "./components/Header";
import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, TableContainer, Paper, Skeleton, Modal, Button, Stack, Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { styles } from "./styles.js";
import MDTypography from "components/MDTypography";
import SimpleBackdrop from "components/Backdrop";
import moment from "moment";
import { BASE_PATH } from "utils";
import { useFetch } from "hooks/useFetch";
import { axiosRequestPromise } from "Apis";
import ReactQuill from "react-quill";
import { Report } from "@mui/icons-material";
import ReportTable from "./ReportTable";
import ConversationsTable from "./ConversationsTable";
import toast from "react-hot-toast";

function JobDetails() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [conversations, setConversations] = useState([]);
  const q = searchParams.get("q");
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const { data, loading } = useFetch(`${BASE_PATH}/jobs/${q}`, { refetch: null });
  const { data: reportsData } = useFetch(`${BASE_PATH}/tickets?job_id=${q}`, { refetch: null });
  const { data: conversationData, loading: conversationsLoading } = useFetch(`${BASE_PATH}/jobs/${q}/conversations`, { refetch: null });
  const job = data?.data;
  const client = data?.data?.profile;
  const attachments = data?.data?.attachments;
  const loaderArray = new Array(5).fill("a");
  const [jobDeleteModalOpen, setJobDeleteModalOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [featured, setFeatured] = useState(false);

  useEffect(() => {
    if (conversationData) {
      console.log(conversationData.data);
      setConversations(conversationData.data);
    }
  }, [conversationData]);

  useEffect(() => {
    if (job) {
      setFeatured(job.featured);
    }
  }, [job]);

  const headers = [
    {
      type: "text",
      title: "Owner",
    },
    {
      type: "text",
      title: "Member",
    },
    {
      type: "text",
      title: "Created At",
    },
    {
      type: "text",
      title: "Last Messaged",
    },
    {
      type: "text",
      title: "Conversation Type",
    },
    {
      type: "text",
      title: "Is Reported",
    },
    {
      type: "text",
      title: "View Details",
    },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "1px solid #A9A8A8",
    boxSizing: "border-box",
    boxShadow: 24,
    borderRadius: "12px",
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleFeatureJob = async () => {
    try {
      const res = await axiosRequestPromise({
        method: "PUT",
        url: `${BASE_PATH}/jobs/${q}/toggle-featured`,
      });
      toast.success(res?.data?.data, { id: "feature_job" });
      setFeatured(!job.featured);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong", { id: "feature_job" });
      setFeatured(job.featured);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {jobDeleteModalOpen && (
        <Modal open={jobDeleteModalOpen} onClose={() => setJobDeleteModalOpen(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Box sx={style}>
            <Typography sx={{ fontSize: "24px" }}>Delete Reason</Typography>
            <textarea
              style={{ width: "100%", height: "100px", border: "1px solid lightgray", borderRadius: "8px", outline: "none", padding: "10px", marginTop: "10px" }}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Enter deletion reason"
            />
            <Stack direction={"row"} gap={"20px"} justifyContent={"flex-end"}>
              <Button
                variant="outlined"
                onClick={() => setJobDeleteModalOpen(false)}
                sx={{
                  color: "#000",
                  border: "1px solid lightgray",
                  "&:hover": {
                    color: "black",
                    border: "1px solid lightgray",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  try {
                    await axiosRequestPromise({ method: "patch", url: `${BASE_PATH}/jobs/${q}/delete`, body: { reason } });
                    navigate("/jobs");
                  } catch (error) {
                    navigate("/jobs");
                  }
                }}
                sx={{
                  color: "#fff",
                  "&:hover": {
                    color: "black",
                  },
                }}
                variant="contained"
              >
                Submit
              </Button>
            </Stack>
          </Box>
        </Modal>
      )}
      {!loading ? (
        <Header user={client} tabValue={tabValue} handleSetTabValue={handleSetTabValue}>
          <MDBox mt={"35px"} mb={"28px"} mx={-2}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
              <MDBox mt={-1} mx={3}>
                <MDTypography sx={{ fontSize: 20, fontWeight: "700" }}>Profile Information</MDTypography>
              </MDBox>
              <MDBox mt={0} mx={3}>
                <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                  <strong>Full Name : </strong> {client?.title}
                </MDTypography>
                {client?.user?.phone && (
                  <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                    <strong>Mobile : </strong> {client?.user?.phone}
                  </MDTypography>
                )}
                {client?.user?.email && (
                  <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                    <strong>Email : </strong> {client?.user?.email}
                  </MDTypography>
                )}
                {client?.address && (
                  <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                    <strong>Location : </strong> {client?.address?.city + " " + client?.address?.state + " " + client?.address?.country_code}
                  </MDTypography>
                )}
                <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                  <strong>Client ID : </strong> {client?._id}
                </MDTypography>{" "}
                <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                  <strong>Job ID : </strong> {job?._id}
                </MDTypography>
              </MDBox>

              <div style={{ display: "flex", alignItems: "center", marginTop: "12px", marginLeft: "12px" }}>
                <Switch onChange={handleFeatureJob} checked={featured} /> Feature Job
              </div>

              <MDBox mt={2} mx={3}>
                <MDTypography sx={{ fontSize: 20, fontWeight: "700" }}>Job Details</MDTypography>
              </MDBox>
              <MDBox mt={0} mx={3}>
                <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                  <strong>Date : </strong> {moment(job?.createdAt).fromNow()}
                </MDTypography>
                <MDBox mt={1}>
                  <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                    <strong>Title : </strong> {job?.title}
                  </MDTypography>
                </MDBox>

                <MDBox mt={1}>
                  <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                    <strong>Description : </strong>
                    <br />
                    <ReactQuill modules={{ toolbar: false }} theme="snow" value={job.description} readOnly={true} />
                  </MDTypography>
                </MDBox>
                <MDBox mt={2}>
                  <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                    <strong>Project Price : </strong> {job.project_type === "hourly" ? `$${job.hourly_rate_range.min} - $${job.hourly_rate_range.max}` : `$${job?.budget}`}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </div>
            <MDBox mt={2} mx={3}>
              <MDTypography sx={{ fontSize: 15, fontWeight: "700" }}> Attachments ({attachments?.length}) </MDTypography>
            </MDBox>

            <MDBox mt={0} mx={3}>
              {
                <MDBox mt={1}>
                  <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                    {attachments?.length > 0 &&
                      attachments?.map((attachment, index) => (
                        <MDBox key={index}>
                          <a target="_blank" rel="noreferrer" href={attachment?.src}>
                            {attachment?.key}
                          </a>
                        </MDBox>
                      ))}
                  </MDTypography>
                </MDBox>
              }
              {job?.reported_by?.length ? (
                <Box>
                  {/* reports Heading  */}
                  <MDBox display="flex" alignItems="center" mt={2}>
                    {" "}
                    <Report fontSize="medium" />
                    <MDTypography sx={{ fontSize: 20, fontWeight: "700" }}>
                      Reports {"("} {reportsData?.data?.docs?.length} {")"}{" "}
                    </MDTypography>
                  </MDBox>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Reported By</TableCell>
                        <TableCell>Reported On</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Reported </TableCell>
                        <TableCell>Ticket</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportsData?.data?.docs?.map((report) => (
                        <ReportTable key={report._id} report={report} />
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                ""
              )}
            </MDBox>

            <MDBox mt={2} mx={3}>
              <MDTypography sx={{ fontSize: 20, fontWeight: "700", width: "fit-content" }}>Conversations {"(" + conversations?.length + ")"}</MDTypography>
              <MDBox>
                <TableContainer component={Paper}>
                  {conversations.length > 0 && (
                    <Table>
                      <TableBody>
                        <TableRow>
                          {headers.map((h, i) => (
                            <TableCell key={i}>
                              <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#19114A" }}>{h.title}</Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                        {conversations?.map((item, index) => {
                          return <ConversationsTable item={item} key={index} />;
                        })}
                      </TableBody>
                    </Table>
                  )}

                  {conversationsLoading &&
                    loaderArray.map((l, i) => {
                      return (
                        <TableRow key={i}>
                          {headers.map((h, i) => {
                            const variant = h.type === "image" ? "circular" : "rounded";
                            const styles = h.type === "image" ? { width: "30px", height: "30px" } : { width: "100%", height: "20px" };
                            return (
                              <TableCell key={i}>
                                <Skeleton animation="wave" sx={styles} variant={variant} />
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableContainer>
              </MDBox>
            </MDBox>

            {localStorage.getItem("@role") === "super_admin" && (
              <Grid px={2}>
                <MDButton style={styles.deleteButton} sx={{ backgroundColor: "#FF6666", "&:hover": { backgroundColor: "#FF6666" } }} onClick={() => setJobDeleteModalOpen(true)}>
                  <DeleteIcon color="white" style={styles.deleteIcon} />
                  <Typography sx={{ color: "#fff", fontSize: 15, fontWeight: "bold" }}>Delete</Typography>
                </MDButton>
              </Grid>
            )}
          </MDBox>
        </Header>
      ) : (
        <SimpleBackdrop />
      )}
    </DashboardLayout>
  );
}

export default JobDetails;
