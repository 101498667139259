import React from "react";
import { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { RadioGroup, Radio, Button } from "@mui/material";
import "./addBlog.css";
import { useNavigate } from "react-router-dom/dist";
import { UploadFileRequest, createUrl } from "Apis/Apis";

const styles = {
  formControlLabel: {
    fontSize: 18,
    fontWeight: "400",
    marginRight: 30,
    color: "#292929",
  },
};
const CreateUrl = () => {
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const handleClose = () => {
    navigate("/blogs/uploadedimages");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setUploading(true);
      const formData = new FormData(e.currentTarget);
      const title = formData.get("title");
      const dimensions = formData.get("dimensions").split(" ");
      const uploadable_image = formData.get("uploadable_image");
      const res = await UploadFileRequest(uploadable_image);
      if (res?.success) {
        const image = res?.data?._id;
        const width = dimensions[0];
        const height = dimensions[1];
        const image_uploaded = createUrl({ title, height, width, image });
        if (image_uploaded) {
          navigate("/blogs/uploadedimages");
        } else {
          alert("Failed to create url");
        }
      }
      setUploading(false);
    } catch (e) {
      setUploading(false);
    }
  };
  return (
    <DashboardLayout>
      <Dialog open={true} fullWidth={true} maxWidth="sm" onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <MDBox m={3}>
          <form onSubmit={handleSubmit}>
            <MDTypography sx={{ fontSize: 20, fontWeight: "500", color: "#292929" }}>Upload Image</MDTypography>

            <MDTypography color={"#292929"} sx={{ fontSize: 15, fontWeight: "400", marginTop: 1 }}>
              Image Title
            </MDTypography>
            <TextField
              placeholder="Category Name"
              InputLabelProps={{
                style: { color: "black" },
              }}
              variant="outlined"
              style={{ marginRight: 30, borderRadius: 20, marginTop: 2.5, width: "100%" }}
              id="input-with-icon-textfield"
              label=""
              name="title"
              required
            />

            <MDTypography color={"#292929"} sx={{ fontSize: 15, fontWeight: "400", marginTop: 1 }}>
              Upload Image
            </MDTypography>

            <MDBox mb={2}>
              <MDInput fullWidth type="file" name="uploadable_image" required />
            </MDBox>

            <MDTypography color={"#292929"} sx={{ fontSize: 15, fontWeight: "400", marginTop: -1 }}>
              Sizes to convert to
            </MDTypography>
            <RadioGroup defaultValue={""} name="dimensions" required>
              <FormControlLabel sx={styles.formControlLabel} value={"1000 1000"} control={<Radio />} label={<p style={{ fontSize: 15, fontWeight: "400" }}>Full size no resizing</p>} />
              <FormControlLabel sx={styles.formControlLabel} value={"1000 700"} control={<Radio />} label={<p style={{ fontSize: 15, fontWeight: "400" }}>Large - 1000 x 700px</p>} />{" "}
              <FormControlLabel sx={styles.formControlLabel} value={"600 400"} control={<Radio />} label={<p style={{ fontSize: 15, fontWeight: "400" }}>Medium - 600 x 400px</p>} />{" "}
              <FormControlLabel sx={styles.formControlLabel} value={"320 200"} control={<Radio />} label={<p style={{ fontSize: 15, fontWeight: "400" }}>Small - 320 x 200px</p>} />{" "}
              <FormControlLabel sx={styles.formControlLabel} value={"150 150"} control={<Radio />} label={<p style={{ fontSize: 15, fontWeight: "400" }}>Thumbnail - 150 x 150px</p>} />
            </RadioGroup>

            <MDBox mx={0} mt={2} sx={{ display: "flex", flexDirection: "row", marginBottom: 1 }}>
              <MDBox
                sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F4F4F4", borderRadius: 10, border: "1px solid #A9A8A8", cursor: "pointer" }}
                onClick={() => navigate("/blogs/uploadedimages")}
              >
                <MDTypography px={4} py={1} sx={{ fontSize: 15, fontWeight: "700", color: "#1E0707" }}>
                  Cancel
                </MDTypography>
              </MDBox>
              <Button
                type="submit"
                disabled={uploading}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#227BE9",
                  color: "#fff",
                  borderRadius: 10,
                  border: "1px solid #A9A8A8",
                  marginLeft: 2,
                  cursor: "pointer",
                }}
              >
                {!uploading ? "Upload" : <img style={{ width: "30px" }} src={require("../../../assets/images/icons/loading.gif")} alt="loading..." />}
              </Button>
            </MDBox>
          </form>
        </MDBox>
      </Dialog>
      <DashboardNavbar />
    </DashboardLayout>
  );
};

export default CreateUrl;
