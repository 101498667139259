import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import breakpoints from "assets/theme/base/breakpoints";
import backgroundImage from "assets/images/bg-profile.jpeg";
import profileicon from "../../../../../assets/images/icons/profile.png";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-hot-toast";
import api from "../../../../../api";

function Header({ children, user }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [notificationValue, setNotificationValue] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const notificationData = (u) => {
    setNotificationValue(u);
  };
  const handleSendNotification = () => {
    const payload = {
      description: notificationValue,
      userType: "",
      userId: user?._id,
    };

    api
      .put(`/users/sendNotification`, payload)
      .then((response) => {
        toast.success(response?.data?.message);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm ? setTabsOrientation("vertical") : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <MDBox position="relative" mb={5} mt={-2}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(rgba(gradients.info.main, 0.6), rgba(gradients.info.state, 0.6))}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={user?.image ? user?.image?.src : profileicon} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {user ? user.title : "User"}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {user?.type || "Client"}
              </MDTypography>
            </MDBox>
          </Grid>

          {/* to send notification  */}

          <Grid item xs={12} md={6} lg={4}>
            <AppBar position="static">
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Send Notification"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <MDTypography sx={{ fontWeight: "bold", fontSize: "" }}>Enter Notification</MDTypography>
                    <textarea
                      name=""
                      id=""
                      onChange={(e) => {
                        notificationData(e.target.value);
                      }}
                      style={{ width: "346px", border: "1px solid black", height: "52px", borderRadius: "5px", padding: "10px" }}
                    />
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleSendNotification} autoFocus>
                    Send
                  </Button>
                </DialogActions>
              </Dialog>
            </AppBar>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <MDBox
                  sx={{
                    backgroundColor: "#3A94EE",
                    width: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 3,
                    padding: 0.5,
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                >
                  <MDTypography pt={1} pb={1} sx={{ fontSize: 15, fontWeight: "500", color: "white" }} color={"white"}>
                    Send Notification
                  </MDTypography>
                </MDBox>
              </div>
              {/* </Tabs> */}
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
