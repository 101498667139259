import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useFetch } from "hooks/useFetch";
import { BASE_PATH } from "utils";
import { Report } from "@mui/icons-material";
import { Loader } from "components/common/Loader";
const MessagesModal = ({ conversation_id }) => {
  const [msg_data, setMsgData] = useState([]);
  const [showEdited, setShowEdited] = useState(false);
  const { data: msgData, loading } = useFetch(`${BASE_PATH}/jobs/conversation-messages/${conversation_id}`, { refetch: false });

  useEffect(() => {
    let data = msgData?.data?.docs.map((doc) => ({
      content: doc.content,
      is_deleted: doc.is_deleted,
      is_edited: doc.is_edited,
      _id: doc._id,
      from: doc.sender_id?.type,
      is_reported: doc.reports.length === 0 ? false : true,
      edit_history: doc.edit_history,
    }));
    setMsgData(data);
  }, [msgData]);
  return (
    <>
      <Box sx={{ padding: "30px", display: "flex", flexDirection: "column", gap: "15px" }}>
        <Typography sx={{ fontSize: 20, fontWeight: "700", marginTop: "26px", margin: "auto" }}>Messages</Typography>
        {loading && <Loader />}
        {msg_data?.map((message, i) => {
          return (
            <Box key={i} sx={{ width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "flex-end", gap: "6px" }}>
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    background: message.from === "freelancer" ? "#FFDB80" : "#80E0FF",
                    color: "#007AA1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                  }}
                >
                  <Typography sx={{ fontSize: "22px", fontWeight: "700" }}>{message.from === "freelancer" ? "F" : "C"}</Typography>
                </Box>
                <Box
                  sx={{
                    background: "#FAF8F8",
                    display: "flex",
                    alignItems: "center",
                    color: "#054089",
                    borderRadius: "10px",
                    border: "1px solid #C9C9C9",
                    padding: "13px",
                    flexGrow: 1,
                    width: "80%",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>{message.content}</Typography>
                  {message?.is_edited && (
                    <Typography onClick={() => setShowEdited(!showEdited)} sx={{ fontSize: "12px", fontWeight: "400", marginLeft: "10px" }}>
                      (Edited)
                    </Typography>
                  )}
                  {showEdited && (
                    <>
                      {message?.edit_history.length ? (
                        <Typography key={i} sx={{ fontSize: "12px", fontWeight: "400", marginLeft: "10px" }}>
                          History {"=>"}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {message?.edit_history?.map((edit, i) => (
                        <Typography key={i} sx={{ fontSize: "14px", fontWeight: "400", marginLeft: "10px" }}>
                          {edit.content}
                        </Typography>
                      ))}
                    </>
                  )}
                  {message?.is_reported && <Report sx={{ marginLeft: "20px", color: "red" }} />}
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <Typography sx={{ fontSize: "10px", fontWeight: "400" }}>
                  {message.is_deleted && "(DELETED MESSAGE)"} {message.is_edited && "(EDITED MESSAGE)"} ID-{message._id}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default MessagesModal;
