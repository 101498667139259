import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { axiosRequestPromise } from "Apis";
import { BASE_PATH } from "utils";
import { useNavigate } from "react-router-dom";
import MDInput from "components/MDInput";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link"];

function SendNotification() {
  const [type, setType] = useState("all");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleSendNotification = async () => {
    try {
      if (!description || !(type === "all" || type === "freelancer" || type === "client")) {
        return null;
      }
      setIsLoading(true);
      toast.loading("sending notification...", { id: "send_notification" });
      const res = await axiosRequestPromise({
        method: "post",
        url: `${BASE_PATH}/notification`,
        body: {
          title,
          content: description,
          notification_to: type,
        },
      });
      if (res?.data?.success) {
        toast.success("Notification broadcasted successfully", { id: "send_notification" });
        setDescription("");
        setType("all");
        setTimeout(() => {
          navigate("/users/notification");
        }, 1500);
      }
    } catch (error) {
      toast.error("Something went wrong", { id: "send_notification" });
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={"30.5px"} pb={3} mt={0}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mx={2}
                mt={-6}
                py={"17px"}
                px={"24px"}
                bgColor="#227BE9"
                borderRadius="13px"
                coloredShadow="info"
                boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)"}
              >
                <MDTypography color="white" fontSize="20px" fontWeight="medium" lineHeight="24px">
                  Send Notification
                </MDTypography>
              </MDBox>
              <MDBox mx={3} pt={2}>
                <MDTypography sx={{ fontSize: 13, fontWeight: "400", color: "#19114A" }}>Select users type</MDTypography>
                <MDBox pt={2} display="flex" alignItems="center" mt={-2} py={3}>
                  <FormControl>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                      <FormControlLabel
                        sx={{ marginLeft: "3px", fontSize: "13px" }}
                        value="all"
                        checked={type === "all"}
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                        control={<Radio />}
                        label={
                          <Box component="div" fontSize={13} fontWeight={400} marginLeft={-1}>
                            All
                          </Box>
                        }
                      />
                      <FormControlLabel
                        sx={{ marginLeft: "-10px", fontSize: "13px" }}
                        value="client"
                        checked={type === "client"}
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                        control={<Radio />}
                        label={
                          <Box component="div" fontSize={13} fontWeight={400} marginLeft={-1}>
                            Client
                          </Box>
                        }
                      />
                      <FormControlLabel
                        sx={{ marginLeft: "-10px", fontSize: "13px" }}
                        value="freelancer"
                        checked={type === "freelancer"}
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                        control={<Radio />}
                        label={
                          <Box component="div" fontSize={13} fontWeight={400} marginLeft={-1}>
                            Freelancer
                          </Box>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </MDBox>
                <MDBox pt={2} display="flex" justifyContent="flex-start" flexDirection="column" mt={-3} py={1} mx={0}>
                  <p style={{ fontSize: 13, fontWeight: "400" }}>Enter Notification Title</p>
                  <FormControl style={{ width: "40%" }}>
                    <MDInput value={title} onChange={(e) => setTitle(e.target.value)} id="outlined-multiline-static" label="Title" style={{ marginTop: 5, marginRight: 25 }} />
                  </FormControl>
                </MDBox>
                <MDBox pt={2} boxSizing={"border-box"} mt={3} py={1} mx={0}>
                  <p style={{ fontSize: 13, fontWeight: "400" }}>Enter Notification Description</p>
                  <FormControl style={{ width: "70%" }}>
                    <ReactQuill
                      className="ql-notification-editor"
                      style={{ height: "250px" }}
                      theme="snow"
                      formats={formats}
                      modules={modules}
                      onChange={(v) => {
                        setDescription(v);
                      }}
                      value={description}
                    />
                  </FormControl>
                </MDBox>
                <MDBox mt={1} sx={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
                  <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F4F4F4", borderRadius: 10, border: "1px solid #A9A8A8", cursor: "pointer" }}>
                    <MDTypography px={8} py={1} sx={{ fontSize: 15, fontWeight: "700", color: "#1E0707" }}>
                      Cancel
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    onClick={handleSendNotification}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: loading ? "#1E0707" : "#227BE9",
                      borderRadius: 10,
                      border: "1px solid #A9A8A8",
                      marginLeft: 2,
                      cursor: "pointer",
                    }}
                  >
                    <MDTypography px={10} py={1} sx={{ fontSize: 15, fontWeight: "700", color: "#FFFFFF" }}>
                      {loading ? "Sending..." : "Send"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default SendNotification;
