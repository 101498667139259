import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #A9A8A8",
  boxSizing: "border-box",
  boxShadow: 24,
  borderRadius: "12px",
  pt: 2,
  px: 4,
  pb: 3,
};
const TrashModal = ({ status, loading, open, handleClose, handleDelete }) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
          Are you sure you want to {status == "trash" ? "recover" : "trash"}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <Button
            onClick={handleDelete}
            variant="contained"
            sx={{
              cursor: "pointer",
              borderRadius: "100px",
              backgroundColor: "#292929",
              fontSize: "12px",
              color: "#fff",
              textTransform: "none",
              marginRight: "2px",
            }}
          >
            {loading ? "Loading..." : "Yes I am Sure"}
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              cursor: "pointer",
              border: "1px solid #A9A8A8",
              borderRadius: "100px",
              fontSize: "12px",
              color: "#292929",
              textTransform: "none",
              marginLeft: "2px",
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TrashModal;
