import { Autocomplete, Box, Dialog, MenuItem, Select, TextField } from "@mui/material";
import { axiosRequestPromise } from "Apis";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useFetch } from "hooks/useFetch";
import React, { useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDebounce } from "use-debounce";
import { BASE_PATH } from "utils";

const CreateBlogCategoryDialogue = ({ open, handleClose, state = "create", refetch, editingCategory }) => {
  const statusOptions = [
    {
      label: "Draft",
      value: "draft",
    },
    {
      label: "Published",
      value: "published",
    },
  ];
  const [blogInputValue, setBlogInputValue] = useState("");
  const [blogInputDebouncedValue] = useDebounce(blogInputValue, 500);
  const { data: blogsResponse, loading: blogsLoading } = useFetch(`${BASE_PATH}/blogs?offset=0&limit=50${blogInputDebouncedValue ? `&search=${blogInputDebouncedValue}&status=published` : ""}`);

  const blogOptions = useMemo(
    () =>
      (blogsResponse?.data?.docs || [])?.map((item) => ({
        label: item.title,
        value: item._id,
        src: item.image?.src,
      })),
    [blogsResponse]
  );
  const [categoryInputValue, setCategoryInputValue] = useState("");
  const [categoryInputDebouncedValue] = useDebounce(blogInputValue, 500);
  const { data: categoriesResponse, loading: categoriesLoading } = useFetch(
    `${BASE_PATH}/blogs/categories?offset=0&limit=50${categoryInputDebouncedValue ? `&search=${categoryInputDebouncedValue}&status=published` : ""}`
  );

  const categoryOptions = useMemo(
    () =>
      (categoriesResponse?.data?.docs || [])?.map((item) => ({
        label: item.title,
        value: item._id,
      })),
    [categoriesResponse]
  );
  const [formDataParams, setFormDataParams] = useState({
    title: editingCategory?.title || "",
    slug: editingCategory?.slug || "",
    description: editingCategory?.description || "",
    featured_blogs: editingCategory?.featured_blogs?.map((fb) => ({ label: fb?.title, value: fb?._id, src: fb?.image?.src })) || [],
    parent_categories: editingCategory?.parent_categories?.map((pc) => ({ label: pc?.title, value: pc?._id })) || [],
    status: editingCategory?.status || statusOptions[0].value,
  });

  const handleSubmit = () => {
    if (!formDataParams?.title || !formDataParams?.slug || !formDataParams?.description) {
      toast.error("Please enter all required value");
      return;
    }
    const obj = {
      ...formDataParams,
      featured_blogs: formDataParams?.featured_blogs?.map((fb) => fb?.value),
      parent_categories: formDataParams?.parent_categories?.map((pc) => pc?.value),
    };
    if (state === "edit") {
      delete formDataParams.image;
      toast.promise(axiosRequestPromise({ method: "put", url: `${BASE_PATH}/blogs/categories/${editingCategory._id}`, body: obj }), {
        success: () => {
          refetch && refetch();
          handleClose();
          return "Category updated successfully";
        },
        loading: "Updating the category",
        error: () => {
          handleClose();
          return "Something went wrong";
        },
      });
    }
    if (state === "create") {
      toast.promise(axiosRequestPromise({ method: "post", url: `${BASE_PATH}/blogs/categories`, body: obj }), {
        success: () => {
          refetch && refetch();
          handleClose();
          return "Category created successfully";
        },
        loading: "Creating the category",
        error: () => {
          handleClose();
          return "Something went wrong";
        },
      });
    }
  };
  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm" onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <MDBox mx={3} my={3}>
        <MDTypography sx={{ fontSize: 20, fontWeight: "500", color: "#292929" }}>{state === "edit" ? "Edit Category" : "Create Category"}</MDTypography>

        <MDTypography color={"#292929"} sx={{ fontSize: 15, fontWeight: "400", marginTop: 1 }}>
          Category Name
        </MDTypography>
        <TextField
          placeholder="Category Name"
          InputLabelProps={{
            style: { color: "black" },
          }}
          value={formDataParams?.title}
          onChange={(e) =>
            setFormDataParams({
              ...formDataParams,
              title: e.target.value,
            })
          }
          variant="outlined"
          style={{ marginRight: 30, borderRadius: 20, marginTop: 2.5, width: 500 }}
          id="input-with-icon-textfield"
          label=""
        />
        <MDTypography sx={{ fontSize: 12, fontWeight: "400" }}>The name of the category</MDTypography>

        <MDTypography color={"#292929"} sx={{ fontSize: 15, fontWeight: "400", marginTop: 1 }}>
          Category Slug
        </MDTypography>
        <TextField
          value={formDataParams?.slug}
          placeholder="Category Slug"
          InputLabelProps={{
            style: { color: "black" },
          }}
          onChange={(e) =>
            setFormDataParams({
              ...formDataParams,
              slug: e.target.value,
            })
          }
          variant="outlined"
          style={{ marginRight: 30, borderRadius: 20, marginTop: 2.5, width: 500 }}
          id="input-with-icon-textfield"
          label=""
        />
        <MDTypography sx={{ fontSize: 12, fontWeight: "400" }}>
          Letters, numbers, dash only. The slug i.e https://www.uptechunt.com/blog/category/this_part. The must be unique (Two category can’t share the same slug).
        </MDTypography>

        <MDTypography color={"#292929"} sx={{ fontSize: 15, fontWeight: "400", marginTop: 1 }}>
          Description
        </MDTypography>
        <TextField
          placeholder="Description"
          multiline
          rows={5}
          value={formDataParams?.description}
          InputLabelProps={{
            style: { color: "black" },
          }}
          onChange={(e) =>
            setFormDataParams({
              ...formDataParams,
              description: e.target.value,
            })
          }
          variant="outlined"
          style={{ marginRight: 30, borderRadius: 20, marginTop: 2.5, width: 500 }}
          id="input-with-icon-textfield"
          label=""
        />

        <MDBox mb={2}>
          <MDTypography color={"#292929"} sx={{ fontSize: 15, fontWeight: "400", marginTop: 1 }}>
            Featured Blogs
          </MDTypography>
          <Autocomplete
            value={formDataParams.featured_blogs}
            onChange={(e, value) => {
              setFormDataParams((pv) => ({ ...pv, featured_blogs: value }));
            }}
            loading={blogsLoading}
            id="country-select-demo"
            sx={{ width: "100%" }}
            options={blogOptions}
            multiple={true}
            autoHighlight
            inputValue={blogInputValue}
            onInputChange={(_, v) => {
              setBlogInputValue(v);
            }}
            // multiple={true}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Box key={key} component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...optionProps}>
                  <img loading="lazy" width="20" height="20" src={option.src} alt="" />
                  {option.label}
                </Box>
              );
            }}
            renderInput={(params) => (
              <MDInput
                {...params}
                label="Choose featured blogs"
                slotProps={{
                  htmlInput: {
                    ...params.inputProps,
                    autoComplete: "new-password",
                  },
                }}
              />
            )}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography color={"#292929"} sx={{ fontSize: 15, fontWeight: "400", marginTop: 1 }}>
            Parent Categories
          </MDTypography>
          <Autocomplete
            value={formDataParams.parent_categories}
            onChange={(e, value) => {
              setFormDataParams((pv) => ({ ...pv, parent_categories: value }));
            }}
            loading={categoriesLoading}
            id="country-select-demo"
            sx={{ width: "100%" }}
            options={categoryOptions}
            multiple={true}
            autoHighlight
            inputValue={categoryInputValue}
            onInputChange={(_, v) => {
              setCategoryInputValue(v);
            }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <MDInput
                {...params}
                label="Choose Parent Categories blogs"
                slotProps={{
                  htmlInput: {
                    ...params.inputProps,
                    autoComplete: "new-password",
                  },
                }}
              />
            )}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDTypography color={"#292929"} sx={{ fontSize: 15, fontWeight: "400", marginTop: 1 }}>
            Status
          </MDTypography>
          <Select
            onChange={(e) => {
              setFormDataParams((pv) => ({ ...pv, status: e.target.value }));
            }}
            id="country-select-demo"
            sx={{ height: "2.5rem", border: "1px solid white", width: "100%", color: "#000" }}
            options={statusOptions}
            InputLabelProps={{ shrink: false }}
            displayEmpty
            getOptionLabel={(option) => option.label}
            value={formDataParams.status}
          >
            {statusOptions.map((so) => (
              <MenuItem sx={{ color: "#000", background: "#fff" }} key={so.value} value={so.value}>
                {so.label}
              </MenuItem>
            ))}
          </Select>
        </MDBox>

        <MDBox mx={0} mt={2} sx={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
          <MDBox
            sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F4F4F4", borderRadius: 10, border: "1px solid #A9A8A8", cursor: "pointer" }}
            onClick={handleClose}
          >
            <MDTypography px={4} py={1} sx={{ fontSize: 15, fontWeight: "700", color: "#1E0707" }}>
              Cancel
            </MDTypography>
          </MDBox>
          <MDBox
            sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#227BE9", borderRadius: 10, border: "1px solid #A9A8A8", marginLeft: 2, cursor: "pointer" }}
            onClick={handleSubmit}
          >
            <MDTypography px={4} py={1} sx={{ fontSize: 15, fontWeight: "700", color: "#FFFFFF" }}>
              Save Changes
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Dialog>
  );
};

export default CreateBlogCategoryDialogue;
