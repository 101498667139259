import { Dialog, DialogTitle, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Pagination } from "components/common/Pagination";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFetch } from "hooks/useFetch";
import usePagination from "hooks/usePagination";
import React from "react";
import { BASE_PATH } from "utils";
import ImgOne from "./Emojies/one.svg";
import ImgTwo from "./Emojies/two.svg";
import ImgThree from "./Emojies/three.svg";
import ImgFour from "./Emojies/four.svg";
import ImgFive from "./Emojies/five.svg";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
function Feedback() {
  const { page, limit, setPage, setLimit } = usePagination();
  const url = `${BASE_PATH}/feedback?limit=${limit}&offset=${limit * page}`;

  const { data, loading } = useFetch(url);
  const [msgModal, setMsgModal] = React.useState(null);

  const jobs = data?.data?.docs;
  const Title = ({ title, sx }) => (
    <MDBox lineHeight={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <MDTypography display="block" variant="caption" sx={{ width: "100%", fontColor: "0A0529", fontWeight: "500", fontSize: "14px", ...sx }}>
        {title}
      </MDTypography>
    </MDBox>
  );

  const loaderArray = new Array(limit).fill("a");
  const totalPages = data?.data?.totalPages || Math.ceil(data?.data?.totalDocs / data?.data?.limit);
  const headers = [
    {
      type: "text",
      title: "Name",
    },
    {
      type: "text",
      title: "Email",
    },
    {
      type: "text",
      title: "Feedback",
    },
    {
      type: "text",
      title: "Rating",
    },
    {
      type: "text",
      title: "Date",
    },
    {
      type: "text",
      title: "Time",
    },
  ];
  return (
    <DashboardLayout>
      <Dialog onClose={() => setMsgModal(null)} open={msgModal}>
        <DialogTitle>Feedback</DialogTitle>
        <MDBox mx={1.5} sx={{ marginBottom: 3 }}>
          <MDTypography sx={{ fontSize: 14, fontWeight: "700", marginLeft: "10px" }}>Improvements</MDTypography>
          {msgModal?.improvements?.map((el, idx) =>
            el.descriptions.length ? (
              <MDBox key={idx} mt={2} sx={{ paddingTop: 1, borderRadius: 2, paddingLeft: 2.5, width: 500, paddingBottom: 1 }}>
                <MDTypography sx={{ fontSize: 14, fontWeight: "700" }}>{el.title}</MDTypography>
                <List marker="circle" sx={{ fontSize: 14, marginLeft: "10px" }}>
                  {el.descriptions.map((des, idx) => (
                    <ListItem key={idx}>{des}</ListItem>
                  ))}
                </List>
              </MDBox>
            ) : null
          )}
        </MDBox>

        {msgModal?.other_suggesation && (
          <MDBox mx={1.5} sx={{ marginBottom: 3 }}>
            <MDTypography sx={{ fontSize: 14, fontWeight: "700" }}>Other Suggeations</MDTypography>
            <MDTypography sx={{ fontSize: 14, marginLeft: "10px" }}>{msgModal?.other_suggesation}</MDTypography>
          </MDBox>
        )}
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <MDBox
            onClick={() => setMsgModal(null)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#227BE9",
              borderRadius: 2,
              border: "1px solid #A9A8A8",
              cursor: "pointer",
              width: 200,
              marginBottom: 5,
            }}
            color="white"
          >
            Cancel
          </MDBox>
        </div>
      </Dialog>
      <DashboardNavbar />
      <MDBox pt={"30.5px"} pb={3} mt={0}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mx={2}
                mt={-6}
                py={"17px"}
                px={"24px"}
                bgColor="#227BE9"
                borderRadius="13px"
                coloredShadow="info"
                boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)"}
              >
                <MDTypography color="white" fontSize="20px" fontWeight="medium" lineHeight="24px">
                  Feedbacks : {data?.data?.totalDocs}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDBox mt={2}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          {headers.map((h, i) => (
                            <TableCell key={i}>
                              <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "#19114A" }}>{h.title}</Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                        {!loading
                          ? jobs?.length === 0
                            ? "No Jobs found"
                            : jobs?.map((item, index) => {
                                return (
                                  <TableRow
                                    sx={{
                                      "&:hover": {
                                        background: "#f5f5f5",
                                      },
                                    }}
                                    key={index}
                                  >
                                    <TableCell>
                                      <Title title={item.name} />
                                    </TableCell>
                                    <TableCell>
                                      <Title title={item.email} />
                                    </TableCell>
                                    <TableCell>
                                      <MDTypography
                                        display="block"
                                        fontColor="blue"
                                        fontSize="16px"
                                        sx={{
                                          cursor: "pointer",
                                          textDecoration: "underline",
                                          "&:hover": {
                                            color: "blue",
                                          },
                                        }}
                                        onClick={() => setMsgModal(item)}
                                      >
                                        View
                                      </MDTypography>
                                    </TableCell>
                                    <TableCell>
                                      {item.rating === 1 ? (
                                        <img src={ImgOne} alt="rating-1" />
                                      ) : item.rating === 2 ? (
                                        <img src={ImgTwo} alt="rating-2" />
                                      ) : item.rating === 3 ? (
                                        <img src={ImgThree} alt="rating-3" />
                                      ) : item.rating === 4 ? (
                                        <img src={ImgFour} alt="rating-4" />
                                      ) : item.rating === 5 ? (
                                        <img src={ImgFive} alt="rating-5" />
                                      ) : (
                                        <img src={ImgOne} alt="No Rating" />
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <Title title={new Date(item.createdAt).toDateString()} />
                                    </TableCell>
                                    <TableCell>
                                      <Title title={new Date(item.createdAt).toLocaleTimeString()} />
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                          : loaderArray.map((l, i) => {
                              return (
                                <TableRow key={i}>
                                  {headers.map((h, i) => {
                                    const variant = h.type === "image" ? "circular" : "rounded";
                                    const styles = h.type === "image" ? { width: "30px", height: "30px" } : { width: "100%", height: "20px" };
                                    return (
                                      <TableCell key={i}>
                                        <Skeleton animation="wave" sx={styles} variant={variant} />
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Pagination
                    currentPage={page + 1}
                    pageCount={totalPages}
                    totalCount={data?.data?.totalDocs}
                    handlePageClick={({ selected }) => setPage(selected)}
                    setItemsPerPage={setLimit}
                    itemsPerPage={limit}
                    itemOffset={limit * page}
                  />
                </MDBox>
                <MDBox mt={-6} display="flex" flexDirection={{ xs: "column", sm: "row" }} justifyContent="flex-end" alignItems={{ xs: "flex-start", sm: "center" }} p={3}></MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Feedback;
