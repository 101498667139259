import React from "react";
import { Paper, Table, TableBody, TableHead, TableCell, TableContainer, TableRow, Skeleton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Pagination } from "components/common/Pagination";

export const SkillsTable = ({ data, handleDelete, handleEdit, headers, totalCount, currentPage, itemOffset, handlePageClick, pageCount, itemsPerPage, setItemsPerPage, loading }) => {
  const Title = ({ title, sx }) => (
    <MDBox lineHeight={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <MDTypography display="block" variant="caption" sx={{ width: "100%", fontColor: "0A0529", fontWeight: "500", fontSize: "14px", ...sx }}>
        {title}
      </MDTypography>
    </MDBox>
  );
  const loaderArray = new Array(itemsPerPage).fill(itemsPerPage);
  // easier to read
  const rows = () => {
    return data?.map((skill, index) => {
      return (
        <TableRow
          sx={{
            "&:hover": {
              background: "#f5f5f5",
            },
          }}
          key={index}
        >
          <TableCell>
            <Title title={skill?.name ?? "-"} />
          </TableCell>  
          <TableCell>
            <p className="skill_truncate ">{skill?.description ?? '-'}</p>
          </TableCell>
          <TableCell>
            <Title title={skill?._id ?? "-"} />
          </TableCell>
          <TableCell>
            <MDTypography display="block" variant="caption" sx={{ fontColor: "0A0529", fontWeight: "700", cursor: "pointer", fontSize: "14px" }}>
              <span onClick={() => handleEdit(skill?._id)}>Edit</span> / <span onClick={() => handleDelete(skill?._id)}>Delete</span>
            </MDTypography>
          </TableCell>
        </TableRow>
      );
    });
  };
  return (
    <>
      {!loading && !data?.length ? (
        <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginTop: "5%", marginBottom: "5%" }}>
          <Title title="No skills found.." />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {headers?.map((header, index) => {
                  return <TableCell key={index}>{header?.title}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading
                ? rows()
                : loaderArray.map((l, i) => {
                    return (
                      <TableRow key={i}>
                        {/* currently no image skeleton, but will automatically work if the key is image */}
                        {headers.map((h, i) => {
                          const variant = h.key === "image" ? "circular" : "rounded";
                          const styles = h.key === "image" ? { width: "30px", height: "30px" } : { width: "100%", height: "20px" };
                          return (
                            <TableCell key={i}>
                              <Skeleton animation="wave" sx={styles} variant={variant} />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
          <Pagination
            handlePageClick={handlePageClick}
            itemOffset={itemOffset}
            pageCount={pageCount}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            totalCount={totalCount}
          />
        </TableContainer>
      )}
    </>
  );
};
