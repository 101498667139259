/* eslint-disable react/prop-types */
import React from "react";
// Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

import { useState, useEffect } from "react";
import api from "../../../../api";

export default function data(tabValue) {
  const [applications, setApplications] = useState(null);

  useEffect(() => {
    if (tabValue === 0) {
      api
        .get("/application?status=pending")
        .then((res) => {
          setApplications(res?.data?.response?.results);
        })
        .catch(() => null);
    }

    if (tabValue === 1) {
      api
        .get("/application?status=approved")
        .then((res) => {
          setApplications(res?.data?.response?.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (tabValue === 2) {
      api
        .get("/application?status=rejected")
        .then((res) => {
          setApplications(res?.data?.response?.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [tabValue]);

  const Image = ({ image }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} size="sm" />
    </MDBox>
  );

  const Title = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  const generateRows = (d) => {
    const tableRowArray = [];

    d.forEach((s) => {
      tableRowArray.push({
        image: <Image image={s.freelancer.user.profilePicture} />,
        name: <Title title={s.freelancer.user.firstName.concat(" ", s.freelancer.user.lastName)} />,
        email: <Title title={s.freelancer.user.email} />,

        action: (
          <MDTypography component="a" href={`/applications/${s._id}`} variant="caption" color="text" fontWeight="medium">
            View Info
          </MDTypography>
        ),
      });
    });
    return tableRowArray;
  };

  return {
    columns: [
      { Header: "image", accessor: "image", align: "left" },
      { Header: "name", accessor: "name", align: "left" },
      { Header: "email", accessor: "email", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: applications ? generateRows(applications) : [],
  };
}
