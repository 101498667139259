import axios from "axios";

export const getData = (url) => {
  const token = localStorage.getItem("@storage_Key");
  const headers = {
    Accept: "*/*",
    Authorization: `Bearer${" "}${token}`,
    "Content-Type": "application/json",
  };

  const options = {
    method: "GET",
    headers: headers,
  };
  try {
    return axios
      .get(url, {
        ...options,
      })
      .then((response) => {
        return response?.data;
      })
      .catch((err) => {
        console.log("err--", err);
      });
  } catch (err) {
    console.log("err--", err);
  }
};

export const axiosRequest = (method, url, body) => {
  const token = localStorage.getItem("@storage_Key");
  const headers = {
    Accept: "*/*",
    Authorization: `Bearer${" "}${token}`,
  };

  const options = {
    headers: headers,
  };

  try {
    return axios[method](url, JSON.parse(body), options)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  } catch (err) {
    console.log("err--", err);
    throw err;
  }
};
export const axiosRequestPromise = ({ method, url, body, headers, options: axiosOptions }) => {
  return new Promise(async (resolve, reject) => {
    const token = localStorage.getItem("@storage_Key");
    const headersObj = {
      Accept: "*/*",
      Authorization: `Bearer${" "}${token}`,
      ...headers,
    };

    const options = {
      headers: headersObj,
      ...axiosOptions,
    };

    try {
      let res;
      if (method.toLowerCase() === "get" || method.toLowerCase() === "delete") {
        res = await axios[method.toLowerCase()](url, options);
      } else {
        res = await axios[method.toLowerCase()](url, body, options);
      }
      resolve(res);
    } catch (err) {
      reject(err);
    }
  });
};
export const axiosDelete = (url) => {
  const token = localStorage.getItem("@storage_Key");
  const headers = {
    Accept: "*/*",
    Authorization: `Bearer${" "}${token}`,
  };

  const options = {
    headers: headers,
  };

  try {
    return axios
      .delete(url, options)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  } catch (err) {
    console.log("err--", err);
  }
};
