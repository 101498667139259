import { forwardRef } from "react";
import React from "react";

import PropTypes from "prop-types";

import MDBoxRoot from "components/MDBox/MDBoxRoot";
const MDBox = forwardRef(({ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow, ...rest }, ref) => (
  <MDBoxRoot {...rest} ref={ref} ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow }} />
));
MDBox.displayName = "MDBox";

MDBox.defaultProps = {
  variant: "contained",
  bgColor: "transparent",
  color: "dark",
  opacity: 1,
  borderRadius: "none",
  shadow: "none",
  coloredShadow: "none",
};

MDBox.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  bgColor: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.number,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string,
  coloredShadow: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "light", "dark", "none"]),
};

export default MDBox;
