import React, { useEffect } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { useState } from "react";
import { GetTables } from "Apis/Apis";
import TableWithPagination from "./data/TableWithPagination";
import { useNavigate } from "react-router-dom";

function Tables() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tables, setTables] = useState([]);
  const [searchParams, setSearchParams] = useState("");

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  async function handleGetTables() {
    setLoading(true);
    const response = await GetTables(searchParams, currentPage, itemsPerPage);
    setTables(response?.data?.docs);
    setPageCount(Math.ceil(response?.data?.totalDocs / itemsPerPage));
    setLoading(false);
    setTotalDocs(response.data.totalDocs);
  }

  useEffect(() => {
    if (currentPage && itemsPerPage) {
      handleGetTables();
    }
  }, [searchParams, currentPage, itemsPerPage]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % totalDocs;
    setItemOffset(newOffset);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3} mt={-3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={2} display="flex" justifyContent="space-between" alignItems="center" mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography color="white" variant="h6" fontWeight="medium">
                  All Tables : {totalDocs}
                </MDTypography>
              </MDBox>
              <MDBox pt={2} display="flex" alignItems="center" mt={-1} py={3} px={2} sx={{ position: "relative", width: "250px" }}>
                <MDInput
                  value={searchParams}
                  sx={{ backgroundColor: "#ffffff", marginRight: "1rem", width: 220 }}
                  type="search"
                  label="Search ID"
                  onChange={(e) => {
                    setSearchParams(e.target.value);
                  }}
                  multiline
                  mr={"0px !important"}
                />
                <MDButton
                  disabled={!searchParams}
                  onClick={() => navigate(`/tables?id=${searchParams}`)}
                  variant="gradient"
                  color="dark"
                  sx={{ position: "absolute", right: "10px", padding: "0", minWidth: "3.3rem", minHeight: "2.8rem", opacity: "1 !important" }}
                >
                  <img src="/images/search-correct.png" />
                  &nbsp;
                </MDButton>
              </MDBox>
              <MDBox pt={-2} mt={-1}>
                <TableWithPagination
                  search={searchParams}
                  loading={loading}
                  data={tables}
                  handlePageClick={handlePageClick}
                  itemOffset={itemOffset}
                  pageCount={pageCount}
                  itemsPerPage={itemsPerPage}
                  setItemsPerPage={setItemsPerPage}
                  currentPage={currentPage}
                  totalCount={totalDocs}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
