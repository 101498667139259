import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Select } from "@mui/material";
import { FormControl } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { PostUser } from "Apis/Apis";
const styles = {
  formControlLabel: {
    fontSize: 15,
    fontWeight: "400",
    marginRight: 30,
    marginLeft: 4,
    marginTop: -7.5,
  },
};
const RoleData = [
  {
    id: 1,
    name: "editor",
  },
  {
    id: 2,
    name: "admin",
  },
  {
    id: 3,
    name: "super_admin",
  },
];

function AddUser() {
  const [formdata, setformdata] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "",
    send_email_notification: false,
  });

  const navigate = useNavigate();

  const [showerror] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {showerror ? <Alert severity="error">You are not authorized to perform this action</Alert> : ""}

      <MDBox pt={"10.5px"} pb={3} mt={-1}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                pt={2}
                mx={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={-3}
                py={"25px"}
                px={"24px"}
                bgColor="#227BE9"
                borderRadius="13px"
                coloredShadow="info"
                boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)"}
              >
                <MDTypography color="white" fontSize={"20px"} sx={{ fontWeight: "600" }}>
                  Add New User
                </MDTypography>
              </MDBox>

              {/* main body content */}

              <MDBox pt={4} pb={3} px={3} mt={-2}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDTypography fontSize={"13px"} lineHeight={"180%"} sx={{ fontWeight: "400", color: "grey" }}>
                      Create a brand new user and add them to this site.
                    </MDTypography>
                  </MDBox>

                  <MDBox mt={0}>
                    <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500", color: "#19114A" }}>
                      Email (Required){" "}
                    </MDTypography>
                    <TextField
                      name=""
                      id=""
                      type="email"
                      className="skillsName"
                      style={{ width: "50%" }}
                      onChange={(e) =>
                        setformdata({
                          ...formdata,
                          email: e.target.value,
                        })
                      }
                    />
                  </MDBox>
                  <MDBox mt={0}>
                    <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500", color: "#19114A" }}>
                      First Name{" "}
                    </MDTypography>
                    <TextField
                      name=""
                      id=""
                      className="skillsName"
                      style={{ width: "50%" }}
                      onChange={(e) =>
                        setformdata({
                          ...formdata,
                          first_name: e.target.value,
                        })
                      }
                    />
                  </MDBox>
                  <MDBox mt={0}>
                    <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500", color: "#19114A" }}>
                      Last Name{" "}
                    </MDTypography>
                    <TextField
                      name=""
                      id=""
                      className="skillsName"
                      style={{ width: "50%" }}
                      onChange={(e) =>
                        setformdata({
                          ...formdata,
                          last_name: e.target.value,
                        })
                      }
                    />
                  </MDBox>
                  <MDBox mt={0}>
                    <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500", color: "#19114A" }}>
                      password{" "}
                    </MDTypography>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Your New Password"
                      sx={{ width: "50%" }}
                      onChange={(e) =>
                        setformdata({
                          ...formdata,
                          password: e.target.value,
                        })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </MDBox>

                  <MDBox mt={2} sx={{ display: "flex", flexDirection: "row" }}>
                    <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500", color: "#19114A" }}>
                      Send User Notification{" "}
                    </MDTypography>
                    <FormGroup style={{ display: "flex", flexDirection: "row" }}>
                      <FormControlLabel
                        style={styles.formControlLabel}
                        control={
                          <Checkbox
                            checked={formdata.send_email_notification}
                            onChange={(e) =>
                              setformdata({
                                ...formdata,
                                send_email_notification: e.target.checked,
                              })
                            }
                          />
                        }
                        label={"Send the new user an email about their account."}
                      />
                    </FormGroup>
                  </MDBox>
                  <MDBox mt={2} sx={{ display: "flex", flexDirection: "row" }}>
                    <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500", color: "#19114A", marginTop: 0.3 }}>
                      User Role{" "}
                    </MDTypography>

                    <FormControl style={{ marginLeft: 10 }}>
                      <Select
                        native
                        defaultValue=""
                        onChange={(e) =>
                          setformdata({
                            ...formdata,
                            role: e.target.value,
                          })
                        }
                        id="grouped-native-select"
                        sx={{ height: 40 }}
                      >
                        <option value={1}>Select Role</option>

                        {RoleData.map((data, idx) => {
                          return (
                            <option key={idx} value={data.name}>
                              {data.name}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </MDBox>
                  <div mt={10}>
                    <MDButton
                      sx={{ backgroundColor: "#227BE9", "&:hover": { backgroundColor: "#227BE9" }, marginTop: 3, borderRadius: 20 }}
                      onClick={() => {
                        PostUser(formdata).then((e) => {
                          e.success ? navigate("/settings") : alert(e.message);
                        });
                      }}
                    >
                      <MDTypography sx={{ color: "#fff", fontSize: 15, fontWeight: "bold" }}>Add New User</MDTypography>
                    </MDButton>
                    <div style={{ marginLeft: -10, marginTop: -5 }}></div>
                  </div>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddUser;
