import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { DeleteTable, GetTables, GetTablesById } from "Apis/Apis";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Pagination } from "components/common/Pagination";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import React from "react";
import DeleteModal from "components/common/DeleteModal";
const headers = [
  {
    key: "text",
    title: "S No",
  },
  {
    key: "text",
    title: "Table Name",
  },
  {
    key: "text",
    title: "Description",
  },
  {
    key: "text",
    title: "ID",
  },
  {
    key: "text",
    title: "Last Modified",
  },
];

const TableWithPagination = ({ data, loading, search, handlePageClick, itemOffset, pageCount, itemsPerPage, setItemsPerPage, currentPage, totalCount }) => {
  const getparam = new URLSearchParams(window.location.search);
  // const [S_No, setS_No] = useState(0);
  const q = getparam.get("q");
  const id = getparam.get("id");
  // const dopt = getparam.get("dopt");
  const opt = getparam.get("opt");
  const myType = getparam.get("type");
  const [tables, setTables] = useState(data);
  const [templist, settemplist] = useState(data);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  async function getTablesList() {
    if (id) {
      const response = await GetTablesById(id);
      setTables([response?.data]);
      settemplist([response?.data]);
    } else {
      const response = await GetTables(search);
      setTables(response?.data?.docs);
      settemplist(response?.data?.docs);
    }
  }

  useEffect(() => {
    getTablesList();
  }, [id, tables?.length, search]);

  useEffect(() => {
    if (opt === "Tech") {
      settemplist(tables?.filter((item) => item?.category?.name === "Tech & Programming"));
    } else {
      settemplist(tables?.filter((item) => item?.category?.name === opt));
    }
  }, [opt]);

  useEffect(async () => {
    if (q?.length > 0) {
      settemplist(tables?.filter((post) => (post?.body || post?.title)?.toLocaleLowerCase()?.includes(q.toLocaleLowerCase())));
    } else {
      if (!myType || myType === "All") {
        settemplist(tables);
      } else if (myType === "Mine") {
        settemplist(tables?.filter((item) => item["user"]?._id === localStorage.getItem("@my_user_id")));
      } else if (myType === "Published") {
        settemplist(tables?.filter((item) => item.status === "published"));
      } else if (myType === "Draft") {
        settemplist(tables?.filter((item) => item.status === "draft"));
      } else if (myType === "Pending") {
        settemplist(tables?.filter((item) => item.status === "pending"));
      } else if (myType === "Trash") {
        settemplist(tables?.filter((item) => item.status === "trash"));
      }
    }
  }, [q, myType]);
  const Title = ({ title, sx }) => (
    <MDBox lineHeight={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <MDTypography display="block" variant="caption" sx={{ width: "100%", fontColor: "0A0529", fontWeight: "500", fontSize: "14px", ...sx }}>
        <span>{title}</span>
      </MDTypography>
    </MDBox>
  );

  const spanStyle = {
    cursor: "pointer",
    marginRight: "6px",
    color: "black",
  };
  const handleDeleteRow = async () => {
    try {
      setDeleting(true);
      await DeleteTable(deleteRowId);
      let update = templist.filter((el) => el._id !== deleteRowId);
      toast.success("Table Data deleted successfully");
      settemplist(update);
      setDeleteRowId(null);
      setDeleting(false);
      setDeleteModal(false);
    } catch (err) {
      toast.error("Error while deleting table");
    }
  };
  const handleShortCodeCopy = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        toast.success("Short Code Copied");
      })
      .catch(() => {
        toast.error("Error While Copying, Try again !");
      });
  };
  const loaderArray = new Array(itemsPerPage).fill(itemsPerPage);

  return (
    // <TableContainer component={Paper}>
    //   <Table>
    //     <TableBody>
    //       <TableRow>
    //         {headers.map((h, i) => (
    //           <TableCell key={i}>
    //             <Typography fontSize={"16px"} fontWeight={"500"}>
    //               {h.title}
    //             </Typography>
    //           </TableCell>
    //         ))}
    //       </TableRow>
    //       {loading
    //         ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((l, i) => {
    //             return (
    //               <TableRow key={i}>
    //                 {/* currently no image skeleton, but will automatically work if the key is image */}
    //                 {headers.map((h, ind) => {
    //                   const variant = h.key === "image" ? "circular" : "rounded";
    //                   const styles = h.key === "image" ? { width: "30px", height: "30px" } : { width: "100%", height: "20px" };
    //                   return (
    //                     <TableCell key={ind}>
    //                       <Skeleton animation="wave" sx={styles} variant={variant} />
    //                     </TableCell>
    //                   );
    //                 })}
    //               </TableRow>
    //             );
    //           })
    //         : data.map((el, index) => {
    //             return (
    //               <TableRow
    //                 sx={{
    //                   "&:hover": {
    //                     background: "#f5f5f5",
    //                   },
    //                 }}
    //                 key={index}
    //               >
    //                 <TableCell>{1 * 10 + index + 1}</TableCell>
    //                 <TableCell>
    //                   <Title sx={{ color: "dodgerblue" }} title={el?.name} />
    //                   <MDBox mt={1} sx={{ display: "flex", justifyContent: "flex-start" }}>
    //                     {/* extra design change */}
    //                     <div style={{ fontSize: "14px", fontWeight: "500" }}>
    //                       <span
    //                         onMouseOut={(e) => (e.target.style.color = "black")}
    //                         onMouseOver={(e) => (e.target.style.color = "#227BE9")}
    //                         style={spanStyle}
    //                         onClick={() => {
    //                           navigate(`/add-table/${el._id}`);
    //                         }}
    //                       >
    //                         Edit
    //                       </span>
    //                       |{" "}
    //                       <span
    //                         onMouseOut={(e) => (e.target.style.color = "black")}
    //                         onMouseOver={(e) => (e.target.style.color = "red")}
    //                         style={spanStyle}
    //                         onClick={() => {
    //                           setDeleteRowId(el._id);
    //                           setDeleteModal(true);
    //                         }}
    //                       >
    //                         Delete
    //                       </span>
    //                       |
    //                       <span
    //                         onMouseOut={(e) => (e.target.style.color = "black")}
    //                         onMouseOver={(e) => (e.target.style.color = "#227BE9")}
    //                         style={spanStyle}
    //                         onClick={() => {
    //                           handleShortCodeCopy(el.short_code);
    //                         }}
    //                       >
    //                         Copy Short_code
    //                       </span>
    //                     </div>
    //                   </MDBox>
    //                 </TableCell>
    //                 <TableCell sx={{ maxWidth: "230px" }}>
    //                   <Title title={el?.description} sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} />
    //                 </TableCell>
    //                 <TableCell>
    //                   <Title sx={{ color: "dodgerblue" }} title={el?._id} />
    //                 </TableCell>
    //                 <TableCell>
    //                   <Title title={moment(el?.updatedAt).fromNow()} />
    //                 </TableCell>
    //               </TableRow>
    //             );
    //           })}

    //       {emptyRows > 0 && (
    //         <TableRow style={{ height: 53 * emptyRows }}>
    //           <TableCell colSpan={3} />
    //         </TableRow>
    //       )}
    //     </TableBody>
    //   </Table>
    //   <DeleteModal open={deleteModal} handleClose={() => setDeleteModal(false)} handleDelete={handleDeleteRow} loading={deleting} />

    //   <Pagination
    //     handlePageClick={handlePageClick}
    //     itemOffset={itemOffset}
    //     pageCount={pageCount}
    //     itemsPerPage={itemsPerPage}
    //     setItemsPerPage={setItemsPerPage}
    //     currentPage={currentPage}
    //     totalCount={totalCount}
    //   />
    // </TableContainer>
    <>
      {!data?.length && !loading ? (
        <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginTop: "5%", marginBottom: "5%" }}>
          <Title title="No Tables found.." />
        </div>
      ) : (
        <Paper sx={{ width: "100%", borderRadius: "15px", minHeight: "80vh" }}>
          <TableContainer sx={{ borderRadius: "0px" }}>
            <Table>
              <TableHead my={"2px"}>
                <TableRow sx={{ height: "30px", zIndex: "100" }}>
                  {headers?.length &&
                    headers?.map((header, index) => (
                      <TableCell
                        key={index}
                        align={`${index === 0 ? "center" : "start"}`}
                        width={`${index === 0 ? "60px" : ""}`}
                        sx={{ fontSize: "16px", fontWeight: "600", color: "#19114A", fontFamily: "Inter", padding: "12px 0px", backgroundColor: "#F0F2F5" }}
                      >
                        {header?.title}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading
                  ? data?.map((el, index) => (
                      <TableRow
                        sx={{
                          "&:hover": {
                            background: "#f5f5f5",
                          },
                        }}
                        key={index}
                      >
                        <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                        <TableCell>
                          <Title sx={{ color: "dodgerblue" }} title={el?.name} />
                          <MDBox mt={1} sx={{ display: "flex", justifyContent: "flex-start" }}>
                            <div style={{ fontSize: "14px", fontWeight: "500" }}>
                              <span
                                onMouseOut={(e) => (e.target.style.color = "black")}
                                onMouseOver={(e) => (e.target.style.color = "#227BE9")}
                                style={spanStyle}
                                onClick={() => {
                                  window.open(`/add-table/${el._id}`, "_blank");
                                }}
                                target="_blank"
                              >
                                Edit
                              </span>
                              |{" "}
                              <span
                                onMouseOut={(e) => (e.target.style.color = "black")}
                                onMouseOver={(e) => (e.target.style.color = "red")}
                                style={spanStyle}
                                onClick={() => {
                                  setDeleteRowId(el._id);
                                  setDeleteModal(true);
                                }}
                              >
                                Delete
                              </span>
                              |
                              <span
                                onMouseOut={(e) => (e.target.style.color = "black")}
                                onMouseOver={(e) => (e.target.style.color = "#227BE9")}
                                style={spanStyle}
                                onClick={() => {
                                  handleShortCodeCopy(el.short_code);
                                }}
                              >
                                Copy Short_code
                              </span>
                            </div>
                          </MDBox>
                        </TableCell>
                        <TableCell sx={{ maxWidth: "230px" }}>
                          <Title title={el?.description} sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} />
                        </TableCell>
                        <TableCell>
                          <Title sx={{ color: "dodgerblue" }} title={el?._id} />
                        </TableCell>
                        <TableCell>
                          <Title title={moment(el?.updatedAt).fromNow()} />
                        </TableCell>
                      </TableRow>
                    ))
                  : loaderArray.map((l, i) => {
                      return (
                        <TableRow key={i}>
                          {headers.map((h, i) => {
                            const variant = h.key === "image" ? "circular" : "rounded";
                            const styles = h.key === "image" ? { width: "30px", height: "30px" } : { width: "100%", height: "20px" };
                            return (
                              <TableCell key={i}>
                                <Skeleton animation="wave" sx={styles} variant={variant} />
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            handlePageClick={handlePageClick}
            itemOffset={itemOffset}
            pageCount={pageCount}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            totalCount={totalCount}
          />
        </Paper>
      )}
      <DeleteModal open={deleteModal} handleClose={() => setDeleteModal(false)} handleDelete={handleDeleteRow} loading={deleting} />
    </>
  );
};

export default TableWithPagination;
