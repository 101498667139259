export const supportTabs = [
  {
    value: "tickets",
    label: "Tickets",
  },
  {
    value: "priority",
    label: "Priority",
  },
  {
    value: "closed",
    label: "closed",
  },
];

export const timingOptions = [
  {
    count: 3,
    unit: "days",
    value: "3d",
  },
  {
    count: 7,
    unit: "days",
    value: "7d",
  },
  {
    count: 28,
    unit: "days",
    value: "28d",
  },
  {
    count: 3,
    unit: "months",
    value: "3m",
  },
  {
    count: 6,
    unit: "months",
    value: "6m",
  },
  {
    count: 9,
    unit: "months",
    value: "9m",
  },
];

export const chatList = [
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "closed",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "closed",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "closed",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "closed",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "closed",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "closed",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "active",
  },
  {
    title: "Issue with the payment",
    ticketId: "150989715",
    date: Date.now() - Math.round(Math.random() * 100),
    status: "closed",
  },
];

export const priorities = [
  {
    value: "low",
    label: "Low Priority",
  },
  {
    value: "medium",
    label: "Medium Priority",
  },
  {
    value: "high",
    label: "High Priority",
  },
];

export const chats = [
  {
    text: "Hey, I need some support",
    position: "left",
    date: Date.now() - Math.random(Math.round() * 100),
  },
  {
    text: `Hello. We see that you bank is yet to refund $6000 for this failed payment.

    As per banking timelines, your money should have 
    reached you by Thu, 16 Mar 2023 08:51 AM. As your
    bank is taking additional time, Please contact them with Unique Transaction Reference (UTR) number for this payment. They are best placed to help you get your refund faster.
    
    Please don’t worry, your money is absolutely safe and will be refunded after you contact your bank for help.`,
    position: "left",
    date: Date.now() - Math.random(Math.round() * 100),
  },
  {
    text: "Yes, Our team will reach out to you with a ticket soon.",
    position: "right",
    date: Date.now() - Math.random(Math.round() * 100),
  },
  {
    text: `Hello. We see that you bank is yet to refund $6000 for this failed payment.
    `,
    position: "left",
    date: Date.now() - Math.random(Math.round() * 100),
  },
  {
    text: "We are facing technical problems. We are trying hard to fix this problem. We will contact with you soon.",
    position: "right",
    date: Date.now() - Math.random(Math.round() * 100),
  },
  {
    text: `Hello. We see that you bank is yet to refund $6000 for this failed payment.

    As per banking timelines, your money should have 
    reached you by Thu, 16 Mar 2023 08:51 AM. As your
    bank is taking additional time, Please contact them with Unique Transaction Reference (UTR) number for this payment. They are best placed to help you get your refund faster.
    
    Please don’t worry, your money is absolutely safe and will be refunded after you contact your bank for help.`,
    position: "left",
    date: Date.now() - Math.random(Math.round() * 100),
  },
];
