import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import filter from "../../../assets/images/filtericon.png";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import searchcorrect from "../../../assets/images/Searchcorrect.png";
import usePagination from "hooks/usePagination";
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useFetch } from "hooks/useFetch";
import { BASE_PATH } from "utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Pagination } from "components/common/Pagination";

const FilterData = [
  {
    id: 1,
    name: "Fixed Price",
    value: "fixed-price",
  },
  {
    id: 2,
    name: "Hourly Rate",
    value: "hourly",
  },
];
function ActiveContracts() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const { page, limit, setPage, setLimit } = usePagination();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("q");
  const filterType = searchParams.get("type");
  const { data, loading } = useFetch(`${BASE_PATH}/jobs?limit=${limit}&offset=${limit * page}${filterType ? `&project_type=${filterType}` : ""}${search ? `&search=${search}` : ""}`, {
    refetch: null,
  });

  const jobs = data?.data?.docs;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Image = ({ image }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} size="xs" />
    </MDBox>
  );
  const [filterState, setFilterState] = useState("");
  function handleChange(e) {
    // have to ask that can we use debouncing here
    searchParams.set("q", e.target.value);
    setSearchParams(searchParams);
  }
  const Title = ({ title, sx }) => (
    <MDBox lineHeight={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <MDTypography display="block" variant="caption" sx={{ width: "100%", fontColor: "0A0529", fontWeight: "500", fontSize: "14px", ...sx }}>
        {title}
      </MDTypography>
    </MDBox>
  );
  const handleView = (id) => {
    navigate(`/jobdetails?q=${id}`);
  };
  const Actions = ({ data }) => (
    <MDTypography display="block" variant="caption" sx={{ fontColor: "0A0529", fontWeight: "700", cursor: "pointer", fontSize: "14px" }}>
      <span onClick={() => handleView(data?._id)}>View Details</span>
    </MDTypography>
  );

  function handleApplyClick() {
    searchParams.set("type", filterState);
    setSearchParams(searchParams);
  }

  const loaderArray = new Array(limit).fill("a");
  const totalPages = data?.data?.totalPages || Math.ceil(data?.data?.totalDocs / data?.data?.limit);
  const headers = [
    {
      type: "image",
      title: "Image",
    },
    {
      type: "text",
      title: "Name",
    },
    {
      type: "text",
      title: "Job ID",
    },
    {
      type: "text",
      title: "Project Type",
    },
    {
      type: "text",
      title: "Country",
    },
    {
      type: "text",
      title: "Budget",
    },
    {
      type: "text",
      title: "Action",
    },
  ];
  return (
    <DashboardLayout>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Filter</DialogTitle>
        <MDBox mx={1.5} sx={{ marginBottom: 3 }}>
          {FilterData.map((job, idx) => {
            return (
              <MDBox
                key={idx}
                mt={2}
                value={filterState}
                onClick={() => {
                  setFilterState(job.value);
                }}
                sx={{ border: "1px solid #A9A8A8", paddingTop: 1, borderRadius: 2, paddingLeft: 2, width: 350, paddingBottom: 1, backgroundColor: [filterState == job.value ? "#227BE9" : ""] }}
                fullWidth
              >
                <MDTypography sx={{ fontSize: 14, fontWeight: "300", color: [filterState == job.value ? "#ffffff" : "#292929"] }}>{job.name}</MDTypography>
              </MDBox>
            );
          })}
        </MDBox>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <MDBox
            onClick={() => setOpen(false)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#227BE9",
              borderRadius: 2,
              border: "1px solid #A9A8A8",
              cursor: "pointer",
              width: 200,
              marginBottom: 5,
            }}
          >
            <MDTypography onClick={handleApplyClick} px={4} py={1} sx={{ fontSize: 15, fontWeight: "700", color: "#FFFFFF" }}>
              Apply
            </MDTypography>
          </MDBox>
        </div>
      </Dialog>
      <DashboardNavbar />
      <MDBox pt={"30.5px"} pb={3} mt={0}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mx={2}
                mt={-6}
                py={"17px"}
                px={"24px"}
                bgColor="#227BE9"
                borderRadius="13px"
                coloredShadow="info"
                boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)"}
              >
                <MDTypography color="white" fontSize="20px" fontWeight="medium" lineHeight="24px">
                  Active Contracts : {data?.data?.totalDocs}
                </MDTypography>
                <MDBox px={"20px"} py={"8px"} border="1px solid white" color="white" borderRadius="8px" fontSize="20px" lineHeight="17px" fontWeight="regular">
                  Export File
                </MDBox>
              </MDBox>
              <MDBox pt={3} display="flex" flexDirection="row" justifyContent="space-between">
                <MDBox pt={2} display="flex" alignItems="center" mt={-4} py={3} px={2} sx={{ position: "relative" }}>
                  <MDInput
                    onChange={handleChange}
                    id="search_input"
                    sx={{ backgroundColor: "#ffffff", marginRight: "1rem", width: 290 }}
                    type="search"
                    multiline
                    required
                    placeholder="Search Job ID or title"
                    mr={"0px !important"}
                    fullWidth
                  />
                  <MDButton variant="gradient" color="dark" sx={{ position: "absolute", right: "10px", padding: "0", minWidth: "3.3rem", minHeight: "2.8rem", opacity: "1 !important" }}>
                    <Image image={searchcorrect} />
                  </MDButton>
                </MDBox>{" "}
                <MDBox pt={2} display="flex" alignItems="center" mt={-4} py={3} mx={4} sx={{ flexDirection: "row" }}>
                  <MDTypography sx={{ fontSize: 15, fontWeight: "500" }}>Filter</MDTypography>
                  <img src={filter} style={{ marginLeft: 15, cursor: "pointer" }} onClick={() => handleClickOpen()} />
                </MDBox>
              </MDBox>
              <MDBox>
                <MDBox mt={2}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          {headers.map((h, i) => (
                            <TableCell key={i}>
                              <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "#19114A" }}>{h.title}</Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                        {!loading
                          ? jobs?.map((item, index) => {
                              return (
                                <TableRow
                                  sx={{
                                    "&:hover": {
                                      background: "#f5f5f5",
                                    },
                                  }}
                                  key={index}
                                >
                                  <TableCell>
                                    <Image image={"https://cdn-icons-png.flaticon.com/512/149/149071.png"} />
                                  </TableCell>
                                  <TableCell>
                                    <Title title={item.title} />
                                  </TableCell>
                                  <TableCell>
                                    <Title title={item._id} />
                                  </TableCell>
                                  <TableCell>
                                    <Title title={item.project_type} />
                                  </TableCell>
                                  <TableCell>
                                    <Title title={"United States"} />
                                  </TableCell>
                                  <TableCell>
                                    <Title title={item.project_type === "hourly" ? `$${item.hourly_rate_range.min} - $${item.hourly_rate_range.min}` : `$${item?.budget}`} />
                                  </TableCell>
                                  <TableCell>
                                    <Actions data={item} />
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          : loaderArray.map((l, i) => {
                              return (
                                <TableRow key={i}>
                                  {headers.map((h, i) => {
                                    const variant = h.type === "image" ? "circular" : "rounded";
                                    const styles = h.type === "image" ? { width: "30px", height: "30px" } : { width: "100%", height: "20px" };
                                    return (
                                      <TableCell key={i}>
                                        <Skeleton animation="wave" sx={styles} variant={variant} />
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Pagination
                    currentPage={page + 1}
                    pageCount={totalPages}
                    totalCount={data?.data?.totalDocs}
                    handlePageClick={({ selected }) => setPage(selected)}
                    setItemsPerPage={setLimit}
                    itemsPerPage={limit}
                    itemOffset={limit * page}
                  />
                </MDBox>
                <MDBox mt={-6} display="flex" flexDirection={{ xs: "column", sm: "row" }} justifyContent="flex-end" alignItems={{ xs: "flex-start", sm: "center" }} p={3}></MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ActiveContracts;
