export const headers = [
  {
    key: "image",
    title: "Icons",
  },
  {
    key: "text",
    title: "Title",
  },
  {
    key: "text",
    title: "Description",
  },
  {
    key: "text",
    title: "Type",
  },
  {
    key: "text",
    title: "Send On",
  },
];

export const filterOptions = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "client",
    label: "client",
  },
  {
    value: "freelancer",
    label: "freelancer",
  },
];
