import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import { useFetch } from "hooks/useFetch";
import { BASE_PATH } from "utils";
import { Toast } from "components/common/Toast";
import { axiosDelete } from "Apis";
import React from "react";
import { CircularProgress, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import usePagination from "hooks/usePagination";
import { Pagination } from "components/common/Pagination";
import { axiosRequestPromise } from "Apis";
import moment from "moment";

export const truncateString = (str, len) => {
  return str.length > len ? str.slice(0, len) + "..." : str.slice(0, len);
};

function ToolRequests() {
  const Title = ({ title, sx }) => (
    <MDBox lineHeight={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <MDTypography display="block" variant="caption" sx={{ width: "100%", fontWeight: "800", fontColor: "#227BE9", ...sx }}>
        {title}
      </MDTypography>
    </MDBox>
  );
  const location = useLocation();
  const [query, setQuery] = useState("");
  const { page, limit, setPage, setLimit } = usePagination();
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(Math.random());
  const [successSnack, setSuccessSnack] = useState({
    open: false,
    type: "",
    message: "",
  });

  const { data: toolsResponse, loading: loadingTools } = useFetch(`${BASE_PATH}/tool-requests?limit=${limit}&offset=${page * limit}${!!query?.length ? `&search=${query}` : ""}`, { refetch });

  const tools = toolsResponse?.data?.docs || [];

  function handleSearch(event) {
    setQuery(event.target.value);
  }

  const handleDelete = async (id) => {
    axiosDelete(`${BASE_PATH}/tool-requests/${id}`).then((res) => {
      if (res?.data?.success) {
        setSuccessSnack({ open: true, type: "success", message: "Tool Request deleted successfully" });
        setRefetch(Math.random());
      } else {
        setSuccessSnack({ open: true, type: "error", message: "Failed to delete Tool Request" });
      }
    });
  };

  const handleAdd = async (id, name, description) => {
    axiosRequestPromise({
      method: "post",
      url: `${BASE_PATH}/tools`,
      body: {
        name,
        description,
      },
    }).then((res) => {
      if (res?.data?.success) {
        axiosDelete(`${BASE_PATH}/tool-requests/${id}`).then((res) => {
          if (res?.data?.success) {
            setSuccessSnack({ open: true, type: "success", message: "Tool Request deleted successfully" });
            setRefetch(Math.random());
          } else {
            setSuccessSnack({ open: true, type: "error", message: "Failed to delete Tool Request" });
          }
        });
      } else {
        setSuccessSnack({ open: true, type: "error", message: "Failed to delete Tool Request" });
      }
    });
  };

  useEffect(() => {
    if (location?.search.includes("refetch")) {
      setRefetch(Math.random());
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [location]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toast message={successSnack.message} type={successSnack.type} open={successSnack?.open} onClose={() => setSuccessSnack({ ...successSnack, open: false })} />
      <MDBox pt={"10.5px"} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                pt={2}
                mx={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={-3}
                py={"24px"}
                px={"24px"}
                bgColor="#227BE9"
                borderRadius="13px"
                coloredShadow="info"
                boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)"}
              >
                <MDTypography color="white" variant="h6" fontWeight="medium" fontSize="20px" lineHeight="24px">
                  Tool Requests
                </MDTypography>
              </MDBox>
              <MDBox pt={3} display="flex" flexDirection="row">
                <MDBox pt={2} display="flex" alignItems="center" mt={-4} py={3} px={2} sx={{ position: "relative" }}>
                  <MDInput
                    value={query}
                    onChange={handleSearch}
                    sx={{ backgroundColor: "#ffffff", marginRight: "1rem", width: "100%" }}
                    type="search"
                    label="Search Tool"
                    multiline
                    required
                    mr={"0px !important"}
                  />

                  <MDButton variant="gradient" color="dark" sx={{ position: "absolute", right: "10px", padding: "0", minWidth: "3.3rem", minHeight: "2.8rem", opacity: "1 !important" }}>
                    <img src={"/images/search-correct.png"} />
                    &nbsp;
                  </MDButton>
                </MDBox>
              </MDBox>
              {!loading && !loadingTools && (
                <TableContainer sx={{ width: "100%" }}>
                  <TableHead>
                    <TableRow
                      sx={{
                        "&:hover": {
                          background: "#f5f5f5",
                        },
                      }}
                    >
                      <TableCell>
                        <Title title={"Name"} />
                      </TableCell>
                      <TableCell>
                        <Title title={"ID"} />
                      </TableCell>
                      <TableCell>
                        <Title title={"Requested AT"} />
                      </TableCell>
                      <TableCell>
                        <MDTypography display="block" variant="caption" sx={{ fontColor: "0A0529", fontWeight: "700", cursor: "pointer", fontSize: "14px" }}>
                          Actions
                        </MDTypography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tools?.map((tool) => (
                      <>
                        <TableRow>
                          <TableCell>
                            <Title title={tool?.name ?? "-"} />
                          </TableCell>
                          <TableCell>
                            <Title title={tool?._id ?? "-"} />
                          </TableCell>
                          <TableCell>
                            <Title title={moment(tool.createdAt).format("DD/MM/YYYY hh:mm A")} />
                          </TableCell>
                          <TableCell>
                            <MDTypography display="block" variant="caption" sx={{ fontColor: "0A0529", fontWeight: "700", cursor: "pointer", fontSize: "14px" }}>
                              <span onClick={() => handleAdd(tool?._id, tool?.name, tool?.description)}>Add</span>
                              {" | "}
                              <span onClick={() => handleDelete(tool?._id)}>Delete</span>
                            </MDTypography>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </TableContainer>
              )}
              {(loading || loadingTools) && (
                <div style={{ width: "100%", display: "flex", justifyContent: "center", height: "300px" }}>
                  <CircularProgress />
                </div>
              )}
              <Pagination
                currentPage={page + 1}
                pageCount={toolsResponse?.data?.totalPages || 0}
                totalCount={toolsResponse?.data?.totalDocs || 0}
                handlePageClick={({ selected }) => setPage(selected)}
                setItemsPerPage={setLimit}
                itemsPerPage={limit}
                itemOffset={limit * page}
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ToolRequests;
