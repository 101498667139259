export const dummyData = () => {
  let data = [];
  for (let i = 0; i < 10; i++) {
    let obj = { title: "", categories: "", role: "", lastMofidied: "" };
    data.push(obj);
  }
  return data;
};
import { Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

const DummySkleton = () => {
  return (
    <>
      {dummyData().map((el, id) => (
        <TableRow key={id}>
          <TableCell sx={{ textAlign: "center", color: "black" }}>
            <Skeleton animation="wave" sx={{ height: "20px" }} variant={"rounded"} />
          </TableCell>
          <TableCell sx={{ textAlign: "center", color: "black" }}>
            <Skeleton animation="wave" sx={{ height: "20px" }} variant={"rounded"} />
          </TableCell>
          <TableCell sx={{ textAlign: "center", color: "black" }}>
            <Skeleton animation="wave" sx={{ height: "20px" }} variant={"rounded"} />
          </TableCell>
          <TableCell sx={{ textAlign: "center", color: "black" }}>
            <Skeleton animation="wave" sx={{ height: "20px" }} variant={"rounded"} />
          </TableCell>
          <TableCell sx={{ textAlign: "center", color: "black" }}>
            <Skeleton animation="wave" sx={{ height: "20px" }} variant={"rounded"} />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default DummySkleton;
