import { TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LOGGED_IN_USER_ROLE } from "utils/common";
const ListTableRow = ({ data, handleTrash }) => {
  const navigate = useNavigate();
  const handleEditPage = (el) => {
    navigate(`/success-story/edit-page?id=${el._id}`);
  };
  const handleView = (el) => {
    navigate(`/success-story/edit-page?id=${el._id}`);
  };
  const spanStyle = {
    cursor: "pointer",
    marginRight: "6px",
    color: "black",
  };
  return (
    <TableRow hover role="checkbox" color="black">
      <TableCell sx={{ textAlign: "center", color: "black" }}>{data.sno}</TableCell>
      <TableCell>
        <img src={data.Image} style={{ height: "40px", borderRadius: "50%", width: "40px" }} />
      </TableCell>
      <TableCell>
        <div style={{ textAlign: "start" }}>
          <p style={{ color: "#227BE9", fontWeight: "bold", fontSize: "16px" }}> {data.Name} </p>{" "}
          <div style={{ fontSize: "14px", fontWeight: "500" }}>
            {LOGGED_IN_USER_ROLE === "super_admin" && (
              <span onMouseOut={(e) => (e.target.style.color = "black")} onMouseOver={(e) => (e.target.style.color = "#227BE9")} style={spanStyle} onClick={() => handleEditPage(data)}>
                Edit Page
              </span>
            )}
            |{" "}
            {LOGGED_IN_USER_ROLE === "super_admin" && (
              <span onMouseOut={(e) => (e.target.style.color = "black")} onMouseOver={(e) => (e.target.style.color = "#227BE9")} style={spanStyle} onClick={() => handleTrash(data)}>
                Trash
              </span>
            )}
            |{" "}
            <span onMouseOut={(e) => (e.target.style.color = "black")} onMouseOver={(e) => (e.target.style.color = "#227BE9")} style={spanStyle} onClick={() => handleView(data)}>
              Preview
            </span>
          </div>{" "}
        </div>
      </TableCell>
      <TableCell>
        <Typography fontSize={15}>{data.Status}</Typography>
      </TableCell>
      <TableCell>
        <Typography fontSize={15}>{data.Slug}</Typography>
      </TableCell>
      <TableCell>
        <Typography fontSize={15}>{data.Last_Modified}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default ListTableRow;
