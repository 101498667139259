import { Dialog, TableCell, TableRow } from "@mui/material";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import MessagesModal from "./MessagesModal";

const ReportTable = ({ report }) => {
  const [msgModal, setMsgModal] = useState(null);
  return (
    <>
      <TableRow>
        <TableCell>
          <MDTypography sx={{ fontSize: 15, fontWeight: "400" }}>
            {report.created_by?.first_name} {report?.created_by?.last_name}
          </MDTypography>
        </TableCell>
        <TableCell>
          <MDTypography sx={{ fontSize: 15, fontWeight: "400" }}>{new Date(report.createdAt).toLocaleDateString()}</MDTypography>
        </TableCell>
        <TableCell>
          <MDTypography sx={{ fontSize: 15, fontWeight: "400", maxWidth: "400px" }}>{report.description}</MDTypography>
        </TableCell>
        <TableCell>
          {report.conversation_id ? (
            <MDTypography onClick={() => setMsgModal(report?.conversation_id)} sx={{ fontSize: 15, fontWeight: "400", cursor: "pointer", color: "blue" }}>
              Message
            </MDTypography>
          ) : (
            <MDTypography sx={{ fontSize: 15, fontWeight: "400" }}>Job</MDTypography>
          )}
        </TableCell>
        <TableCell>
          <MDTypography sx={{ fontSize: 15, fontWeight: "400" }}>{report._id.toUpperCase()}</MDTypography>
        </TableCell>
      </TableRow>
      <Dialog open={msgModal} fullWidth={true} maxWidth="sm" onClose={() => setMsgModal(null)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <MessagesModal conversation_id={msgModal} />
      </Dialog>
    </>
  );
};

export default ReportTable;
