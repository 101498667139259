import { Box, styled } from "@mui/material";

export const CustomSelect = styled("input")({
  padding: "16px 12px",
  border: "1px solid #ddd",
  background: "none",
  borderRadius: 5,
  color: "#555",
  fontSize: "14px",
  width: "100%",
});

export const InputContainer = styled(Box)({
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
});

export const TransparentButton = styled("button")({
  position: "absolute",
  top: 0,
  right: 0,
  background: "transparent",
  border: "none",
  padding: "14px 8px",
  color: "#555",
  fontSize: "24px",
});

export const DropButton = styled("button")({
  position: "absolute",
  top: 0,
  right: 0,
  background: "dodgerblue",
  border: "none",
  padding: "16px 10px",
  color: "#fff",
  fontSize: "16px",
  borderRadius: 5,
});

export const CountryContainer = styled(Box)({
  height: 220 - 50 - 3,
  width: "100%",
  overflow: "hidden",
  overflowY: "scroll",
  padding: "10px 0",
  "&::-webkit-scrollbar": {
    width: "14px",
  },
  "&::-webkit-scrollbar-thumb": {
    border: "4px solid rgba(0, 0, 0, 0)",
    backgroundClip: "padding-box",
    borderRadius: "9999px",
    backgroundColor: "#AAAAAA",
  },
});

export const CountriesListWrapper = styled(Box)({
  position: "absolute",
  top: 50,
  background: "#fff",
  border: "1px solid #ddd",
  borderRadius: 5,
  minWidth: "100%",
  height: 270,
  zIndex: 99,
});

export const CountriesList = styled(Box)({
  fontSize: 14,
  padding: "5px 10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  columnGap: 5,
});

export const FooterButtons = styled(Box)({
  background: "#fff",
  borderTop: "1px solid #ddd",
  padding: "5px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  columnGap: 10,
  borderRadius: "0 0 5px 5px",
  height: 50,
});

export const PrimaryButton = styled("button")({
  width: "100%",
  padding: "10px 16px",
  border: "1px solid #ddd",
  background: "#fff",
  borderRadius: 5,
});

export const SecondaryButton = styled("button")({
  width: "100%",
  padding: "10px 16px",
  border: "1px solid #ddd",
  background: "dodgerblue",
  color: "#fff",
  borderRadius: 5,
});

export const FormControllContainer = styled(Box)({
  justifyContent: "center",
  alignItems: "center",
});

export const LocationWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
});

export const AddFAQBtnWrapper = styled(Box)({
  border: "3px solid grey",
  borderRadius: 10,
  padding: 6,
  cursor: "pointer",
  marginRight: 20,
});

export const FAQWrapper = styled(Box)({
  width: "100%",
});

export const RemoveFAQBtn = styled(Box)({
  border: "1px solid red",
  borderRadius: 10,
  padding: "8px 12px",
  cursor: "pointer",
  marginRight: 20,
});

export const Title = styled("p")({
  fontSize: 14,
  fontWeight: "500",
});

export const FlexContainer = styled(Box)({
  display: "flex",
});

export const SublocationContainer = styled(Box)({
  position: "relative",
  borderRight: "1px solid #ddd",
  width: "100%",
});
