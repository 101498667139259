import { Grid, Card, Button, Typography, Skeleton, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import { Title } from "layouts/Post/AddPost/elements";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { Suspense, useEffect, useState } from "react";
import InputController from "components/ui/form/input-controller";
import { useForm } from "react-hook-form";
import { SLUG_REGEX } from "utils/common";
import RichTextEditorController from "components/ui/form/rich-text-editor-controller";
import { v4 as uuid } from "uuid";
import FileInputController from "components/ui/form/file-input-controller";
import { seoDetails } from "layouts/Post/data";
import { useNavigate, useSearchParams } from "react-router-dom";
import MDButton from "components/MDButton";
import { axiosRequestPromise } from "Apis";
import { BASE_PATH } from "utils";
import CategoryDropdown from "./successCategory/CategoryDropdown";
import dropDown from "assets/images/downarrow.png";
import axios from "axios";
import { axiosRequest } from "Apis";
import { UploadFileRequest } from "Apis/Apis";
import { LOGGED_IN_USER_ROLE } from "utils/common";
import { useFetch } from "hooks/useFetch";
import { toast } from "react-hot-toast";
const publishList = [
  {
    label: "Published",
    value: "published",
    disabled: LOGGED_IN_USER_ROLE === "super_admin" ? false : true,
  },
  {
    label: "Not Published",
    value: "trash",
    disabled: LOGGED_IN_USER_ROLE === "super_admin" ? false : true,
  },
  {
    label: "Draft",
    value: "draft",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

const SuccessStoryPageEdit = () => {
  const [formData, setFormData] = useState({ status: "draft" });
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [slugExist, setSlugExist] = useState(false);
  const [skill, setSkill] = useState({});
  const [skillsData, setSkillsData] = useState([]);
  const [parent, setParent] = useState("None");
  const [isLoading, setIsLoading] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [query, setQuery] = useState("");
  const [skills, setSkills] = useState([]);
  const [statusData, setStatusData] = useState({ label: "", value: "" });
  const [isProfileVerified, setIsProfileVerified] = useState(false);
  const navigate = useNavigate();

  const fetchSuccessStoryCategories = async () => {
    try {
      setIsLoading(true);
      let res = await axios.get(`${BASE_PATH}/stories/categories/dropdown`);
      setCategories(res.data?.data.reverse());
      setIsLoading(false);
    } catch (err) {
      toast.error("Something went wrong !");
      setIsLoading(false);
    }
  };
  const { data, loading: loadingSkills } = useFetch(`${BASE_PATH}/skills?${!!query?.length ? `&search=${query}` : ""}`);
  useEffect(() => {
    if (data) {
      let newData = data?.data?.docs.map((el) => ({ ...el, label: el.name, value: el.name })) || [];
      setSkills(newData);
    }
  }, [data]);

  const handleAddSkills = (skill) => {
    let isDuplicate = skillsData.filter((el) => el._id === skill._id);
    if (isDuplicate.length) {
      toast.error("No Duplicate Skills Allowed");
    } else {
      if (skill.name) {
        setSkillsData([...skillsData, skill]);
      } else {
        toast.error("Please Select a Skill");
      }
    }
    setSkill({});
  };
  useEffect(async () => {
    fetchSuccessStoryCategories();
    // get page data based on the url and change the state of setPageData here
  }, []);
  const module = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const formats = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "video"];

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      status: "draft",
    },
  });

  useEffect(() => {
    const getStories = async () => {
      const res = await axiosRequestPromise({
        method: "get",
        url: `${BASE_PATH}/stories/${id}`,
      });
      const story = res?.data?.data;
      console.log("data =>", story);
      if (story) {
        const { title, slug, short_codes, status, createdAt, description, body, image, skills, updatedAt, user, _id, category, author } = story;
        let selectedStatus = publishList.filter((el) => el.value.toLocaleLowerCase() == status.toLocaleLowerCase())[0];
        setIsProfileVerified(author?.is_verified);
        setStatusData(selectedStatus);
        setSkillsData(skills);
        setParent(category?.name);
        setCategoryId(category?._id);
        setFormData({
          ...formData,
          slug,
          status,
          imageUrl: image?.src ?? "",
          imageId: image?._id,
          authorImageId: author?.image?._id,
          authorImageUrl: author?.image?.src,
        });
        reset({
          title,
          slug,
          status,
          createdAt,
          description,
          body,
          image,
          short_codes,
          user,
          _id,
          authorName: author?.name,
          authorSkills: author?.description,
          seo_meta_title: story?.metadata?.seo_meta?.title ?? "",
          seo_meta_description: story?.metadata?.seo_meta?.description ?? "",
          seo_meta_canonical: story?.metadata?.seo_meta?.canonical ?? "",
          seo_meta_keywords: story?.metadata?.seo_meta?.keywords ?? "",
          seo_meta_robots: story?.metadata?.seo_meta?.robots ?? "",
          open_graph_title: story?.metadata?.opengraph?.title ?? "",
          open_graph_description: story?.metadata?.opengraph?.description ?? "",
          open_graph_card: story?.metadata?.opengraph?.card ?? "",
          open_graph_site_name: story?.metadata?.opengraph?.site_name ?? "",
          open_graph_image: story?.metadata?.opengraph?.image ?? "",
          open_graph_url: story?.metadata?.opengraph?.url ?? "",
          twitter_title: story?.metadata?.twitter?.title ?? "",
          twitter_description: story?.metadata?.twitter?.description ?? "",
          twitter_card: story?.metadata?.twitter?.card ?? "",
          twitter_site_name: story?.metadata?.twitter?.site_name ?? "",
          twitter_image: story?.metadata?.twitter?.image ?? "",
          twitter_url: story?.metadata?.twitter?.url ?? "",
          updatedAt,
        });
      }
      setIsLoading(false);
    };
    if (id) {
      getStories();
    } else {
      setIsLoading(false);
    }
  }, [id, searchParams]);

  const handleSlugAlreadyExist = (val) => {
    if (val.length > 2) {
      checkSlug(val);
    }
  };

  const checkSlug = async (slug) => {
    try {
      toast.loading("Checking for Slug", { id: "check" });
      let res = await axiosRequestPromise({
        method: "get",
        url: `${BASE_PATH}/stories/check-slug/${slug}`,
      });
      if (!res?.data?.data?.available) {
        if (id) {
          if (slug === formData?.slug) {
            toast.remove("check");
          } else {
            toast.error("Slug already exists.");
            toast.remove("check");
            setSlugExist(true);
          }
        } else {
          toast.error("Slug already exists ! Try another Slug");
          toast.remove("check");
          setSlugExist(true);
        }
      } else {
        toast.remove("check");
        setSlugExist(false);
      }
    } catch (err) {
      toast.error("Error while Checking Slug ! Try again");
    }
  };

  const uploadImage = async (imgData) => {
    try {
      let res = await UploadFileRequest(imgData);
      return res.data?._id;
    } catch (err) {
      toast.error("Failed: Image Size Too Large!");
      throw err;
    }
  };

  const updateData = async (data) => {
    try {
      let imgData = formData?.imageData;
      let imgDataProf = formData.authorImageData;
      if (!data.image && !imgData) {
        return toast.error("Please upload Feature Image");
      }
      if (!data?.author?.image && !imgDataProf) {
        return toast.error("Please Select User Profile Image");
      }
      if (imgData) {
        data.image = await uploadImage(imgData);
      }
      if (imgDataProf) {
        data.author.image = await uploadImage(imgDataProf);
      }
      let res = await axiosRequest("put", `${BASE_PATH}/stories/${id}`, JSON.stringify(data));
      return res.data;
    } catch (err) {
      toast.error("Something went Wrong !");
      throw err;
    }
  };

  const createData = async (data) => {
    try {
      let image = formData.imageData;
      let profileImg = formData.authorImageData;
      if (!image) {
        return toast.error("Please select Featured Image");
      }
      if (!profileImg) {
        return toast.error("please Select User Profile Image.");
      }
      let imgId = await uploadImage(image);
      let profileImgId = await uploadImage(profileImg);
      data.image = imgId;
      data.author.image = profileImgId;
      console.log("data final =>", data);
      // let res = await axiosRequest("post", `${BASE_PATH}/stories`, JSON.stringify(data));
      // return res.data;
    } catch (err) {
      toast.error("something went wrong");
      throw err;
    }
  };

  const onSubmit = async (data) => {
    let skillArr = skillsData?.map((el) => el?._id);
    let payload = {
      title: data.title,
      description: data.description,
      body: data.body,
      slug: data.slug,
      image: formData.imageId,
      category: categoryId || null,
      status: data.status,
      skills: skillArr,
      faq: [],
      author: {
        name: data.authorName,
        description: data.authorSkills,
        image: formData.authorImageId,
        is_verified: isProfileVerified,
      },
      metadata: {
        seo_meta: {
          title: data.seo_meta_title,
          description: data.seo_meta_description,
          canonical: data.seo_meta_canonical,
          keywords: data.seo_meta_keywords,
          robots: data.seo_meta_robots,
        },
        opengraph: {
          title: data.open_graph_title,
          description: data.open_graph_description,
          card: data.open_graph_card,
          site_name: data.open_graph_site_name,
          url: data.open_graph_url,
          image: data.open_graph_image,
        },
        twitter: {
          title: data.twitter_title,
          description: data.twitter_description,
          card: data.twitter_card,
          site_name: data.twitter_site_name,
          url: data.twitter_url,
          image: data.twitter_image,
        },
      },
    };
    if (slugExist) {
      toast.error("Please Change The Slug.");
      return;
    }

    if (id) {
      console.log("pri payload =>", payload);
      toast.loading("Updating Story", { id: "update" });
      try {
        let data = await updateData(payload);
        console.log("data after update =>", data);
        toast.success("Story Updated Successfully.");
        toast.remove("update");
        navigate("/success-story");
      } catch (err) {
        toast.error(err.response?.data?.error[0]?.message || "Something Went Wrong Please Try again");
        toast.remove("update");
      }
    } else {
      toast.loading("Creating New Story Page", { id: "create" });
      try {
        await createData(payload);
        toast.success("Story Page Created Successfully.");
        toast.remove("create");
        navigate("/success-story");
      } catch (err) {
        toast.error(err.response?.data?.error[0]?.message || "Something Went Wrong Please Try again");
        toast.remove("create");
      }
    }
  };

  const handleDeleteSkill = (id) => {
    setSkillsData(skillsData.filter((el) => el?._id !== id));
  };
  const changeCategory = (id, name) => {
    setParent(name);
    setCategoryId(id);
    setShowDropdown(false);
  };
  const handleDropDown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleQueryChange = (val) => {
    setQuery(val);
  };
  return (
    <Suspense fallback={""}>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={4} pb={3} mt={-3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox pt={2} display="flex" justifyContent="space-between" alignItems="center" mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                  <MDTypography color="white" variant="h6" fontWeight="medium">
                    {id ? "Update Success Story" : "Add New Success Story"}
                  </MDTypography>
                </MDBox>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <MDBox pt={2} display="flex" justifyContent="flex-start" flexDirection="column" mt={0} py={3} mx={4}>
                    <Grid my={"10px"} item xs={12} md={12} sm={12}>
                      <Title>Title*</Title>
                      {isLoading ? (
                        <Skeleton animation="wave" sx={{ height: "30px" }} variant={"rounded"} />
                      ) : (
                        <InputController
                          InputLabelProps={{ style: { color: "black" } }}
                          placeholder="Title"
                          variant="outlined"
                          handleOnChange={(title) => {
                            setValue("title", title, { shouldValidate: true });
                            let slug = title.toLowerCase()?.split(" ")?.join("-");
                            !id && setValue("slug", slug, { shouldValidate: true });
                            !id && handleSlugAlreadyExist(slug);
                          }}
                          required
                          errors={errors}
                          control={control}
                          id="input-with-icon-textfield"
                          name="title"
                          style={{ width: "100%", borderRadius: 20, marginTop: 2.5 }}
                        />
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} sm={6} lg={4}>
                        <Title>Slug*</Title>
                        {isLoading ? (
                          <Skeleton animation="wave" sx={{ height: "30px" }} variant={"rounded"} />
                        ) : (
                          <InputController
                            InputLabelProps={{ style: { color: "black" } }}
                            placeholder="Slug"
                            variant="outlined"
                            label=""
                            required
                            rules={{
                              pattern: {
                                value: SLUG_REGEX,
                                message: "Please enter a valid slug.",
                              },
                            }}
                            errors={errors}
                            control={control}
                            handleOnChange={(slug) => {
                              handleSlugAlreadyExist(slug);
                            }}
                            id="input-with-icon-textfield"
                            name="slug"
                            style={{ width: "100%", borderRadius: 20, placeholderText: "#292929" }}
                          />
                        )}
                      </Grid>
                      <Grid fontSize={14} item xs={12} md={6} sm={6} lg={4}>
                        <Title>Status*</Title>
                        {isLoading ? (
                          <Skeleton animation="wave" sx={{ height: "30px" }} variant={"rounded"} />
                        ) : (
                          <Select
                            errors={errors}
                            control={control}
                            name="status"
                            required
                            defaultValue={statusData}
                            onChange={(e) => {
                              setValue("status", e.value, { shouldValidate: false });
                              if (e.value === "None") {
                                setError("status", { type: "required", message: "This field is required" });
                              }
                            }}
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                height: "45px",
                                borderRadius: "6px",
                              }),
                            }}
                            id="status"
                            options={publishList}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Title style={{ marginTop: "10px" }}>Add skills</Title>
                    <Grid item xs={12} md={8} sm={12} fontSize={14} position="relative">
                      {isLoading ? (
                        <Skeleton animation="wave" sx={{ height: "30px" }} variant={"rounded"} />
                      ) : (
                        <Select
                          errors={errors}
                          control={control}
                          name="skills"
                          isSearchable={true}
                          isLoading={loadingSkills}
                          onKeyDown={(e) => handleQueryChange(e.target.value)}
                          onChange={(e) => {
                            setSkill(e);
                          }}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              height: "45px",
                              borderRadius: "6px",
                            }),
                          }}
                          id="skills"
                          options={skills}
                        />
                      )}
                      <MDButton onClick={() => handleAddSkills(skill)} style={{ backgroundColor: "#227BE9", position: "absolute", right: "0", top: "0" }}>
                        <MDTypography sx={{ color: "#fff", fontSize: 16 }}>ADD</MDTypography>
                      </MDButton>
                    </Grid>
                    {/* added skills */}
                    {skillsData?.length ? (
                      <MDBox display="flex" justifyContent="flex-start" flexDirection="column" my={1}>
                        <Title>Added</Title>
                        <MDBox display="flex" className="custom_scrollbar" p={"6px 2px"} sx={{ overflowX: "auto", overflowY: "hidden", scrollbars: "hidden" }}>
                          {skillsData?.map((el, id) => (
                            <Grid mr="10px" key={id} borderRadius={"100px"} height={"40px"} display="flex" alignItems={"center"} px={2} style={{ backgroundColor: "#227BE9", whiteSpace: "nowrap" }}>
                              <Typography display={"flex"} alignItems={"center"} sx={{ color: "#fff", fontSize: 14 }}>
                                {el.name}
                                <CloseIcon
                                  color="white"
                                  sx={{ fontSize: 16, fontWeight: "600", marginLeft: "10px", cursor: "pointer", marginBottom: "4px" }}
                                  onClick={() => handleDeleteSkill(el?._id)}
                                />
                              </Typography>
                            </Grid>
                          ))}
                        </MDBox>
                      </MDBox>
                    ) : (
                      ""
                    )}
                  </MDBox>
                  <MDBox pt={2} display="flex" justifyContent="flex-start" flexDirection="column" mt={-2} py={3} mx={4}>
                    <Title>Description*</Title>
                    {isLoading ? (
                      <Skeleton animation="wave" sx={{ height: "120px" }} variant={"rounded"} />
                    ) : (
                      <InputController
                        InputLabelProps={{ shrink: false }}
                        placeholder="Description"
                        multiline
                        variant="outlined"
                        label=""
                        required
                        errors={errors}
                        control={control}
                        id="outlined-multiline-static"
                        name="description"
                        rows={6}
                        style={{ width: "100%", borderRadius: 20, marginTop: 5 }}
                      />
                    )}
                  </MDBox>

                  <MDBox mx={4}>
                    <Title>Body*</Title>
                    <RichTextEditorController
                      handleChange={() => {}}
                      id={uuid()}
                      errors={errors}
                      style={{ borderRadius: "10px", height: 250 }}
                      control={control}
                      name="body"
                      required
                      modules={module}
                      formats={formats}
                    />
                  </MDBox>
                  <MDBox mt={10} mx={4}>
                    <Grid container spacing={2} style={{ alignItems: "center" }}>
                      <Grid item xs={12} md={12} sm={12}>
                        <MDBox pb="3px">
                          <Title> Featured Image* </Title>
                        </MDBox>
                        <MDBox style={{ display: "flex", alignItems: "center" }}>
                          <FileInputController
                            handleChange={(val) => {
                              setFormData({
                                ...formData,
                                imageId: null,
                                imageData: val,
                                imageUrl: URL.createObjectURL(val),
                              });
                            }}
                            style={{ cursor: "pointer" }}
                            required
                            errors={errors}
                            control={control}
                            id="image"
                            name="image"
                            accept="image/**"
                            InputLabelProps={{ color: "red" }}
                          />
                          <MDBox>
                            {formData?.imageUrl ? (
                              <img alt="" width="140px" height="100px" style={{ objectFit: "contain", backgroundColor: "#D3D3D3", marginLeft: "50px" }} src={formData?.imageUrl ?? ""} />
                            ) : (
                              <div
                                style={{
                                  marginLeft: "50px",
                                  fontSize: "14px",
                                  color: "#A9A8A8",
                                  width: "160px",
                                  height: "70px",
                                  border: "1px dashed #A9A8A8",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "10px",
                                }}
                              >
                                Preview
                              </div>
                            )}
                          </MDBox>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                  <hr style={{ color: "#A9A8A8", margin: "20px 0px" }} />
                  <MDBox mx={4}>
                    <Grid container spacing={2} style={{ alignItems: "center" }}>
                      <Grid item xs={12} md={12} sm={12}>
                        <MDBox style={{ display: "flex", alignItems: "center" }}>
                          <MDBox>
                            <Grid item xs={12} md={12} sm={12}>
                              <Title>Name</Title>
                              {isLoading ? (
                                <Skeleton animation="wave" sx={{ height: "30px" }} variant={"rounded"} />
                              ) : (
                                <InputController
                                  InputLabelProps={{ style: { color: "black" } }}
                                  placeholder="Write a name"
                                  variant="outlined"
                                  label=""
                                  required
                                  errors={errors}
                                  control={control}
                                  id="authorName"
                                  name="authorName"
                                  style={{ width: "100%", borderRadius: 20, placeholderText: "#292929" }}
                                />
                              )}
                            </Grid>
                            <Grid pb="3px" pt={"15px"} item xs={12} md={12} sm={12}>
                              <Title>Skills</Title>
                              {isLoading ? (
                                <Skeleton animation="wave" sx={{ height: "30px" }} variant={"rounded"} />
                              ) : (
                                <InputController
                                  InputLabelProps={{ style: { color: "black" } }}
                                  placeholder="Enter skills name"
                                  variant="outlined"
                                  label=""
                                  required
                                  errors={errors}
                                  control={control}
                                  id="authorSkills"
                                  name="authorSkills"
                                  style={{ width: "100%", borderRadius: 20, placeholderText: "#292929" }}
                                />
                              )}
                            </Grid>
                            <MDBox pb="3px" pt="15px">
                              <Title> User Profile* </Title>
                            </MDBox>
                            <FileInputController
                              style={{ width: "100%", cursor: "pointer" }}
                              required
                              errors={errors}
                              control={control}
                              id="authorProfile"
                              name="image"
                              handleChange={(val) => {
                                setFormData({
                                  ...formData,
                                  authorImageId: null,
                                  authorImageData: val,
                                  authorImageUrl: URL.createObjectURL(val),
                                });
                              }}
                            />
                            <MDBox fontSize={14} display="flex" alignItems="center" mt={2}>
                              <label htmlFor="profile">Varified Profile </label>
                              <Checkbox checked={isProfileVerified} onChange={(e) => setIsProfileVerified(e.target.checked)} style={{ marginLeft: "12px" }} type="checkbox" />
                            </MDBox>
                          </MDBox>

                          <MDBox>
                            {formData?.authorImageUrl ? (
                              <img alt="" width="140px" height="100px" style={{ objectFit: "contain", backgroundColor: "#D3D3D3", marginLeft: "50px" }} src={formData?.authorImageUrl ?? ""} />
                            ) : (
                              <div
                                style={{
                                  marginLeft: "50px",
                                  fontSize: "14px",
                                  color: "#A9A8A8",
                                  width: "160px",
                                  height: "70px",
                                  border: "1px dashed #A9A8A8",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "10px",
                                }}
                              >
                                Preview
                              </div>
                            )}
                          </MDBox>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                  <hr style={{ color: "#A9A8A8", margin: "20px 0px" }} />

                  <Grid item sm={12} md={8} mx={4} mb={2}>
                    <Title>Parent Category</Title>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ border: "1px solid #d5D9DC", height: "46px", borderRadius: "6px", padding: "8px", cursor: "pointer" }}
                      onClick={handleDropDown}
                    >
                      <Typography fontSize={"14px"}>{parent}</Typography>
                      <img src={dropDown} alt="dropdownIcon" />
                    </MDBox>
                    {showDropdown && (
                      <MDBox className="custom_scrollbar" sx={{ border: "1px solid #d5d9dc", fontSize: "14px", overflow: "auto", maxHeight: "300px" }}>
                        {categories?.map((el, id) => (
                          <CategoryDropdown data={el} key={id} changeCategory={changeCategory} />
                        ))}
                      </MDBox>
                    )}
                  </Grid>

                  <MDBox pb={1} display="flex" justifyContent="flex-start" flexDirection="column" mx={4}>
                    <p style={{ fontSize: 20, fontWeight: "600" }}>SEO Details</p>
                  </MDBox>
                  {seoDetails?.map((seo, idx) => {
                    return (
                      <MDBox key={idx} display="flex" justifyContent="flex-start" flexDirection="column" pb={3} mx={4}>
                        <MDBox pt={2} display="flex" justifyContent="flex-end" mt={-1}>
                          <Grid container spacing={2}>
                            {seo?.fields?.map((field, idx) => {
                              return (
                                <Grid key={idx} item xs={12} md={12} sm={12} lg={4}>
                                  <Title>{field?.title}</Title>
                                  <InputController
                                    InputLabelProps={{ style: { color: "black" } }}
                                    placeholder={field?.placeholder}
                                    variant="outlined"
                                    label={field?.label}
                                    required={field?.required}
                                    errors={errors}
                                    rules={field?.rules ?? {}}
                                    control={control}
                                    id={field?.id}
                                    name={field?.name}
                                    style={{ width: "100%", borderRadius: 20, marginTop: 2.5 }}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </MDBox>
                      </MDBox>
                    );
                  })}
                  <MDBox mt={6} mx={4}></MDBox>
                  <MDBox mx={4} mt={-3} sx={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
                    <MDBox
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#F4F4F4",
                        borderRadius: 10,
                        border: "1px solid #A9A8A8",
                        transition: "0.2s",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#eef0f8",
                        },
                      }}
                    >
                      <MDTypography onClick={() => navigate("/success-story")} px={4} py={1} sx={{ fontSize: 15, fontWeight: "700", color: "#1E0707" }}>
                        Cancel
                      </MDTypography>
                    </MDBox>

                    <Button
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#227BE9",
                        "&:hover": { backgroundColor: "#1d6fd4", color: "#ffff" },
                        "&:disabled": { backgroundColor: "#60a5fa", color: "#ffff" },
                        borderRadius: 10,
                        marginLeft: 2,
                        cursor: "pointer",
                        color: "#FFFF",
                      }}
                      type="submit"
                      px={4}
                      py={1}
                    >
                      Save Changes
                    </Button>
                  </MDBox>
                </form>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </Suspense>
  );
};

export default SuccessStoryPageEdit;
