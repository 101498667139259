import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { FormControl } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import InputAdornment from "@mui/material/InputAdornment";
// import { LoginUser } from "Apis/Apis";
import { ChangePasswordRequest } from "Apis/Apis";
function PasswordChange() {
  const [showPassword, setShowPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // async function handleLoginUser() {
  //   const data = {
  //     email: localStorage.getItem("@my_user_email"),
  //     password: currentPassword,
  //   };
  //   const response = await LoginUser(data);
  //   return response?.success;
  // }

  function handleSave() {
    if (newPassword !== confirmPassword) {
      alert(`New password and Confirm password doesn't matched`);
      return;
    }
    const data = {
      password: localStorage.getItem("@password"),
      new_password: newPassword,
    };
    ChangePasswordRequest(data)
      .then((res) => alert(res?.message))
      .catch(() => alert("Password Update failed"));
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={"10.5px"} pb={3} mt={-1}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                pt={2}
                mx={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={-3}
                py={"25px"}
                px={"24px"}
                bgColor="#227BE9"
                borderRadius="13px"
                coloredShadow="info"
                boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)"}
              >
                <MDTypography color="white" fontSize={"20px"} sx={{ fontWeight: "600" }}>
                  Password Change
                </MDTypography>
              </MDBox>

              {/* main body content */}

              <MDBox pt={4} pb={3} px={3} mt={-2}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDTypography fontSize={"13px"} lineHeight={"180%"} sx={{ fontWeight: "400", color: "grey" }}>
                      Create a brand new user and add them to this site.
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={-1}>
                    <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500", color: "#19114A" }}>
                      Current Password
                    </MDTypography>
                    <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        placeholder=""
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        sx={{ marginLeft: -1, width: 400 }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </MDBox>

                  <MDBox mt={1}>
                    <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500", color: "#19114A" }}>
                      New Password{" "}
                    </MDTypography>
                    <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        placeholder=""
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        sx={{ marginLeft: -1, width: 400 }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </MDBox>

                  <MDBox mt={1}>
                    <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500", color: "#19114A" }}>
                      Confirm Password{" "}
                    </MDTypography>
                    <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        placeholder=""
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        sx={{ marginLeft: -1, width: 400 }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </MDBox>

                  <MDBox mx={4} mt={3} sx={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
                    <MDBox
                      px={3}
                      sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F4F4F4", borderRadius: 10, border: "1px solid #A9A8A8", cursor: "pointer" }}
                    >
                      <MDTypography px={4} py={1} sx={{ fontSize: 15, fontWeight: "700", color: "#1E0707" }}>
                        Cancel
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      px={3}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#227BE9",
                        borderRadius: 10,
                        border: "1px solid #A9A8A8",
                        marginLeft: 2,
                        cursor: "pointer",
                      }}
                    >
                      <MDTypography px={4} py={1} sx={{ fontSize: 15, fontWeight: "700", color: "#FFFFFF" }} onClick={handleSave}>
                        Save
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default PasswordChange;
