import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import back from "../../../assets/images/Back.png";
import InputAdornment from "@mui/material/InputAdornment";
import { Button } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import key from "../../../assets/images/key.png";
import { UpdatePassword } from "Apis/Apis";
function NewPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isError] = useState("*Password dose not match");
  const [loading, setLoading] = useState(false);
  const params = window.location.search.split("&");
  const paramEmail = params[0].split("=")[1];
  const paramCode = params[1].split("=")[1];

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate();

  const handleSignInSubmit = () => {
    setLoading(true);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ width: 470, marginLeft: -2, borderRadius: 5 }}>
        <MDBox pt={3} pb={3} px={-1}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img src={back} style={{ width: 40, height: 40 }} />
            <p style={{ color: "#19114A", fontWeight: "400", fontSize: 15, marginTop: 7.5, marginLeft: -2 }}>Forgot Password</p>
          </div>

          <div style={{ marginLeft: 25 }}>
            <div style={{ marginTop: 25 }}>
              <p style={{ fontSize: 34, fontWeight: "500", color: "#19114A" }}>New Password</p>
            </div>

            <div style={{ marginTop: 7.5 }}>
              <p style={{ fontSize: 15, fontWeight: "400", color: "#888888", textAlign: "center", display: "flex" }}>Please enter your new password</p>
            </div>

            <div style={{ marginTop: 12.5 }}>
              <p style={{ fontSize: 15, fontWeight: "400", color: "#14114A" }}>Password</p>
              <div>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Your New Password"
                  sx={{ width: 410 }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start" style={{ marginRight: -5 }}>
                      <img src={key} />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            </div>
            <div style={{ marginTop: 12.5 }}>
              <p style={{ fontSize: 15, fontWeight: "400", color: "#14114A" }}>Confirm Password</p>
              <div>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirm Your New Password"
                  sx={{ width: 410 }}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start" style={{ marginRight: -5 }}>
                      <img src={key} />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <MDTypography sx={{ display: "flex", textAlign: "center", marginLeft: 1, fontSize: 12, fontWeight: "400", color: "red" }}>
                  {confirmPassword && password !== confirmPassword && isError}
                </MDTypography>
              </div>
              <div style={{ display: "flex", marginTop: 20, marginRight: 50, marginBottom: 20, justifyContent: "center", alignItems: "center" }}>
                <Button
                  color="primary"
                  sx={{
                    "&:hover": {
                      backgroundColor: "#227BE9",
                      boxShadow: "none",
                    },
                    "&:active": {
                      boxShadow: "none",
                      backgroundColor: "#227BE9",
                    },
                    backgroundColor: "#49A3F1",
                    padding: 1,
                    width: "45%",
                    borderRadius: 20,
                  }}
                  onClick={() => {
                    handleSignInSubmit();
                    UpdatePassword(paramEmail, paramCode, password).then((e) => {
                      if (e?.success) {
                        navigate("/authentication/sign-in");
                        setLoading(true);
                      } else {
                        alert(e?.message);
                      }
                    });
                  }}
                  disabled={confirmPassword && password !== confirmPassword}
                >
                  <MDTypography color={"white"} sx={{ display: "flex", textAlign: "center", fontSize: 14, fontWeight: "700", color: "white" }}>
                    {!loading ? "Save Changes" : <img style={{ width: "30px" }} src={require("../../../assets/images/icons/loading.gif")} alt="loading..." />}
                  </MDTypography>
                </Button>
              </div>
            </div>
          </div>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default NewPassword;
