import { TableCell, TableRow, Dialog } from "@mui/material";
import React, { useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MessagesModal from "./MessagesModal";
const ConversationsTable = ({ item }) => {
  const [msgModal, setMsgModal] = useState(null);
  const Title = ({ title, sx }) => (
    <MDBox lineHeight={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <MDTypography display="block" variant="caption" sx={{ width: "100%", fontColor: "0A0529", fontWeight: "500", fontSize: "14px", ...sx }}>
        {title}
      </MDTypography>
    </MDBox>
  );
  return (
    <>
      <TableRow
        sx={{
          "&:hover": {
            background: "#f5f5f5",
          },
        }}
      >
        <TableCell>
          <Title title={item.participants[0]?.user?.user?.first_name + " " + item.participants[0]?.user?.user?.last_name || "-"} />
        </TableCell>
        <TableCell>
          <Title title={(item.participants[1]?.user?.user?.first_name || "Deleted User") + " " + (item.participants[1]?.user?.user?.last_name || "") || ""} />
        </TableCell>
        <TableCell>
          <Title title={new Date(item.createdAt).toLocaleDateString() + " (" + new Date(item.createdAt).toLocaleTimeString() + ")"} />
        </TableCell>
        <TableCell>
          <Title title={new Date(item?.last_messaged).toLocaleDateString() + " (" + new Date(item?.last_messaged).toLocaleTimeString() + ")"} />
        </TableCell>
        <TableCell>
          <Title title={item.is_group_chat ? "Group" : "One-on-One"} />
        </TableCell>
        <TableCell>
          <Title title={item?.reported_messages ? "Yes" : "No"} />
        </TableCell>
        <TableCell onClick={() => setMsgModal(item?._id)}>
          <Title sx={{ cursor: "pointer" }} title={"View"} />
        </TableCell>
      </TableRow>
      <Dialog open={msgModal} fullWidth={true} maxWidth="sm" onClose={() => setMsgModal(null)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <MessagesModal conversation_id={msgModal} />
      </Dialog>
    </>
  );
};

export default ConversationsTable;
