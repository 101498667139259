import { Box, Button, Card, Dialog, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState } from "react";
import { Pagination } from "components/common/Pagination";
import { Add } from "@mui/icons-material";
import { useFetch } from "hooks/useFetch";
import { BASE_PATH } from "utils";
import moment from "moment";
import usePagination from "hooks/usePagination";
import { axiosRequestPromise } from "Apis";
import { Toast } from "components/common/Toast";
import "./tools.css";

const headers = [
  {
    type: "text",
    title: "S No",
  },
  {
    type: "text",
    title: "Tools Name",
  },
  {
    type: "text",
    title: "Created By",
  },
  {
    type: "text",
    title: "Last Modification",
  },
  {
    type: "text",
    title: "Action",
  },
];

const Title = ({ children, sx, onClick }) => {
  return (
    <Typography onClick={onClick && onClick} className="font-inter" sx={{ color: "#19114A", fontSize: "15px", fontWeight: "500", ...sx }}>
      {children}
    </Typography>
  );
};

const Tools = () => {
  const [search, setSearch] = useState("");
  const [addModalShown, setAddModalShown] = useState(false);
  const [toolName, setToolName] = useState("");
  const [editToolName, setEditToolName] = useState("");
  const [toast, setToast] = useState({ open: false, type: "", message: "" });
  const [editingTool, setEditingTool] = useState({
    open: false,
    tool: {},
  });
  const [deletingTool, setDeletingTool] = useState({
    open: false,
    tool: {},
  });
  const { page, limit, setPage, setLimit } = usePagination();
  const { data: res, loading, refetch } = useFetch(`${BASE_PATH}/tools?offset=${page * limit}&limit=${limit || 0}${search && `&search=${search}`}`, { refetch: null });
  const tools = res?.data?.docs;
  const loaderArray = new Array(limit).fill("loader");
  const handleAddTool = async () => {
    if (!toolName) {
      return null;
    }
    try {
      const response = await axiosRequestPromise({ method: "post", url: `${BASE_PATH}/tools`, body: { name: toolName } });
      if (response?.data?.success) {
        setToast({ message: "Tool added successfully", open: true, type: "success" });
        refetch();
        setAddModalShown(false);
      }
    } catch (error) {
      setToast({ message: "Something went wrong", open: true, type: "error" });
    }
  };

  const handleEditTool = async () => {
    if (!editToolName) {
      return null;
    }
    try {
      const response = await axiosRequestPromise({ method: "put", url: `${BASE_PATH}/tools/${editingTool?.tool?._id}`, body: { name: editToolName } });
      if (response?.data?.success) {
        setToast({ message: "Tool edited successfully", open: true, type: "success" });
        refetch();
        setEditingTool({ open: false, tool: {} });
      }
    } catch (error) {
      setToast({ message: "Something went wrong", open: true, type: "error" });
      setEditingTool({ open: false, tool: {} });
    }
  };

  const handleDeleteTool = async () => {
    try {
      const response = await axiosRequestPromise({ method: "delete", url: `${BASE_PATH}/tools/${deletingTool?.tool?._id}` });
      if (response?.data?.success) {
        setToast({ message: "Tool delete successfully", open: true, type: "success" });
        refetch();
        setDeletingTool({ open: false, tool: {} });
      }
    } catch (error) {
      setToast({ message: "Something went wrong", open: true, type: "error" });
      setEditingTool({ open: false, tool: {} });
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toast open={toast.open} type={toast.type} message={toast.message} onClose={() => setToast({ message: "", open: false, type: "" })} />
      {addModalShown && (
        <Dialog sx={{ backdropFilter: "blur(5px)" }} open={addModalShown} onClose={() => setAddModalShown(false)}>
          <MDBox mt={1} mx={3}>
            <Box sx={{ margin: "30px 0" }}>
              <Typography sx={{ fontSize: "20px", fontWeight: "500", color: "#292929", marginBottom: "15px" }}>Tool</Typography>
              <Box sx={{ width: "400px", maxWidth: "100%" }}>
                <Typography sx={{ fontSize: "15px", fontWeight: "400", color: "#292929" }}>Add Tool</Typography>
                <TextField value={toolName} onChange={(e) => setToolName(e.target.value)} placeholder="Tool name" sx={{ width: "100%", margin: "8px 0 5px 0" }} />
                <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#292929" }}>Write the tool name</Typography>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "10px", marginTop: "15px" }}>
                  <Button
                    onClick={() => setAddModalShown(false)}
                    variant="contained"
                    sx={{
                      background: "#F4F4F4",
                      color: "#1E0707",
                      padding: "10px 65px",
                      border: "1px solid #A9A8A8",
                      borderRadius: "100px",
                      "&:hover": { background: "#F4F4F4" },
                      "&:focus": { background: "#F4F4F4" },
                      "&:focus:not(:hover)": { background: "#F4F4F4" },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleAddTool} variant="contained" sx={{ color: "#fff", padding: "10px 65px", borderRadius: "100px" }}>
                    Save
                  </Button>
                </div>
              </Box>
            </Box>
          </MDBox>
        </Dialog>
      )}
      {editingTool.open && (
        <Dialog sx={{ backdropFilter: "blur(5px)" }} open={editingTool.open} onClose={() => setEditingTool({ open: false, tool: {} })}>
          <MDBox mt={1} mx={3}>
            <Box sx={{ margin: "30px 0" }}>
              <Typography sx={{ fontSize: "20px", fontWeight: "500", color: "#292929", marginBottom: "15px" }}>Tool</Typography>
              <Box sx={{ width: "400px", maxWidth: "100%" }}>
                <Typography sx={{ fontSize: "15px", fontWeight: "400", color: "#292929" }}>Edit Tool</Typography>
                <TextField value={editToolName} onChange={(e) => setEditToolName(e.target.value)} placeholder="Tool name" sx={{ width: "100%", margin: "8px 0 5px 0" }} />
                <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#292929" }}>Write the new tool name</Typography>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "10px", marginTop: "15px" }}>
                  <Button
                    onClick={() => {
                      setEditingTool({ open: false, tool: {} });
                      setEditingTool("");
                    }}
                    variant="contained"
                    sx={{
                      background: "#F4F4F4",
                      color: "#1E0707",
                      padding: "10px 65px",
                      border: "1px solid #A9A8A8",
                      borderRadius: "100px",
                      "&:hover": { background: "#F4F4F4" },
                      "&:focus": { background: "#F4F4F4" },
                      "&:focus:not(:hover)": { background: "#F4F4F4" },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleEditTool} variant="contained" sx={{ color: "#fff", padding: "10px 65px", borderRadius: "100px" }}>
                    Save
                  </Button>
                </div>
              </Box>
            </Box>
          </MDBox>
        </Dialog>
      )}
      {deletingTool.open && (
        <Dialog sx={{ backdropFilter: "blur(5px)" }} open={deletingTool.open} onClose={() => setDeletingTool({ open: false, tool: {} })}>
          <Box sx={{ width: "450px", padding: "20px" }}>
            <Typography sx={{ fontSize: "24px", fontWeight: "700" }}>Delete {deletingTool?.tool?.name}</Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "500", marginBottom: "10px" }}>
              Are you sure to delete {deletingTool?.tool?.name}?<br /> It can&apos;t be undone
            </Typography>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "10px", marginTop: "15px" }}>
              <Button
                onClick={() => {
                  setDeletingTool({ open: false, tool: {} });
                }}
                variant="contained"
                sx={{
                  background: "#F4F4F4",
                  color: "#1E0707",
                  padding: "10px 45px",
                  border: "1px solid #A9A8A8",
                  borderRadius: "100px",
                  "&:hover": { background: "#F4F4F4" },
                  "&:focus": { background: "#F4F4F4" },
                  "&:focus:not(:hover)": { background: "#F4F4F4" },
                }}
              >
                Cancel
              </Button>
              <Button onClick={handleDeleteTool} variant="contained" sx={{ color: "#fff", padding: "10px 45px", borderRadius: "100px" }}>
                Delete
              </Button>
            </div>
          </Box>
        </Dialog>
      )}
      <MDBox pt={"3px"} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={2} display="flex" justifyContent="space-between" alignItems="center" mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography color="white" sx={{ fontSize: "20px", fontWeight: "600" }} fontWeight="medium">
                  Tools {res?.data?.totalDocs ? `(${res?.data?.totalDocs})` : ""}
                </MDTypography>
                <Button
                  onClick={() => setAddModalShown(true)}
                  variant="contained"
                  sx={{ background: "#060606", color: "#fff", "&:hover": { background: "#060606" }, "&:focus": { background: "#060606" }, "&:focus:not(:hover)": { background: "#060606" } }}
                >
                  <Add /> ADD NEW
                </Button>
              </MDBox>
              {/* <MDBox mt={1} mx={3}>
                <Box sx={{ margin: "30px 0" }}>
                  <Typography sx={{ fontSize: "15px", fontWeight: "500", color: "#19114A", width: "400px", marginBottom: "5px" }}>Tool Name*</Typography>
                  <Box sx={{ width: "400px", maxWidth: "100%" }}>
                    <TextField placeholder="Tool name" sx={{ width: "100%" }} />
                    <Button variant="contained" sx={{ display: "block", color: "#fff", marginLeft: "auto", marginTop: "15px", padding: "10px 65px", borderRadius: "100px" }}>
                      Add
                    </Button>
                  </Box>
                </Box>
              </MDBox> */}
              <Box>
                <Box>
                  <Box mx={3} my={"25px"} display="flex" alignItems="center" sx={{ position: "relative" }}>
                    <TextField
                      size="small"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search Tools"
                      inputProps={{
                        style: {
                          fontSize: "14px",
                          paddingLeft: "10px",
                          paddingRight: "20px",
                          borderRadius: "10px",
                        },
                      }}
                    />
                    <Box
                      sx={{
                        background: "#292929",
                        borderRadius: "8px",
                        width: "47px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "-10px",
                        zIndex: "9",
                      }}
                    >
                      <img src="images/search-correct.png" />
                    </Box>
                  </Box>
                  <div style={{ width: "100%", height: "1px", background: "#D3CECE" }}></div>
                  <Box sx={{ maxWidth: "100%" }}>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow>
                            {headers.map((h, i) => (
                              <TableCell key={i}>
                                <Typography sx={{ color: "#19114A", fontSize: "16px", fontFamily: "Inter", fontWeight: "600" }}>{h.title}</Typography>
                              </TableCell>
                            ))}
                          </TableRow>
                          {!loading
                            ? tools?.map((td, i) => {
                                return (
                                  <TableRow
                                    sx={{
                                      "&:hover": {
                                        background: "#f5f5f5",
                                      },
                                    }}
                                    key={i}
                                  >
                                    <TableCell>
                                      <Title>{i + 1}</Title>
                                    </TableCell>
                                    <TableCell>
                                      <Title>{td.name}</Title>
                                    </TableCell>
                                    <TableCell>
                                      <div className="email-tooltip-container">
                                        <Title sx={{ fontWeight: "400" }}>{td?.created_by?.role}</Title>
                                        <div className="email-tooltip">
                                          <Typography sx={{ fontSize: "13px", fontWeight: "500" }}>{td?.created_by?.email}</Typography>
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <Title sx={{ fontWeight: "400", fontSize: "14px" }}>{moment(td?.createdAt).format("MMMM DD, YYYY")}</Title>
                                      <Title sx={{ fontWeight: "400", fontSize: "12px" }}>{moment(td?.createdAt).format("h:mm a")}</Title>
                                    </TableCell>
                                    <TableCell>
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <Title
                                          onClick={() => {
                                            setEditingTool({ tool: td, open: true });
                                            setEditToolName(td?.name);
                                          }}
                                          sx={{ cursor: "pointer" }}
                                        >
                                          Edit
                                        </Title>{" "}
                                        <span style={{ fontWeight: "500" }}>/</span>{" "}
                                        <Title onClick={() => setDeletingTool({ open: true, tool: td })} sx={{ cursor: "pointer" }}>
                                          Delete
                                        </Title>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            : loaderArray.map((l, i) => {
                                return (
                                  <TableRow key={i}>
                                    {/* currently no image skeleton, but will automatically work if the key is image */}
                                    {headers.map((h, i) => {
                                      const variant = h.key === "image" ? "circular" : "rounded";
                                      const styles = h.key === "image" ? { width: "30px", height: "30px" } : { width: "100%", height: "20px" };
                                      return (
                                        <TableCell key={i}>
                                          <Skeleton animation="wave" sx={styles} variant={variant} />
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                        </TableBody>
                      </Table>
                      <Pagination
                        itemOffset={page * limit}
                        itemsPerPage={limit}
                        handlePageClick={({ selected }) => setPage(selected)}
                        pageCount={res?.data?.totalPages || 0}
                        currentPage={page + 1}
                        totalCount={res?.data?.totalDocs || 1000}
                        setItemsPerPage={setLimit}
                      />
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Tools;
