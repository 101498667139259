import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Box, Button, CircularProgress, Select } from "@mui/material";
import { MenuItem, FormControl } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "hooks/useFetch";
import { BASE_PATH } from "utils";
import getFullName from "utils/getFullName";
import { axiosRequestPromise } from "Apis";
import { Toast } from "components/common/Toast";

function DisableUser() {
  const { userId } = useParams();
  const { data: userRes, loading: userLoading } = useFetch(`${BASE_PATH}/users/${userId}`);
  const user = userRes?.data;
  const { data: usersRes, loading: usersLoading } = useFetch(`${BASE_PATH}/users`);
  const users = usersRes?.data?.docs;
  const [selected, setSelected] = useState("");
  const [toastData, setToastData] = useState({ type: "", message: "", open: false });
  const navigate = useNavigate();
  const handleFormSubmit = async (e) => {
    e?.preventDefault();
    try {
      if (!selected) {
        setToastData({ type: "error", open: true, message: "you must select an admin to attribute" });
        return null;
      }
      const res = await axiosRequestPromise({ url: `${BASE_PATH}/users/${userId}/toggle-disable-enable`, body: { value: "disable", assigned_to: selected }, method: "post" });
      if (res?.data?.success) {
        setToastData({ open: true, message: `User disabled successfully`, type: "success" });
        setTimeout(() => {
          navigate("/settings");
        }, 2000);
      }
    } catch (error) {
      setToastData({ open: true, message: error?.response?.data?.message, type: "success" });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toast open={toastData.open} message={toastData.message} onClose={() => setToastData({ message: "", open: false, type: "" })} type={toastData.type} />
      <MDBox pt={"10.5px"} pb={3} mt={-1}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                pt={2}
                mx={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={-3}
                py={"25px"}
                px={"24px"}
                bgColor="#227BE9"
                borderRadius="13px"
                coloredShadow="info"
                boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)"}
              >
                <MDTypography color="white" fontSize={"20px"} sx={{ fontWeight: "600" }}>
                  Disable user
                </MDTypography>
              </MDBox>

              {/* main body content */}

              <MDBox pt={4} pb={3} px={3} mt={-2}>
                {userLoading || usersLoading ? (
                  <Box sx={{ height: "200px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <MDBox component="form" role="form" onSubmit={handleFormSubmit}>
                    <MDBox mb={2}>
                      <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500", color: "#19114A" }}>
                        ID #{user?._id}: {getFullName(user?.first_name, user?.last_name)}
                      </MDTypography>
                    </MDBox>

                    <MDBox mb={2}>
                      <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500", color: "#19114A" }}>
                        What should be done with content owned by this user ?
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={2} mt={-1} sx={{ display: "flex", flexDirection: "row" }}>
                      <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "400", color: "#19114A" }}>
                        Attribute all content to :
                      </MDTypography>

                      <FormControl style={{ marginLeft: 10 }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          shrink="false"
                          value={selected}
                          displayEmpty={true}
                          renderValue={(selectedOption) => {
                            const _user = users.find((u) => u?._id === selectedOption);
                            return selectedOption?.length ? `${getFullName(_user?.first_name, _user?.last_name)} - ${_user?.role}` : "Select Role";
                          }}
                          style={{ minWidth: 150, height: 30 }}
                          onChange={(e) => {
                            setSelected(e.target.value);
                          }}
                        >
                          {users?.map((u, idx) => {
                            return (
                              <MenuItem key={idx} sx={{ minWidth: 200, gap: "10px" }} value={u?._id}>
                                <input style={{ width: "16px", height: "16px" }} type="checkbox" checked={u?._id === selected} readOnly />
                                <MDTypography sx={{ fontSize: 12, fontWeight: "400" }}>
                                  {getFullName(u?.first_name, u?.last_name)} - {u?.role}
                                </MDTypography>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </MDBox>

                    <div mt={5}>
                      <Button variant="contained" type={"submit"} sx={{ marginTop: 3, borderRadius: 20 }}>
                        <MDTypography sx={{ color: "#fff", fontSize: 15, fontWeight: "bold" }}>Confirm Deletion</MDTypography>
                      </Button>
                      <div style={{ marginLeft: -10, marginTop: -5 }}></div>
                    </div>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default DisableUser;
