import MDBox from "components/MDBox";
import React, { useState } from "react";

const InsertLinkModal = ({ closeModal, data, handleChangeInCell }) => {
  const [cellData, setCellData] = useState({ row: data.row, col: data.col, link: data.data.link, text: data.data.text });
  const handleAddLink = () => {
    handleChangeInCell(cellData.row, cellData.col, cellData.text, cellData.link);
    closeModal();
  };
  const getAlphabet = (value) => {
    const str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return str[value];
  };
  return (
    <MDBox
      sx={{
        position: "fixed",
        zIndex: "1000",
        top: "0",
        left: "0",
        width: "100%",
        height: "100vh",
        backgroundColor: "#00000062",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MDBox sx={{ background: "white", padding: "20px", position: "relative", borderRadius: "6px", width: "700px" }}>
        <p style={{ fontSize: "18px" }}>
          Insert Link into cell {getAlphabet(cellData.col)}
          {cellData.row + 1}
        </p>
        <p style={{ fontSize: "15px", marginTop: "18px" }}>Enter the destination URL</p>
        <div style={{ fontSize: "15px", marginTop: "13px", textAlign: "right" }}>
          URL{" "}
          <input
            type="url"
            style={{ width: "580px", height: "40px", borderRadius: "6px", border: "1px solid #A9A8A8", outline: "none", padding: "0px 4px", fontSize: "15px" }}
            value={cellData.link}
            onChange={(e) => {
              setCellData({ ...cellData, link: e.target.value });
            }}
          />
        </div>
        <div style={{ fontSize: "15px", marginTop: "13px", textAlign: "right" }}>
          Link Text{" "}
          <input
            type="text"
            style={{ width: "580px", height: "40px", borderRadius: "6px", border: "1px solid #A9A8A8", outline: "none", padding: "0px 4px", fontSize: "15px" }}
            value={cellData.text}
            onChange={(e) => setCellData({ ...cellData, text: e.target.value })}
          />
        </div>
        <div style={{ marginTop: "13px", textAlign: "right" }}>
          <button
            onClick={() => closeModal()}
            style={{ height: "40px", border: "1px solid #A9A8A8", borderRadius: "4px", width: "120px", background: "none", margin: "5px 20px", fontSize: "15px", cursor: "pointer" }}
          >
            Cancel
          </button>
          <button
            onClick={handleAddLink}
            style={{ height: "40px", border: "none", borderRadius: "4px", width: "120px", background: "#227BE9", margin: "5px 20px", color: "white", fontSize: "15px", cursor: "pointer" }}
          >
            Add Link
          </button>
        </div>
      </MDBox>
    </MDBox>
  );
};

export default InsertLinkModal;
