/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";

function DataTableHeadCell({ width, children, sorted, align, type, ...rest }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <>
      {type == "post" ? (
        <MDBox
          component="th"
          width={width}
          py={1.5}
          sx={({ palette: { secondary }, borders: { borderWidth } }) => ({
            borderBottom: `${borderWidth[1]} solid ${secondary.main}`,
            borderTop: `${borderWidth[1]} solid ${secondary.main} `,
          })}
        >
          <MDBox
            {...rest}
            position="relative"
            textAlign={"left"}
            color={darkMode ? "white" : "#19114A"}
            fontFamily="Inter"
            fontStyle="normal"
            fontSize="14px"
            sx={{
              textTransform: "uppercase",
              cursor: sorted && "pointer",
              userSelect: sorted && "none",
              fontWeight: 600,
              lineHeight: "17px",
              marginLeft: 2,
            }}
          >
            {children}
            {sorted && (
              <MDBox
                position="absolute"
                top={0}
                // right={align !== "right" ? "1px" : 0}
                // left={align === "right" ? "-5px" : "unset"}
                sx={({ typography: { size } }) => ({
                  fontSize: size.lg,
                })}
              >
                <MDBox position="absolute" top={-6} color={sorted === "asce" ? "text" : "secondary"} opacity={sorted === "asce" ? 1 : 0.5}>
                  <Icon>arrow_drop_up</Icon>
                </MDBox>
                <MDBox position="absolute" top={0} color={sorted === "desc" ? "text" : "secondary"} opacity={sorted === "desc" ? 1 : 0.5}>
                  <Icon>arrow_drop_down</Icon>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      ) : type == "project" ? (
        <MDBox component="th" width={width} py={1.5}>
          <MDBox
            {...rest}
            position="relative"
            textAlign={"left"}
            color={darkMode ? "white" : "#19114A"}
            fontFamily="Inter"
            fontStyle="normal"
            fontSize="14px"
            sx={{
              textTransform: "uppercase",
              cursor: sorted && "pointer",
              userSelect: sorted && "none",
              fontWeight: 600,
              lineHeight: "17px",
              marginLeft: 3,
            }}
          >
            {children}
            {sorted && (
              <MDBox
                position="absolute"
                top={0}
                // right={align !== "right" ? "16px" : 0}
                // left={align === "right" ? "-5px" : "unset"}
                sx={({ typography: { size } }) => ({
                  fontSize: size.lg,
                })}
              >
                <MDBox position="absolute" top={-6} color={sorted === "asce" ? "text" : "secondary"} opacity={sorted === "asce" ? 1 : 0.5}>
                  <Icon>arrow_drop_up</Icon>
                </MDBox>
                <MDBox position="absolute" top={0} color={sorted === "desc" ? "text" : "secondary"} opacity={sorted === "desc" ? 1 : 0.5}>
                  <Icon>arrow_drop_down</Icon>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      ) : type == "skill" ? (
        <MDBox component="th" width={width} py={1.5}>
          <MDBox
            {...rest}
            position="relative"
            textAlign={"left"}
            color={darkMode ? "white" : "#19114A"}
            fontFamily="Inter"
            fontStyle="normal"
            fontSize="14px"
            sx={{
              textTransform: "uppercase",
              cursor: sorted && "pointer",
              userSelect: sorted && "none",
              fontWeight: 600,
              lineHeight: "17px",
              marginLeft: 3,
            }}
          >
            {children}
            {sorted && (
              <MDBox
                position="absolute"
                top={0}
                // right={align !== "right" ? "1px" : 0}
                // left={align === "right" ? "-5px" : "unset"}
                sx={({ typography: { size } }) => ({
                  fontSize: size.lg,
                })}
              >
                <MDBox position="absolute" top={-6} color={sorted === "asce" ? "text" : "secondary"} opacity={sorted === "asce" ? 1 : 0.5}>
                  <Icon>arrow_drop_up</Icon>
                </MDBox>
                <MDBox position="absolute" top={0} color={sorted === "desc" ? "text" : "secondary"} opacity={sorted === "desc" ? 1 : 0.5}>
                  <Icon>arrow_drop_down</Icon>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      ) : type == "user" ? (
        <MDBox component="th" width={width} py={1.5}>
          <MDBox
            {...rest}
            position="relative"
            textAlign={align}
            color={darkMode ? "white" : "#19114A"}
            fontFamily="Inter"
            fontStyle="normal"
            fontSize="14px"
            sx={{
              textTransform: "uppercase",
              cursor: sorted && "pointer",
              userSelect: sorted && "none",
              fontWeight: 600,
              lineHeight: "17px",
              marginLeft: 3,
            }}
          >
            {children}
          </MDBox>
        </MDBox>
      ) : type == "finance" ? (
        <MDBox
          component="th"
          width={width}
          py={1.5}
          sx={({ palette: { secondary }, borders: { borderWidth } }) => ({
            borderBottom: `${borderWidth[1]} solid ${secondary.main}`,

            borderRadius: 0,
          })}
        >
          <MDBox
            {...rest}
            position="relative"
            textAlign={"left"}
            color={darkMode ? "white" : "#19114A"}
            fontFamily="Inter"
            fontStyle="normal"
            fontSize="14px"
            sx={{
              textTransform: "uppercase",
              cursor: sorted && "pointer",
              userSelect: sorted && "none",
              fontWeight: 600,
              lineHeight: "17px",
              marginLeft: 2,
            }}
          >
            {children}
            {sorted && (
              <MDBox
                position="absolute"
                top={0}
                // right={align !== "right" ? "1px" : 0}
                // left={align === "right" ? "-5px" : "unset"}
                sx={({ typography: { size } }) => ({
                  fontSize: size.lg,
                })}
              >
                <MDBox position="absolute" top={-6} color={sorted === "asce" ? "text" : "secondary"} opacity={sorted === "asce" ? 1 : 0.5}>
                  <Icon>arrow_drop_up</Icon>
                </MDBox>
                <MDBox position="absolute" top={0} color={sorted === "desc" ? "text" : "secondary"} opacity={sorted === "desc" ? 1 : 0.5}>
                  <Icon>arrow_drop_down</Icon>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      ) : (
        <MDBox component="th" width={width} py={1.5}>
          <MDBox
            {...rest}
            position="relative"
            textAlign={align}
            color={darkMode ? "white" : "#19114A"}
            fontFamily="Inter"
            fontStyle="normal"
            fontSize="14px"
            sx={{
              textTransform: "uppercase",
              cursor: sorted && "pointer",
              userSelect: sorted && "none",
              fontWeight: 600,
              lineHeight: "17px",
              marginLeft: 3,
            }}
          >
            {children}
            {sorted && (
              <MDBox
                position="absolute"
                top={0}
                // right={align !== "right" ? "1px" : 0}
                // left={align === "right" ? "-5px" : "unset"}
                sx={({ typography: { size } }) => ({
                  fontSize: size.lg,
                })}
              >
                <MDBox position="absolute" top={-6} color={sorted === "asce" ? "text" : "secondary"} opacity={sorted === "asce" ? 1 : 0.5}>
                  <Icon>arrow_drop_up</Icon>
                </MDBox>
                <MDBox position="absolute" top={0} color={sorted === "desc" ? "text" : "secondary"} opacity={sorted === "desc" ? 1 : 0.5}>
                  <Icon>arrow_drop_down</Icon>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      )}
    </>
  );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left"]),
};

export default DataTableHeadCell;
