import React, { useState } from "react";
import { Table, TableContainer, TableBody, TableRow, TableCell, Paper, Skeleton, Typography, Dialog, Button } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Pagination } from "components/common/Pagination";
import { headers } from "layouts/notifications/data";
import moment from "moment";
import { stripHtml } from "utils/common";

const TableWithPagination = ({ data, page, limit, setPage, setLimit, loading, type, search }) => {
  const [showMore, setShowMore] = useState(false);
  const [showedData, setShowedData] = useState({ description: "" });
  const notifications = data?.data?.docs || [];

  const Title = ({ title, sx }) => (
    <MDBox lineHeight={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <MDTypography display="block" variant="caption" sx={{ width: "100%", fontColor: "0A0529", fontWeight: "500", fontSize: "14px", ...sx }}>
        {title}
      </MDTypography>
    </MDBox>
  );
  const loaderArray = new Array(10).fill("a");

  const getNotifications = () => {
    let _notifications = notifications;
    if (type) {
      if (type !== "all") {
        const filtered = _notifications.filter((n) => n?.notification_to === type);
        _notifications = filtered;
      }
    }
    if (search) {
      const searched = _notifications.filter((n) => n?.content?.toLowerCase()?.includes(search?.toLowerCase()));
      _notifications = searched;
    }
    return _notifications;
  };

  const ContentMessage = ({ text }) => {
    return (
      <Title
        sx={{ color: "#49A3F1", fontWeight: "700", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
        title={
          <>
            <span>
              {text.length > 60 ? (
                <>
                  {text.slice(0, 60)}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowMore(true);
                      setShowedData({ description: text });
                    }}
                  >
                    ...see more
                  </a>
                </>
              ) : (
                text
              )}
            </span>
          </>
        }
      />
    );
  };
  const getDetailsByCategory = (type) => {
    if (type === "all") {
      return { color: "#80E0FF", text: "A", label: "All" };
    } else if (type === "freelancer") {
      return { color: "#FFDB80", text: "F", label: "Freelancer" };
    } else if (type === "client") {
      return { color: "#D68583", text: "C", label: "Client" };
    } else {
      return { color: "#80E0FF", text: "A", label: "All" };
    }
  };

  return (
    <>
      <Dialog
        open={showMore}
        onClose={() => {
          setShowMore(false);
          setShowedData({ description: "" });
        }}
      >
        <div style={{ padding: "30px" }}>
          <Typography sx={{ fontSize: "15px", fontWeight: "500", marginBottom: "10px" }}>{showedData.description}</Typography>
          <Button
            variant="contained"
            sx={{ color: "#fff", marginLeft: "auto", display: "block", borderRadius: "100px", padding: "10px 50px" }}
            onClick={() => {
              setShowMore(false);
              setShowedData({ description: "" });
            }}
          >
            Close
          </Button>
        </div>
      </Dialog>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              {headers.map((h, i) => (
                <TableCell key={i}>
                  <b>{h.title}</b>
                </TableCell>
              ))}
            </TableRow>
            {!loading
              ? getNotifications()?.map((notification, index) => (
                  <TableRow
                    sx={{
                      "&:hover": {
                        background: "#f5f5f5",
                      },
                    }}
                    key={index}
                  >
                    <TableCell>
                      <div
                        style={{
                          width: "46px",
                          height: "46px",
                          background: getDetailsByCategory(notification?.notification_to)?.color,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          color: "#007AA1",
                        }}
                      >
                        <Typography>{getDetailsByCategory(notification?.notification_to)?.text}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <ContentMessage text={notification?.title || "-"} />
                    </TableCell>
                    <TableCell>
                      <ContentMessage text={stripHtml(notification?.content)} />
                    </TableCell>
                    <TableCell>
                      <Title title={notification?.notification_to || "-"} />
                    </TableCell>
                    <TableCell>
                      <Title title={moment(notification?.createdAt).format(" D MMMM YYYY, h:mm A")} />
                    </TableCell>
                  </TableRow>
                ))
              : loaderArray.map((l, i) => {
                  return (
                    <TableRow key={i}>
                      {headers.map((h, i) => {
                        const variant = h.type === "image" ? "circular" : "rounded";
                        const styles = h.type === "image" ? { width: "30px", height: "30px" } : { width: "100%", height: "20px" };
                        return (
                          <TableCell key={i}>
                            <Skeleton animation="wave" sx={styles} variant={variant} />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
        <Pagination
          itemOffset={page * limit}
          handlePageClick={({ selected }) => setPage(selected)}
          itemsPerPage={limit}
          pageCount={data?.data?.totalPages}
          currentPage={data?.data?.page}
          totalCount={data?.data?.totalDocs}
          setItemsPerPage={setLimit}
        />
      </TableContainer>
    </>
  );
};

export default TableWithPagination;
