import { Box, Typography, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import moment from "moment";
import { Send } from "@mui/icons-material";
import { axiosRequestPromise } from "Apis";
import { BASE_PATH } from "utils";
import DOMPurify from "dompurify";
import { GrAttachment } from "react-icons/gr";
import { IoIosAlert } from "react-icons/io";
import { FiLoader } from "react-icons/fi";
import FileIcon from "components/common/FileIcon";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-hot-toast";
import { MdClose } from "react-icons/md";

const Chat = ({ data, loading, ticketId, refetch }) => {
  const [messageBody, setMessageBody] = useState("");
  const modules = {
    toolbar: [
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const chat = data?.data;
  const messages = chat?.messages;

  const [files, setFiles] = useState([]);

  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axiosRequestPromise({ method: "post", url: `${BASE_PATH}/files`, body: formData });
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;

    if (files) {
      if (files.length > 5) {
        alert("You cannot send more than 5 files");
        setFiles([]);
        return;
      }

      const filesArray = Array.from(files);
      const filesArrayPreview = [];

      for (let i = 0; i < filesArray.length; i++) {
        filesArrayPreview.push({
          id: uuidv4(),
          file: filesArray[i],
          status: "loading",
        });
      }

      setFiles(filesArrayPreview);

      try {
        const uploadPromises = filesArrayPreview.map(async (image, index) => {
          setFiles((prevStatus) => {
            prevStatus[index].id = index.toString();
            prevStatus[index].status = "loading";
            return [...prevStatus];
          });

          try {
            const response = await uploadFile(image.file);
            setFiles((prevStatus) => {
              prevStatus[index].id = response.data._id;
              prevStatus[index].status = "success";
              return [...prevStatus];
            });
          } catch (e) {
            const error = e;
            toast.error(error.response?.data.message);

            setFiles((prevStatus) => {
              prevStatus[index].id = index.toString();
              prevStatus[index].status = "error";
              return [...prevStatus];
            });
          }
        });

        await Promise.all(uploadPromises);
      } catch (error) {
        toast.error("Something went wrong while uploading files");
      }
    }
  };

  const chatRef = useRef();
  useEffect(() => {
    chatRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, []);

  const getMessagePosition = (message) => {
    const currentAdminId = localStorage.getItem("@my_user_id");
    const senderId = message?.sender?._id;
    return senderId === currentAdminId ? "right" : "left";
  };

  const handleSendMessage = async () => {
    const res = await axiosRequestPromise({ method: "put", url: `${BASE_PATH}/tickets/${ticketId}/message`, body: { text: messageBody || undefined, attachments: files.map((file) => file.id) } });
    if (res?.data?.success) {
      setMessageBody("");
      refetch();
      setFiles([]);
    } else {
      console.log("message sending failed");
    }
  };

  const isImage = (mimetype) => {
    return mimetype.includes("image");
  };

  return (
    <div className="chat">
      {loading ? (
        <Box sx={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "Center" }}>
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          <Box sx={{ padding: "0 10px", display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", borderBottom: "1px solid #8d8888", height: "72px" }}>
            <Typography className="font-inter" sx={{ fontSize: "15px", color: "#054089", fontWeight: "500" }}>
              {chat?.subject}
            </Typography>
            <Typography className="font-inter" sx={{ fontSize: "13px", color: "#000", fontWeight: "400" }}>
              Ticket ID: {chat?._id}
            </Typography>
          </Box>
          <Box sx={{ height: "calc(100% - 280px)", overflowY: "auto" }}>
            <Box ref={chatRef} style={{ width: "100%", padding: "10px" }}>
              {messages?.map((message, i) => {
                const sanitized = DOMPurify.sanitize(message.text);
                return (
                  <>
                    {message.attachments?.length > 0 && (
                      <>
                        {message?.attachments?.map((attachment) => (
                          <a target="_blank" component={"a"} href={attachment.src} className={`chat-attachment chat-${getMessagePosition(message)} chat-box-container`} key={i}>
                            {isImage(attachment?.mimetype) && (
                              <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={attachment?.src} alt="attachment" className="attachment" key={attachment?.key} />
                            )}
                            {!isImage(attachment?.mimetype) && (
                              <div className="attachment-file">
                                <Typography sx={{ fontSize: "10px", fontWeight: "500", color: "#054089" }}>{attachment?.key}</Typography>
                              </div>
                            )}
                          </a>
                        ))}
                      </>
                    )}
                    {message.text && (
                      <Box key={i} className={`chat-${getMessagePosition(message)} chat-box-container`}>
                        <div className={`chat-box`} dangerouslySetInnerHTML={{ __html: sanitized }}></div>
                        <Typography className="chat-box-time">{moment(message.createdAt).format("ddd, DD MMM [at] h:mm A")}</Typography>
                      </Box>
                    )}
                  </>
                );
              })}
            </Box>
          </Box>
          {files.length > 0 && (
            <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "10px", padding: "10px", background: "#f5f5f5" }}>
              {files.map((attachment) => (
                <div className="file-preview">
                  {attachment.status === "success" && (
                    <button
                      type="button"
                      onClick={() => {
                        setFiles((prev) => {
                          const updatedFiles = prev.filter((item) => item.id !== attachment.id);
                          return [...updatedFiles];
                        });
                      }}
                      className="attachment-delete-btn"
                    >
                      <MdClose />
                    </button>
                  )}
                  {isImage(attachment.file.type) && (
                    <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={URL.createObjectURL(attachment?.file)} alt="attachment" key={attachment?.key} />
                  )}
                  {!isImage(attachment.file.type) && <FileIcon mimetype={attachment.file.type} />}
                  {attachment.status === "error" && (
                    <div className="error-overlay">
                      <IoIosAlert />
                    </div>
                  )}
                  {attachment.status === "loading" && (
                    <div className="loading-overlay">
                      <FiLoader className="loading-overlay-loader" />
                    </div>
                  )}
                </div>
              ))}
            </Box>
          )}
          <ReactQuill
            readOnly={chat?.status === "closed"}
            value={messageBody}
            onChange={(c) => setMessageBody(c)}
            placeholder="Type here..."
            modules={modules}
            style={{ overflow: "hidden", height: "150px" }}
          />
          <Box sx={{ height: "55px", display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "10px", borderTop: "1px solid #8d8888" }}>
            <label
              htmlFor="attachment-selector"
              disabled={chat?.status === "closed"}
              variant="text"
              style={{
                width: "40px",
                height: "40px",
                marginRight: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "all .3s ease-in-out",
                padding: "0",
                background: "#f5f5f5",
                borderRadius: "50%",
              }}
            >
              <GrAttachment color="#000" />
            </label>
            <input multiple style={{ display: "none" }} onChange={handleFileChange} type="file" id="attachment-selector" />
            <Button disabled={chat?.status === "closed"} onClick={handleSendMessage} variant="contained" sx={{ color: "#fff", paddingLeft: "50px", paddingRight: "50px", borderRadius: "100px" }}>
              <Send />
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};

export default Chat;
