import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export default function Basic() {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem("@storage_Key");
    navigate("/authentication/sign-in");
  }, []);
  return <></>;
}
