import { Input, TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

export default function FileInputController ({ control, name, handleChange, errors, InputLabelProps, value, required, id, style, variant, label }) {
  return (
    <div>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required,
        }}
        render={({ field: { onChange, ref } }) => (
          <TextField
            type="file"
            error={!!errors[name]}
            onChange={(e) => {
              onChange(e.target.files[0]);
              handleChange && handleChange(e.target.files[0]);
            }}
            ref={ref}
            InputLabelProps={InputLabelProps}
            variant={variant}
            style={style}
            id={id}
            label={label}
            helperText={errors[name]?.type === "required" ? "This field is required." : ""}
          />
        )}
      />
    </div>
  );
};
