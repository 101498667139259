import axios from "axios";
import { useEffect, useState } from "react";

export const useFetch = (url, { refetch: _refetch = null } = {}, options = {}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const token = localStorage.getItem("@storage_Key");
  const headers = {
    Accept: "*/*",
    Authorization: `Bearer${" "}${token}`,
    "Content-Type": "application/json",
  };

  const _options = {
    headers: headers,
    ...options,
  };

  // should be refetch when option changes

  const handleGetData = () => {
    setLoading(true);
    const getData = () => {
      axios
        .get(url, _options)
        .then((response) => {
          setData(response?.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getData();
  };

  const refetch = () => {
    handleGetData();
  };

  useEffect(() => {
    handleGetData();
  }, [url, _refetch]);

  return { data, loading, error, refetch };
};
