import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import back from "../../../assets/images/Back.png";
import authmain from "../../../assets/images/authmain.png";
import emailfield from "../../../assets/images/emailfield.png";
import InputAdornment from "@mui/material/InputAdornment";
import { Button } from "@mui/material";
import { ForgotPasswordApi } from "Apis/Apis";

function ForgotPassword() {
  const [email, setemail] = useState("");
  const navigate = useNavigate();

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ width: 470, marginLeft: -2, borderRadius: 5 }}>
        <MDBox pt={3} pb={3} px={2}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/authentication/sign-in");
              }}
            >
              <img src={back} style={{ width: 40, height: 40 }} />
            </div>

            <p style={{ color: "#19114A", fontWeight: "400", fontSize: 15, marginTop: 7.5, marginLeft: -2 }}>Forgot Password</p>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={authmain} />
          </div>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 2.5 }}>
            <p style={{ fontSize: 34, fontWeight: "500", color: "#19114A" }}>Forgot Password?</p>
          </div>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 5 }}>
            <p style={{ fontSize: 15, fontWeight: "400", color: "#888888", textAlign: "center", display: "flex", paddingLeft: 10, paddingRight: 10 }}>
              Please type your email to receive a confirmation code to set a new password
            </p>
          </div>

          <div style={{ marginTop: 12.5, marginLeft: 55 }}>
            <p style={{ fontSize: 15, fontWeight: "400", color: "#14114A" }}>Email Address</p>
            <div style={{ marginLeft: -9 }}>
              <TextField
                placeholder="Enter your email address"
                InputLabelProps={{
                  style: { color: "black" },
                }}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                variant="outlined"
                style={{ marginTop: 3, borderRadius: 12.5, width: 342 }}
                id="input-with-icon-textfield"
                label=""
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" style={{ marginRight: -5 }}>
                      <img src={emailfield} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: 20, marginRight: 50, marginBottom: 20 }}>
              <Button
                color="primary"
                sx={{
                  "&:hover": {
                    backgroundColor: "#227BE9",
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                    backgroundColor: "#227BE9",
                  },
                  backgroundColor: "#49A3F1",
                  padding: 1,
                  width: "60%",
                  borderRadius: 20,
                }}
                onClick={() => {
                  ForgotPasswordApi(email).then((e) => {
                    e.success && navigate(`/authentication/reset-code?email=${e.data.email}`);
                  });
                }}
              >
                <MDTypography color={"white"} sx={{ display: "flex", textAlign: "center", fontSize: 14, fontWeight: "700", color: "white" }}>
                  Confirm Email
                </MDTypography>
              </Button>
              <Button
                color="primary"
                sx={{
                  "&:hover": {
                    backgroundColor: "#227BE9",
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                    backgroundColor: "#227BE9",
                  },
                  backgroundColor: "#49A3F1",
                  marginLeft: 2,
                  width: "60%",
                  borderRadius: 20,
                  padding: 1,
                }}
              >
                <MDTypography color={"white"} sx={{ display: "flex", textAlign: "center", fontSize: 14, fontWeight: "700", color: "white" }}>
                  Resend Email
                </MDTypography>
              </Button>
            </div>
          </div>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ForgotPassword;
