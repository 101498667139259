import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const usePagination = (limitOptions = [10, 25, 50], { searchParams = undefined, setSearchParams = undefined } = {}) => {
  const _search = useSearchParams();
  const _searchParams = searchParams || _search[0];
  const _setSearchParams = setSearchParams || _search[1];
  const page = +_searchParams.get("page");
  const limit = +_searchParams.get("limit");
  const setSearchQueries = (key, value) => {
    _searchParams.set(key, value);
    _setSearchParams(_searchParams);
  };
  const setPage = (page) => {
    setSearchQueries("page", page);
  };
  const setLimit = (limit) => {
    setSearchQueries("limit", limit);
  };
  useEffect(() => {
    if (!page) {
      setSearchQueries("page", 0);
    }
    if (!limitOptions.includes(limit)) {
      setSearchQueries("limit", limitOptions[0]);
    }
  }, [page, limit]);

  return { page, limit, setPage, setLimit, offset: page * limit };
};

export default usePagination;
