import React, { useState } from "react";
import { TablePagination, Grid, Typography, Box, Modal, Skeleton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-hot-toast";
import CreateBlogCategoryDialogue from "../CreateBlogCategoryDialogue";
import { Edit } from "@mui/icons-material";
import { axiosRequestPromise } from "Apis";
import { BASE_PATH } from "utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TableWithPagination = ({ blogCategoryList, searchParams, page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, rowsPerPageOptions, responseData, loading, refetch }) => {
  const q = searchParams.get("q");
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [blogId, setBlogId] = useState("");

  const [editingCategory, setEditingCategory] = useState(null);

  const handleModal = () => setOpen(!open);

  const openModal = (id) => {
    setOpen(true);
    setBlogId(id);
  };

  const getBlogCategoriesForUI = () => {
    if (q) {
      const newCatList = blogCategoryList.slice();
      const data = newCatList?.filter((blog) => {
        const name = blog?.name?.toLowerCase();
        const searchQuery = q.toLowerCase();
        return name?.includes(searchQuery);
      });
      return data;
    } else {
      return blogCategoryList;
    }
  };
  const loaderArray = new Array(rowsPerPage).fill(1);

  const handleEdit = async (id) => {
    try {
      const res = await axiosRequestPromise({ url: `${BASE_PATH}/blogs/categories/${id}`, method: "get" });
      setEditingCategory(res?.data?.data);
      setEditOpen(true);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <MDBox mt={2} mx={3}>
      <Modal open={open} onClose={handleModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "center" }}>
            Are you sure you want to delete category ?
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }}>
            <MDButton
              type="submit"
              variant="gradient"
              color="error"
              onClick={() => {
                toast.promise(axiosRequestPromise({ method: "delete", url: `${BASE_PATH}/blogs/categories/${blogId}` }), {
                  error: () => {
                    setOpen(false);
                    return "Failed to delete category";
                  },
                  loading: "Deleting category",
                  success: () => {
                    setOpen(false);
                    refetch && refetch();
                    return "Category deleted successfully";
                  },
                });
              }}
              sx={{ mr: 2 }}
            >
              Delete
            </MDButton>
            <MDButton type="submit" color="secondary" sx={{ mr: 2 }} onClick={() => setOpen(false)}>
              Cancel
            </MDButton>
          </Typography>
        </Box>
      </Modal>

      {editOpen ? (
        <CreateBlogCategoryDialogue
          editingCategory={editingCategory}
          handleClose={() => {
            setEditOpen(false);
            setEditingCategory(null);
          }}
          state="edit"
          open={editOpen}
          refetch={refetch}
        />
      ) : null}
      <Grid container spacing={1}>
        {!loading
          ? getBlogCategoriesForUI()?.map((data, index) => {
              return (
                <Grid key={index} item xs={6} md={6} sm={12}>
                  <div
                    style={{ padding: "25px", marginBottom: "30px", border: "1px solid #C6C6C6", borderRadius: 8, display: "flex", alignItems: "center", justifyContent: "space-between", rowGap: 2 }}
                  >
                    {/* <div style={{ fontSize: "18px", color: "#3490DC" }}>{item?.title}</div> */}
                    <MDBox mt={0}>
                      <Typography variant="caption" sx={{ fontWeight: 500, fontSize: "22px" }}>
                        {data?.title}
                      </Typography>
                      <MDBox mt={1} mr={2}>
                        <MDTypography sx={{ fontSize: 14, fontWeight: "400", marginTop: 0.25, marginBottom: 0.25 }}>Slug: {data?.slug}</MDTypography>
                      </MDBox>
                      <MDBox mt={1} mr={2}>
                        <MDTypography className="two-line-ellipsis" sx={{ fontSize: 12, fontWeight: "400", marginTop: 0.25, marginBottom: 0.25 }}>
                          <span style={{ fontWeight: "bold" }}>Description:</span> {data?.description}
                        </MDTypography>
                      </MDBox>
                      <MDBox mt={1} mr={2}>
                        <MDTypography sx={{ fontSize: 18, fontWeight: "400", marginTop: 0.25, marginBottom: 0.25 }}>
                          Status: <span style={{ color: "#00FF00" }}>{data?.status}</span>
                        </MDTypography>
                      </MDBox>
                      {data?.parent_categories?.length > 0 && (
                        <MDBox mt={1} mr={2}>
                          <MDTypography sx={{ fontSize: 14, fontWeight: "400", marginTop: 0.25, marginBottom: 0.25 }}>
                            Parent Categories: {data?.parent_categories?.map((pc) => pc.title).join(", ")}
                          </MDTypography>
                        </MDBox>
                      )}
                    </MDBox>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mt: 2, rowGap: 2 }}>
                      {/* <MDButton startIcon={<EditIcon />} variant="gradient" color="info" sx={{ paddingLeft: 4, paddingRight: 4 }} component={Button} onClick={() => setopenedit(data)}>
                      Edit
                    </MDButton> */}
                      <MDButton
                        sx={{ paddingLeft: 4, paddingRight: 4 }}
                        variant="gradient"
                        startIcon={<Edit />}
                        color="info"
                        onClick={() => {
                          handleEdit(data._id);
                        }}
                      >
                        Edit
                      </MDButton>
                      <MDButton variant="gradient" startIcon={<DeleteIcon />} color="error" onClick={() => openModal(data._id)}>
                        delete
                      </MDButton>
                    </Box>
                  </div>
                </Grid>
              );
            })
          : loaderArray.map((a, i) => (
              <Grid key={i} item xs={6} md={6} sm={12}>
                <Skeleton animation="wave" variant="rectangular" sx={{ width: "100%", height: "200px", marginBottom: "20px", borderRadius: "5px" }} />
              </Grid>
            ))}
      </Grid>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={responseData.totalDocs}
        rowsPerPage={+rowsPerPage}
        page={+page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </MDBox>
  );
};

export default TableWithPagination;
