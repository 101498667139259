import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ListTableRow from "./ListTableRow";
import { useFetch } from "hooks/useFetch";
import { BASE_PATH } from "utils";
import moment from "moment";
import DummySkleton from "./successCategory/DummySkleton";
import axios from "axios";
import { toast } from "react-hot-toast";

const columns = [
  {
    id: "sno",
    label: "S No.",
    minWidth: 70,
    format: (value) => value.toLocaleString("en-US"),
  },
  { id: "Image", label: "IMAGE", minWidth: 100 },
  { id: "Name", label: "Name", minWidth: 170 },
  {
    id: "Status",
    label: "Status",
    minWidth: 170,
  },
  {
    id: "Slug",
    label: "Slug",
    minWidth: 170,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Last_Modified",
    label: "Last Modified",
    minWidth: 170,
    format: (value) => value.toFixed(2),
  },
];
export default function ListTable({ query, selectedRange, timeDuration, category, status, getData, display, applyRange }) {
  const [tableData, setTableData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [itemOffset, setItemOffset] = React.useState(0);
  const { data: stories, loading: isLoading } = useFetch(
    `${BASE_PATH}/stories?limit=${rowsPerPage}&offset=${itemOffset}&display=${display}${status && `&status=${status}`}${query && `&search=${query}`}${category && `&category=${category}`}${
      timeDuration && `&start_date=${new Date(timeDuration).toISOString()}`
    }${applyRange ? `&start_date=${new Date(selectedRange[0].startDate).toISOString()}&end_date=${new Date(selectedRange[0].endDate).toISOString()}` : ""}`
  );
  React.useEffect(() => {
    if (stories) {
      getData(stories.data);
      let data = stories?.data?.docs.map((el, id) => ({
        ...el,
        sno: id + 1,
        Image: el.image?.src,
        Name: el.title,
        Status: el.status,
        Slug: el.slug,
        Last_Modified: moment(el.updatedAt).fromNow(),
      }));
      setTableData(data);
    }
  }, [stories]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const newOffset = (event.selected * rowsPerPage) % stories?.data?.totalDocs;
    setItemOffset(newOffset);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleTrash = async (row) => {
    try {
      toast.loading("Deleting Story...", { id: "delete" });
      const token = localStorage.getItem("@storage_Key");
      const headers = {
        Accept: "*/*",
        Authorization: `Bearer${" "}${token}`,
      };
      const options = {
        headers: headers,
      };
      const res = await axios.delete(`${BASE_PATH}/stories/${row._id}`, options);
      toast.success(res.data?.data);
      let filtredData = tableData.filter((el) => el._id !== row._id);
      setTableData(filtredData);
      toast.remove("delete");
    } catch (err) {
      toast.error("Error While Deleting Story");
      toast.remove("delete");
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 600 }} className="custom_scrollbar">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, id) => (
                <TableCell key={id} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <DummySkleton />
            ) : (
              tableData?.map((row, id) => {
                return <ListTableRow key={id} data={row} handleTrash={handleTrash} />;
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        fontWeight={"400"}
        fontSize="15px"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
