import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { toast } from "react-hot-toast";
import api from "../../api";
import MDButton from "components/MDButton";
import { useFetch } from "hooks/useFetch";
import { BASE_PATH } from "utils";
import { axiosRequestPromise } from "Apis";
import { useNavigate } from "react-router-dom";

function PlatformFee() {
  const [value, setValue] = useState(0);
  const [confirm, setConfirm] = useState("");

  const navigate = useNavigate();

  const { data } = useFetch(`${BASE_PATH}/service-charge`);

  const serviceCharge = data?.data?.service_charge || 0;

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log({ value, confirm });
      if (value <= 0) {
        return toast.error("Charge can't be 0 or less");
      }
      if (confirm !== "confirm") {
        return toast.error("Type confirm to proceed");
      }

      await axiosRequestPromise({ url: `${BASE_PATH}/service-charge`, method: "post", body: { service_charge: value } });

      toast.success("Service charge updated successfully");

      navigate("/dashboard");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={"10.5px"} pb={3} mt={-1}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                pt={2}
                mx={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={-3}
                py={"25px"}
                px={"24px"}
                bgColor="#227BE9"
                borderRadius="13px"
                coloredShadow="info"
                boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)"}
              >
                <MDTypography color="white" fontSize={"20px"} sx={{ fontWeight: "600" }}>
                  Platform Fee: {serviceCharge}%
                </MDTypography>
              </MDBox>

              {/* main body content */}

              <MDBox pt={4} pb={3} px={3} mt={-2}>
                <form onSubmit={handleFormSubmit}>
                  <MDBox mt={1}>
                    <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500", color: "#19114A" }}>
                      Platform Percentage Amount*{" "}
                    </MDTypography>
                    <TextField value={value} onChange={(e) => setValue(+e.target.value)} type="number" name="" id="" className="platformCharge" style={{ width: "50%", marginTop: 1 }} />
                  </MDBox>
                  <MDBox mt={1}>
                    <MDTypography value={confirm} onChange={(e) => setConfirm(e.target.value)} fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500", color: "#19114A" }}>
                      Type "confirm"*{" "}
                    </MDTypography>
                    <TextField value={confirm} onChange={(e) => setConfirm(e.target.value)} placeholder="confirm" name="" id="" className="confirm" style={{ width: "50%", marginTop: 1 }} />
                  </MDBox>

                  <div mt={10}>
                    <MDButton sx={{ backgroundColor: "#227BE9", "&:hover": { backgroundColor: "#227BE9" }, marginTop: 3, borderRadius: 20, width: "50%" }} type="submit">
                      <MDTypography sx={{ color: "#fff", fontSize: 15, fontWeight: "bold" }}>Submit</MDTypography>
                    </MDButton>
                  </div>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default PlatformFee;
