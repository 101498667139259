import { CircularProgress } from "@mui/material";
import React from "react";

export const Loader = () => {
  return (
    <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginTop: "5%", marginBottom: "5%" }}>
      <CircularProgress style={{ color: "#227be9" }} />
    </div>
  );
};
