import { FormHelperText } from "@mui/material";
import { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

// import { registerSmartBreak } from "./registerSmallBreak";

// const Parchment = Quill.import("parchment");

// registerSmartBreak();

// import { ReactSelectController } from "components/ui/form/react-select-controller";

// function lineBreakMatcher() {
//   var Delta = Quill.import("delta");
//   var newDelta = new Delta();
//   newDelta.insert({ break: "" });
//   return newDelta;
// }

// function handleLinebreak(range, context) {
//   const { quill } = this;
//   const currentLeaf = quill.getLeaf(range.index)[0];
//   const nextLeaf = quill.getLeaf(range.index + 1)[0];
//   quill.insertEmbed(range.index, "break", true, Quill.sources.USER);

//   if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
//     quill.insertEmbed(range.index, "break", true, Quill.sources.USER);
//   }
//   quill.setSelection(range.index + 1, Quill.sources.SILENT);

//   Object.keys(context.format).forEach((name) => {
//     if (Parchment.query(name, Parchment.Scope.BLOCK)) return;
//     if (Array.isArray(context.format[name])) return;
//     if (name === "link") return;
//     quill.format(name, context.format[name], Quill.sources.USER);
//   });
// }

// function handleEnter(range, context) {
//   const { quill } = this;

//   if (range.length > 0) {
//     quill.scroll.deleteAt(range.index, range.length);
//   }
//   const lineFormats = Object.keys(context.format).reduce((acc, format) => {
//     if (Parchment.query(format, Parchment.Scope.BLOCK) && !Array.isArray(context.format[format])) {
//       acc[format] = context.format[format];
//     }
//     return acc;
//   }, {});

//   const previousChar = quill.getText(range.index - 1, 1);

//   quill.insertText(range.index, "\n", lineFormats, Quill.sources.USER);

//   if (previousChar == "" || (previousChar == "\n" && !(context.offset > 0 && context.prefix.length === 0))) {
//     quill.setSelection(range.index + 2, Quill.sources.SILENT);
//   } else {
//     quill.setSelection(range.index + 1, Quill.sources.SILENT);
//   }
//   Object.keys(context.format).forEach((name) => {
//     if (lineFormats[name] != null) return;
//     if (Array.isArray(context.format[name])) return;
//     if (name === "link") return;
//     quill.format(name, context.format[name], Quill.sources.USER);
//   });
// }

export default function RichTextEditorController({ control, handleChange, formats, id, modules, name, errors, style, required }) {
  // const quillRef = useRef(null);

  // const removeExtraneousLines = () => {
  //   const quill = quillRef.current.getEditor();
  //   const length = quill.getLength();
  //   const text = quill.getText(length - 2, 2);

  //   if (text === "\n\n") {
  //     quill.deleteText(quill.getLength() - 2, 2);
  //   }
  // };

  // const formatEmptyValue = (v) => v.replace(/^<p><br><\/p>$/gi, "");

  // useEffect(() => {
  //   removeExtraneousLines();
  // }, []);
  return (
    <div id={id} style={{ borderRadius: "20px" }}>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required,
        }}
        render={({ field: { onChange, value, ref } }) => (
          <ReactQuill
            ref={ref}
            className="editor"
            theme="snow"
            formats={formats}
            modules={modules}
            onChange={(v) => {
              // const text = formatEmptyValue(v);
              onChange(v);
              handleChange(v);
            }}
            value={value ?? ""}
            style={style}
          />
        )}
      />
      {!!errors[name] && (
        <FormHelperText htmlFor={id} error={!!errors[name]}>
          {errors[name]?.type === "required" ? "This field is required." : ""}
        </FormHelperText>
      )}
    </div>
  );
}
