import { URL_REGEX } from "utils/common";
import { v4 as uuid } from "uuid";
export const seoDetails = [
  {
    title: "seo_meta",
    fields: [
      {
        title: "SEO Meta*",
        name: "seo_meta_title",
        placeholder: "Title",
        required: true,
        label: "",
        id: uuid(),
      },
      {
        title: "Description*",
        name: "seo_meta_description",
        placeholder: "Description",
        required: true,
        label: "",
        id: uuid(),
      },
      {
        title: "Canonical",
        name: "seo_meta_canonical",
        placeholder: "Canonical",
        required: false,
        label: "",
        id: uuid(),
        rules: {
          pattern: {
            value: URL_REGEX,
            message: "Please enter a valid url.",
          },
        },
      },
      {
        title: "Keywords*",
        name: "seo_meta_keywords",
        placeholder: "Keywords",
        required: true,
        label: "",
        id: uuid(),
      },
      {
        title: "Robots*",
        name: "seo_meta_robots",
        placeholder: "Robots",
        required: true,
        label: "",
        id: uuid(),
      },
    ],
  },
  {
    title: "open_graph",
    fields: [
      {
        title: "Open Graph*",
        name: "open_graph_card",
        placeholder: "Card",
        required: true,
        label: "",
        id: uuid(),
      },
      {
        title: "Title*",
        name: "open_graph_title",
        placeholder: "Title",
        required: true,
        label: "",
        id: uuid(),
      },
      {
        title: "Description*",
        name: "open_graph_description",
        placeholder: "Description",
        required: true,
        label: "",
        id: uuid(),
      },

      {
        title: "Site Name*",
        name: "open_graph_site_name",
        placeholder: "Site Name",
        required: true,
        label: "",
        id: uuid(),
      },
      {
        title: "URL",
        name: "open_graph_url",
        placeholder: "URL",
        required: false,
        label: "",
        id: uuid(),
        rules: {
          pattern: {
            value: URL_REGEX,
            message: "Please enter a valid url.",
          },
        },
      },
      {
        title: "Image",
        name: "open_graph_image",
        placeholder: "Image",
        required: false,
        label: "",
        id: uuid(),
        rules: {
          pattern: {
            value: URL_REGEX,
            message: "Please enter a valid url.",
          },
        },
      },
    ],
  },
  {
    title: "twitter",
    fields: [
      {
        title: "Twitter*",
        name: "twitter_card",
        placeholder: "Card",
        required: true,
        label: "",
        id: uuid(),
      },
      {
        title: "Title*",
        name: "twitter_title",
        placeholder: "Title",
        required: true,
        label: "",
        id: uuid(),
      },
      {
        title: "Description*",
        name: "twitter_description",
        placeholder: "Description",
        required: true,
        label: "",
        id: uuid(),
      },

      {
        title: "Site Name*",
        name: "twitter_site_name",
        placeholder: "Site Name",
        required: false,
        label: "",
        id: uuid(),
      },
      {
        title: "URL",
        name: "twitter_url",
        placeholder: "URL",
        required: false,
        label: "",
        id: uuid(),
        rules: {
          pattern: {
            value: URL_REGEX,
            message: "Please enter a valid url.",
          },
        },
      },
      {
        title: "Image",
        name: "twitter_image",
        placeholder: "Image",
        required: false,
        label: "",
        id: uuid(),
        rules: {
          pattern: {
            value: URL_REGEX,
            message: "Please enter a valid url.",
          },
        },
      },
    ],
  },
];

export const editorFormat = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "video"];

export const categoryPageDefaultValues = {
  name: "",
  slug: "",
  description: "",
  short_description: "",
  body: "",
  parent: "None",
  status: "draft",
  seo_meta_title: "",
  seo_meta_description: "",
  seo_meta_canonical: "",
  seo_meta_keywords: "",
  seo_meta_robots: "",
  open_graph_title: "",
  open_graph_card: "",
  open_graph_site_name: "",
  open_graph_description: "",
  open_graph_image: "",
  open_graph_image_alt: "",
  open_graph_url: "",
  twitter_title: "",
  twitter_description: "",
  twitter_card: "",
  twitter_site_name: "",
  twitter_image: "",
  twitter_image_alt: "",
  twitter_url: "",
};
