import { Grid, Card, Box, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { Suspense, useEffect, useState } from "react";
import ListTable from "./ListTable";
import searchImg from "../../assets/images/search.png";
import { filters } from "layouts/Post/ListPost/data";
import { useFetch } from "hooks/useFetch";
import { BASE_PATH } from "utils";
import dropDown from "assets/images/downarrow.png";
import CategoryDropdown from "./successCategory/CategoryDropdown";
import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./index.css";

const SuccessStory = () => {
  const [query, steQuery] = useState("");
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [timeDuration, setTimeDuration] = useState("");
  const [status, setStatus] = useState("");
  const [display, setDisplay] = useState("all");
  const [categoryData, setcategoryData] = useState([]);
  const [category, setCategory] = useState("");
  const [parent, setParent] = useState("All Categories");
  const [showDropdown, setShowDropdown] = useState(false);
  const [docs, setDocs] = useState(0);
  const [applyRange, setApplyRange] = useState(false);
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(false);

  const { data: categories } = useFetch(`${BASE_PATH}/stories/categories/dropdown`, { refetch: "" });
  const AllDatesPreset = [
    {
      id: 1,
      name: "Last 7 days",
      value: moment().subtract(7, "days").toDate(),
    },
    {
      id: 2,
      name: "Today",
      value: new Date(),
    },
    {
      id: 3,
      name: "Yesterday",
      value: moment().subtract(1, "days").toDate(),
    },
    {
      id: 4,
      name: "Last 28 days",
      value: moment().subtract(28, "days").toDate(),
    },
    {
      id: 5,
      name: "Last 90 days",
      value: moment().subtract(90, "days").toDate(),
    },
    {
      id: 6,
      name: "Last 180 days",
      value: moment().subtract(180, "days").toDate(),
    },
    {
      id: 7,
      name: "Last calender year",
      value: "lastYear",
    },
    {
      id: 8,
      name: "Custom Change",
      value: "custom",
    },
  ];
  useEffect(() => {
    setcategoryData(categories?.data);
  }, [categories]);
  const handleQuery = (e) => {
    steQuery(e.target.value);
  };
  const handleTableDataType = (val) => {
    if (val.title === "All" || val.title === "Mine") {
      setStatus("");
      setDisplay(val.title.toLowerCase());
    } else {
      setStatus(val.title.toLowerCase());
    }
  };
  const handleDropDown = () => {
    setShowDropdown(!showDropdown);
  };
  const changeCategory = (id, name) => {
    setCategory(id);
    setParent(name);
    setShowDropdown(false);
  };
  const getData = (data) => {
    setDocs(data.totalDocs);
  };
  const handleTimeDuration = (e) => {
    const value = e.target.value;
    if (value === "custom" || value === "lastYear") {
      setIsDateRangeVisible(!isDateRangeVisible);
    } else if (value === "all") {
      setApplyRange(false);
      setTimeDuration(null);
    } else {
      setTimeDuration(value);
      setApplyRange(false);
    }
  };
  const handleOnChangeRange = (range) => {
    setSelectedRange([range?.selection]);
  };
  const handleDateRangeClose = () => {
    setIsDateRangeVisible(false);
    setSelectedRange([
      {
        startDate: new Date(),
        endDate: null,
        key: "selection",
      },
    ]);
  };
  return (
    <Suspense fallback={""}>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={"10.5px"} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox pt={2} display="flex" justifyContent="space-between" alignItems="center" mx={2} mt={-4} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                  <MDTypography color="white" sx={{ fontSize: "20px", fontWeight: "600" }}>
                    Success Story Pages <span>&#40;{docs}&#41;</span>
                  </MDTypography>
                </MDBox>
                {/* Table Top Section  */}
                <hr color="#d5D9DC" style={{ margin: "20px 0px" }} />
                <MDBox px={3} display="flex" justifyContent="space-between">
                  <MDBox style={{ width: "50%" }}>
                    <MDBox fontSize="14px" fontWeight="500" display="flex">
                      {filters?.map((el, id) => (
                        <Box key={id} mx={"5px"} style={{ cursor: "pointer" }} onClick={() => handleTableDataType(el)}>
                          {el.title} {id === filters.length - 1 ? " " : " | "}
                        </Box>
                      ))}
                    </MDBox>
                    <MDBox display="flex" my="10px">
                      <MDBox style={{ position: "relative" }}>
                        <select
                          onChange={handleTimeDuration}
                          style={{ width: "136px", height: "30px", padding: "2px 4px", fontSize: "13px", borderRadius: "3px", color: "#565454", marginRight: "20px", border: "1px solid #565454" }}
                          value={timeDuration}
                        >
                          <option className="cus_date_dropdown" value={"all"}>
                            All Dates
                          </option>
                          {AllDatesPreset?.map((el, id) => {
                            return (
                              <option className="cus_date_dropdown" key={id} value={el?.value}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                        {isDateRangeVisible && (
                          <div style={{ position: "absolute", top: "38px", border: "1px solid gray", borderRadius: "5px", overflow: "hidden", zIndex: "100" }}>
                            <DateRange editableDateInputs={true} onChange={(item) => handleOnChangeRange(item)} moveRangeOnFirstSelection={false} ranges={selectedRange} />
                            <div style={{ display: "flex", justifyContent: "end", background: "white", borderTop: "1px solid gray", padding: "8px" }}>
                              <button className="cus-date-dropdown-btn" type="button" onClick={handleDateRangeClose}>
                                Cancel
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setApplyRange(true);
                                  setIsDateRangeVisible(false);
                                }}
                                className="cus-date-dropdown-btn"
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        )}
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-start" flexDirection="column" style={{ width: "200px", fontSize: "14px" }}>
                        <MDBox
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{ border: "1px solid #d5D9DC", height: "36px", borderRadius: "6px", padding: "8px", cursor: "pointer" }}
                          onClick={handleDropDown}
                        >
                          <Typography fontSize={"14px"}>{parent}</Typography>
                          <img src={dropDown} alt="dropdownIcon" />
                        </MDBox>
                        {showDropdown && (
                          <MDBox className="custom_scrollbar" sx={{ border: "1px solid #d5d9dc", overflow: "auto", maxHeight: "300px" }}>
                            <p className="dropdown_category" onClick={() => changeCategory("", "None")}>
                              None
                            </p>
                            {categoryData?.map((el) => (
                              <CategoryDropdown data={el} key={el._id} changeCategory={changeCategory} />
                            ))}
                          </MDBox>
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  {/* search functionality  */}
                  <MDBox
                    width="350px"
                    style={{ position: "relative", height: "50px", display: "flex", border: "1px solid #A9A8A8", borderRadius: "100px", justifyContent: "center", alignItems: "center" }}
                  >
                    <img style={{ border: "px solid red", height: "24px", width: "24px" }} src={searchImg} alt="search icon" />
                    <input
                      style={{ height: "100%", width: "80%", padding: "2px 4px", border: "none", outline: "none", fontSize: "16px", margin: "auto 8px" }}
                      type="text"
                      placeholder="Search"
                      onChange={handleQuery}
                    />
                  </MDBox>
                </MDBox>
                {/* Table view of all users  */}
                <ListTable query={query} selectedRange={selectedRange} timeDuration={timeDuration} status={status} category={category} getData={getData} display={display} applyRange={applyRange} />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </Suspense>
  );
};

export default SuccessStory;
