import { Navigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { UserContext } from "context/user";
import React from "react";
function PrivateRoute({ children }) {
  const { valueC } = useContext(UserContext);
  useEffect(() => {
    localStorage.getItem("@storage_Key");
  }, []);
  // const user = true
  return valueC ? children : <Navigate to="/authentication/sign-in" />;
}

export default PrivateRoute;
