import React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Typography, TablePagination } from "@mui/material";
import { getAllImages } from "Apis/Apis";
import "./uploadedImages.css";
import moment from "moment";
import { ContentCopyOutlined } from "@mui/icons-material";
import { toast } from "react-hot-toast";
const UploadedImages = () => {
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getAllImages()
      .then((res) => {
        res && setImages(res);
      })
      .catch(() => alert("Error occured"));
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={"3px"} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={2} display="flex" justifyContent="space-between" alignItems="center" mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography color="white" variant="h6" fontWeight="medium">
                  Upload Images
                </MDTypography>
              </MDBox>
              <MDBox mt={1} mx={3}>
                <MDTypography sx={{ fontSize: 20, fontWeight: "400", marginBottom: "20px" }}>You can view all previously uploaded images here.</MDTypography>
                <MDTypography sx={{ fontSize: 20, fontWeight: "400" }}>It includes one thumbnail per photo - the smallest image is selected.</MDTypography>
              </MDBox>
              <MDBox mt={2} mx={3}>
                {images?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data, index) => {
                  return (
                    <div
                      key={index}
                      style={{ padding: "25px", marginBottom: "30px", border: "1px solid #C6C6C6", borderRadius: 8, display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}
                    >
                      {/* <div style={{ fontSize: "18px", color: "#3490DC" }}>{item?.title}</div> */}
                      <div>
                        <MDBox mt={0}>
                          <Typography variant="caption" sx={{ fontWeight: 500, fontSize: "20px" }}>
                            Image ID : {data?._id} : {data?.title}
                          </Typography>
                        </MDBox>
                        <MDBox mt={0}>
                          <MDTypography sx={{ fontWeight: "400", fontSize: 15, color: "#292929" }}>Uploaded {moment(data?.updatedAt).fromNow()}</MDTypography>
                        </MDBox>

                        <MDBox mt={2}>
                          <MDTypography sx={{ fontWeight: 400, fontSize: 15 }}>
                            <strong>Image Dimensions</strong> - {data?.width} x {data?.height} :
                          </MDTypography>
                        </MDBox>

                        <MDBox mt={1}>
                          <MDTypography sx={{ fontWeight: 400, fontSize: 18 }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <div className="copy-tooltip" style={{ marginRight: "8px" }}>
                                [
                                <a href={data?.image?.src} style={{ color: "#227BE9", fontSize: 18, fontWeight: "500" }}>
                                  Link
                                </a>{" "}
                                ]
                                <div
                                  onClick={() => {
                                    navigator.clipboard.writeText(data?.image.src);
                                    toast.success("Copied To clipboard");
                                  }}
                                  className="copy-tooltip-element"
                                >
                                  <ContentCopyOutlined />
                                  <p>Copy</p>
                                </div>
                              </div>{" "}
                              /{" "}
                              <MDBox
                                mx={0.5}
                                sx={{ backgroundColor: "#227BE9", borderRadius: 1, padding: 0.205, width: 70, display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 1 }}
                              >
                                <MDTypography fontSize={"15px"} fontWeight={"500"} color={"white"}>
                                  Show
                                </MDTypography>
                              </MDBox>
                            </div>
                          </MDTypography>
                        </MDBox>
                      </div>

                      <img src={data?.image?.src} alt="blog" height={225} width={250} style={{ borderRadius: 10, textAlign: "center" }} />
                    </div>
                  );
                })}
              </MDBox>
              <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }} justifyContent="flex-end" alignItems={{ xs: "flex-start", sm: "center", marginRight: 30 }} p={3} sx={{ marginTop: -5 }}>
                <TablePagination component="div" count={images.length} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default UploadedImages;
