import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CreateNewTableRequest } from "Apis/Apis";
import { Title } from "layouts/Post/AddPost/elements";

function AddTable() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState({
    name: "",
    description: "",
    data: [],
    short_code: "",
  });
  const [table, setTable] = useState({ cols: 0, rows: 0 });
  const handleAddTable = async () => {
    if (!tableData.name) {
      toast.error("Table name required");
    } else if (!table.rows || !table.cols) {
      toast.error("enter valid rows / columns");
    } else {
      tableData.data = createTableData(table.rows, table.cols);
      tableData.short_code = generateShortCode();
      const res = await CreateNewTableRequest(tableData);
      if (!res.success) {
        toast.error(res.message);
      } else {
        toast.success("Table Created Successfully");
        navigate(`/add-table/${res.data?._id}`);
      }
    }
  };
  const createTableData = (row, col) => {
    let newData = [];
    for (let i = 0; i < row; i++) {
      let arr = [];
      for (let j = 0; j < col; j++) {
        arr.push({});
      }
      newData.push(arr);
    }
    return newData;
  };
  const generateShortCode = () => {
    const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const milliseconds = new Date().getTime().toString();
    let code = "";

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * (alphabets?.length + milliseconds?.length));
      if (randomIndex < alphabets?.length) {
        code += alphabets[randomIndex];
      } else {
        code += milliseconds[randomIndex - alphabets?.length];
      }
    }
    return code;
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={"10.5px"} pb={3} mt={-1}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  pt={2}
                  mx={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={-3}
                  py={"30px"}
                  px={"24px"}
                  bgColor="#227BE9"
                  borderRadius="13px"
                  coloredShadow="info"
                  boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)"}
                >
                  <Title sx={{ color: "#fff", fontSize: "16px", fontWeight: "600" }}>Add New Table</Title>
                </MDBox>

                {/* main body content */}

                <MDBox pt={4} pb={3} px={3} mt={-3}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDTypography fontSize={"18px"} lineHeight={"180%"} sx={{ fontWeight: "500" }}>
                        Add New Table
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={-1}>
                      <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500" }}>
                        Table Name <span style={{ color: "red" }}>*</span>
                      </MDTypography>
                      <TextField
                        name=""
                        id=""
                        value={tableData.name}
                        onChange={(e) => {
                          setTableData({
                            ...tableData,
                            name: e.target.value,
                          });
                        }}
                        className="skillsName"
                        style={{ width: "50%" }}
                      />
                      <MDTypography fontSize={"15px"} lineHeight={"120%"} sx={{ fontWeight: 400, color: "black", width: "46%", marginTop: 0 }}>
                        The name is how it appears on your site
                      </MDTypography>
                    </MDBox>

                    <MDBox mt={2}>
                      <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: 500 }}>
                        Description (Optional)
                      </MDTypography>
                      <TextField
                        id="outlined-multiline-static"
                        label=""
                        InputLabelProps={{ shrink: false }}
                        multiline
                        rows={5}
                        sx={{ width: "50%" }}
                        value={tableData.description}
                        onChange={(e) => setTableData({ ...tableData, description: e.target.value })}
                      />
                      <MDTypography fontSize={"15px"} lineHeight={"120%"} sx={{ fontWeight: 400, color: "black", width: "46%", marginTop: 1 }}>
                        A description of teh contents of your table:
                      </MDTypography>
                    </MDBox>

                    <MDBox mt={2}>
                      <Grid space={2}>
                        <div style={{ display: "flex", flexDirection: "row", width: "50%" }}>
                          <MDBox mt={-1}>
                            <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500" }}>
                              Number of Rows
                            </MDTypography>
                            <TextField
                              type="number"
                              name=""
                              id=""
                              inputProps={{ min: 0 }}
                              value={table.rows}
                              className="skillsName"
                              style={{ width: "95%" }}
                              onChange={(e) => {
                                setTable({ ...table, rows: e.target.value });
                              }}
                            />
                            <MDTypography fontSize={"15px"} lineHeight={"120%"} sx={{ fontWeight: 400, color: "black", width: "100%", marginTop: 0 }}>
                              The number of rows in your table
                            </MDTypography>
                          </MDBox>

                          <MDBox mt={-1}>
                            <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500" }}>
                              Number of Columns
                            </MDTypography>
                            <TextField
                              type="number"
                              value={table.cols}
                              inputProps={{ min: 0 }}
                              className="skillsName"
                              style={{ width: "98%" }}
                              onChange={(e) => {
                                setTable({ ...table, cols: e.target.value });
                              }}
                            />
                            <MDTypography fontSize={"15px"} lineHeight={"120%"} sx={{ fontWeight: 400, color: "black", width: "100%", marginTop: 0 }}>
                              The number of columns in your table
                            </MDTypography>
                          </MDBox>
                        </div>
                      </Grid>
                    </MDBox>
                    <div>
                      <MDButton onClick={handleAddTable} sx={{ backgroundColor: "#227BE9", "&:hover": { backgroundColor: "#227BE9" }, marginTop: 2 }}>
                        <MDTypography sx={{ color: "#fff", fontSize: 15, fontWeight: "bold" }}>Add New Table</MDTypography>
                      </MDButton>
                      <div style={{ marginLeft: -10, marginTop: -5 }}></div>
                    </div>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default AddTable;
