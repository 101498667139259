import React, { useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import OTPInput, { ResendOTP } from "otp-input-react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import back from "../../../assets/images/Back.png";
import authmain from "../../../assets/images/authmain.png";
import { Button } from "@mui/material";
import { CheckResetCode } from "Apis/Apis";

function ResetPassword() {
  const [OTP, setOTP] = useState("");

  const navigate = useNavigate();

  const renderButton = (buttonProps) => {
    return (
      <div {...buttonProps} style={{ cursor: "pointer" }}>
        <p style={{ color: "#EF4F4E", fontSize: 15, fontWeight: "500" }}>Resend OTP</p>
      </div>
    );
  };
  const renderTime = (remainingTime) => {
    return (
      <div>
        <p style={{ color: "orange", fontSize: 13, fontWeight: 600, marginRight: 10, marginLeft: 10, marginTop: 1 }}>{remainingTime} seconds</p>
      </div>
    );
  };

  return (
    <BasicLayout image={bgImage}>
      <Card sx={{ width: 470, marginLeft: -2, borderRadius: 5 }}>
        <MDBox pt={3} pb={3} px={2}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/authentication/forgot-password");
              }}
            >
              <img src={back} style={{ width: 40, height: 40 }} />
            </div>
            <p style={{ color: "#19114A", fontWeight: "400", fontSize: 15, marginTop: 7.5, marginLeft: -2 }}>Reset Code</p>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={authmain} />
          </div>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 2.5 }}>
            <p style={{ fontSize: 34, fontWeight: "500", color: "#19114A" }}>Reset Your Password</p>
          </div>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 5 }}>
            <p style={{ fontSize: 15, fontWeight: "400", color: "#888888", textAlign: "center", display: "flex", paddingLeft: 10, paddingRight: 10 }}>
              Reset code sent to <strong style={{ marginLeft: 3, color: "black" }}>{window.location.search.split("?email=")}</strong>
            </p>
          </div>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 20 }}>
            <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} secure />
            <div style={{ marginTop: 10, display: "flex", flexDirection: "row" }}>
              <p style={{ fontSize: 15, fontWeight: "400", color: "#888888" }}>Didn&apos;t receive the OTP ?</p>
              <div style={{ marginLeft: 2 }}>
                <ResendOTP renderButton={renderButton} renderTime={renderTime} />
              </div>
            </div>
            <Button
              color="primary"
              sx={{
                "&:hover": {
                  backgroundColor: "#227BE9",
                  boxShadow: "none",
                },
                "&:active": {
                  boxShadow: "none",
                  backgroundColor: "#227BE9",
                },
                backgroundColor: "#49A3F1",
                padding: 1,
                width: "40%",
                borderRadius: 20,
                marginTop: 2,
                marginBottom: 4,
              }}
              onClick={() => {
                CheckResetCode(window.location.search.split("?email=")[1], OTP).then((e) => {
                  if (e.data.is_valid) {
                    navigate(`/authentication/new-password?email=${window.location.search.split("?email=")[1]}&code=${OTP}`);
                  } else {
                    alert("Code not correct");
                  }
                });
              }}
            >
              <MDTypography color={"white"} sx={{ display: "flex", textAlign: "center", fontSize: 14, fontWeight: "700", color: "white" }}>
                Confirm Code
              </MDTypography>
            </Button>
          </div>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetPassword;
