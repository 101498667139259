import { TableCell } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Tablecell = ({ columnIndex, rowIndex, cell, handleChangeInCell, isSelected, onselect }) => {
  const [cellType, setCellType] = useState(false);
  const handleCellDoubleClick = () => {
    setCellType(true);
  };
  const handleChange = (e) => {
    handleChangeInCell(rowIndex, columnIndex, e.target.value, cell.link);
  };
  const handleCellKeyPress = (e) => {
    if (e.key === "Enter") {
      setCellType(false);
    }
  };
  const handleCellClick = (r, c, obj) => {
    onselect(r, c, obj);
  };
  const cellStyle = {
    border: `${isSelected ? "2px solid #3A94EE" : "0.5px solid black"}`,
    minWidth: "225px",
    height: "40px",
    padding: "0px",
    color: "black",
  };
  const inputStyle = {
    height: "100%",
    width: "100%",
    margin: "0",
    padding: "5px",
    fontSize: "18px",
    textAlign: "center",
    border: "1px solid",
  };
  return (
    <TableCell onClick={() => handleCellClick(rowIndex, columnIndex, cell)} onDoubleClick={handleCellDoubleClick} style={cellStyle}>
      {cellType ? (
        <input style={inputStyle} type="text" value={cell?.text} onChange={(e) => handleChange(e)} onKeyDown={(e) => handleCellKeyPress(e)} />
      ) : cell?.link ? (
        <Link to={"#"} className="table_cell_text" style={{ color: "blue" }}>
          {cell?.text}
        </Link>
      ) : (
        <p className="table_cell_text">{cell?.text}</p>
      )}
    </TableCell>
  );
};

export default Tablecell;
