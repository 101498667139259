export const filters = [
  {
    id: 1,
    title: "All",
  },
  {
    id: 2,
    title: "Mine",
  },
  {
    id: 3,
    title: "Published",
  },
  {
    id: 4,
    title: "Draft",
  },
  {
    id: 5,
    title: "Pending",
  },
  {
    id: 6,
    title: "Trash",
  },
];
