import { FILE_TYPES } from "../../../utils/common";
import React from "react";
import { AiOutlineFileUnknown } from "react-icons/ai";

/**
 * FileIcon component displays an icon based on the provided mimetype.
 *
 * @component
 *
 * @param {Object} props - The component's properties.
 * @param {string} props.mimetype - The mimetype of the file to determine the icon.
 *
 * @returns {React.Element} The icon element corresponding to the mimetype.
 */
const FileIcon = ({ mimetype }) => {
  const getFileIcon = (mimetype) => {
    const currentFile = FILE_TYPES.find((ft) => ft.mimetype === mimetype);
    return currentFile?.icon || <AiOutlineFileUnknown fontSize={25} />;
  };
  return <>{getFileIcon(mimetype)}</>;
};

export default FileIcon;
