import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import MDBox from "components/MDBox";
import { Button, Card, CircularProgress, FormControl, FormControlLabel, Grid, Input, Modal, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { useFetch } from "hooks/useFetch";
import { BASE_PATH } from "utils";
import { axiosRequestPromise } from "Apis";
import { toast } from "react-hot-toast";
import profileicon from "assets/images/icons/profile.png";
import MDAvatar from "components/MDAvatar";
import getFullName from "utils/getFullName";
import backgroundImage from "assets/images/bg-profile.jpeg";

function PayoutDetails() {
  const { id } = useParams();
  const [status, setStatus] = useState("pending");
  const [declineReasonOpen, setDeclineReasonOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  const { data, loading, refetch } = useFetch(`${BASE_PATH}/withdraw/payoneer/${id}/single`);

  const withdrawal = data?.data;
  const profile = data?.data?.profile;

  useEffect(() => {
    if (withdrawal) {
      setStatus(withdrawal?.status);
    }
  }, [withdrawal]);

  const handleStatusChange = async (status) => {
    try {
      toast.loading("Changing status", { id: "payout_change_status" });
      await axiosRequestPromise({
        method: "put",
        url: `${BASE_PATH}/withdraw/payoneer/${id}/change-status`,
        body: {
          status,
          reason: status === "declined" ? rejectionReason : undefined,
        },
      });
      refetch();
      setStatus(status);
      toast.success("Status changed", { id: "payout_change_status" });
      setDeclineReasonOpen(false);
      setRejectionReason("");
      if (status === "declined") {
        setStatus("declined");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong", { id: "payout_change_status" });
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "1px solid #A9A8A8",
    boxSizing: "border-box",
    boxShadow: 24,
    borderRadius: "12px",
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {declineReasonOpen && (
        <Modal aria-labelledby="decline-modal" aria-aria-describedby="decline-modal-description" open={declineReasonOpen} onClose={() => setDeclineReasonOpen(false)}>
          <Box sx={style}>
            <Typography sx={{ fontSize: "24px" }}>Decline Reason</Typography>
            <textarea
              style={{ width: "100%", height: "100px", border: "1px solid lightgray", borderRadius: "8px", outline: "none", padding: "10px", marginTop: "10px" }}
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
              placeholder="Enter rejection reason"
            />
            <Stack direction={"row"} gap={"20px"} justifyContent={"flex-end"}>
              <Button
                variant="outlined"
                onClick={() => setDeclineReasonOpen(false)}
                sx={{
                  color: "#000",
                  border: "1px solid lightgray",
                  "&:hover": {
                    color: "black",
                    border: "1px solid lightgray",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleStatusChange("declined")}
                sx={{
                  color: "#fff",
                  "&:hover": {
                    color: "black",
                  },
                }}
                variant="contained"
              >
                Submit
              </Button>
            </Stack>
          </Box>
        </Modal>
      )}

      <MDBox position="relative" mb={5} mt={-2}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(rgba(gradients.info.main, 0.6), rgba(gradients.info.state, 0.6))}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          {!loading && withdrawal?._id && (
            <>
              <Grid container spacing={3} alignItems="center" style={{ display: "flex", justifyContent: "space-between" }}>
                <Grid item>
                  <MDBox style={{ display: "flex", flexDirection: "row" }}>
                    <MDAvatar src={profile?.image?.src || profileicon} alt="profile-image" size="xl" shadow="sm" />
                    <MDBox height="100%" mt={0.5} lineHeight={1} sx={{ marginLeft: 2, textTransform: "capitalize" }}>
                      <MDTypography variant="h5" fontWeight="medium">
                        {getFullName(profile?.user?.first_name, profile?.user?.last_name)}
                      </MDTypography>

                      <MDTypography variant="button" color="text" fontWeight="regular">
                        <span style={{ textTransform: "capitalize" }}>Freelancer</span>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox mt={"35px"} mb={"28px"} mx={-2}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                  <MDBox mt={-1} mx={3}>
                    <MDTypography sx={{ fontSize: 20, fontWeight: "700" }}>Profile Information</MDTypography>
                  </MDBox>
                  <MDBox mt={0} mx={3}>
                    <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                      <strong>Full Name : </strong> {profile?.user?.first_name} {profile?.user?.last_name}
                    </MDTypography>
                    <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                      <strong>Payoneer Email : </strong> {withdrawal?.email}
                    </MDTypography>{" "}
                    <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                      <strong>Freelancer ID : </strong> {profile?._id}
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={2} mx={3}>
                    <MDTypography sx={{ fontSize: 20, fontWeight: "700" }}>Payout</MDTypography>
                  </MDBox>
                  <MDBox mt={0} mx={3}>
                    <MDTypography sx={{ fontSize: 15, fontWeight: "400" }} mt={1}>
                      <strong>Amount : </strong> {withdrawal?.amount?.toFixed(2)}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={2} display="flex" alignItems="center" mt={-2} py={3} mx={2}>
                    <FormControl>
                      {/* <FormLabel id="demo-row-radio-buttons-group-label">Filter Users</FormLabel> */}
                      <RadioGroup
                        value={status}
                        onChange={(e) => {
                          if (e.target.value !== "declined") {
                            setStatus(e.target.value);
                          }
                        }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          sx={{ marginLeft: "3px", fontSize: "13px" }}
                          value="pending"
                          checked={status === "pending"}
                          onChange={(e) => {
                            handleStatusChange(e.target.value);
                          }}
                          control={<Radio />}
                          label={
                            <Box component="div" fontSize={13} fontWeight={400} marginLeft={-1}>
                              Pending
                            </Box>
                          }
                        />

                        <FormControlLabel
                          sx={{ marginLeft: "-10px", fontSize: "13px" }}
                          value="completed"
                          checked={status === "completed"}
                          onChange={(e) => {
                            handleStatusChange(e.target.value);
                          }}
                          control={<Radio />}
                          label={
                            <Box component="div" fontSize={13} fontWeight={400} marginLeft={-1}>
                              Completed
                            </Box>
                          }
                        />
                        <FormControlLabel
                          sx={{ marginLeft: "-10px", fontSize: "13px" }}
                          value="declined"
                          checked={status === "declined"}
                          onChange={(e) => {
                            setDeclineReasonOpen(e.target.value);
                          }}
                          control={<Radio />}
                          label={
                            <Box component="div" fontSize={13} fontWeight={400} marginLeft={-1}>
                              Declined
                            </Box>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </MDBox>
                </div>
              </MDBox>
            </>
          )}
          {loading && (
            <div style={{ width: "100%", height: "300px", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CircularProgress sx={{ marginX: "auto" }} />
            </div>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default PayoutDetails;
