import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DynamicTable from "./DynamicTable";
import { GetTablesById } from "Apis/Apis";
import { useNavigate, useParams } from "react-router-dom";
import { UpdateTable } from "Apis/Apis";
import { toast } from "react-hot-toast";

function CreateTable() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  useEffect(() => {
    fetchTable(id);
  }, [id]);
  const fetchTable = async (id) => {
    try {
      let res = await GetTablesById(id);
      if (res.success) {
        setData(res.data);
      } else {
        toast.error("Something went wrong while fetching table data");
        navigate("/Tables");
      }
    } catch (err) {
      toast.error("Something went wrong while fetching table data");
    }
  };
  const updateTableData = async (dta) => {
    const newData = { name: data?.name, short_code: data?.short_code, data: dta, description: data.description };
    let res = await UpdateTable(id, newData);
    if (res.success) {
      setData(res.data);
      toast.success("Table Updated successfully.");
      navigate("/Tables");
    } else {
      toast.error("Something went wrong while updating table !");
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={"10.5px"} pb={3} mt={-1}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  pt={2}
                  mx={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={-3}
                  py={"31px"}
                  px={"24px"}
                  bgColor="#227BE9"
                  borderRadius="13px"
                  coloredShadow="info"
                  boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.14), 0px 7px 10px -5px rgba(0, 187, 212, 0.4)"}
                >
                  <MDTypography color="white" variant="h6" fontWeight="medium">
                    Update Table
                  </MDTypography>
                </MDBox>

                {/* main body content */}

                <MDBox pt={4} pb={3} px={3} mt={-3}>
                  <MDBox component="form" role="form">
                    <MDBox mb={2}>
                      <MDBox mb={2}>
                        <MDTypography fontSize={"18px"} lineHeight={"180%"} sx={{ fontWeight: "500" }}>
                          Table Information
                        </MDTypography>

                        <MDBox mt={2}>
                          <Grid space={2}>
                            <div style={{ display: "flex", flexDirection: "row", width: "50%" }}>
                              <MDBox mt={-1}>
                                <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500" }}>
                                  Table ID
                                </MDTypography>
                                <TextField
                                  name=""
                                  id=""
                                  value={data?._id}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  className="skillsName"
                                  style={{ width: "95%" }}
                                />
                                <MDTypography fontSize={"15px"} lineHeight={"120%"} sx={{ fontWeight: 400, color: "black", width: "90%", marginTop: 0 }}>
                                  The name is how it appears on your site.
                                </MDTypography>
                              </MDBox>

                              <MDBox mt={-1}>
                                <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500" }}>
                                  Short Code
                                </MDTypography>
                                <TextField
                                  name=""
                                  id=""
                                  value={data?.short_code}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  className="skillsName"
                                  style={{ width: "98%" }}
                                />
                                <MDTypography fontSize={"15px"} lineHeight={"120%"} sx={{ fontWeight: 400, color: "black", width: "90%", marginTop: 0 }}>
                                  The shortcode is how it appears on your site.
                                </MDTypography>
                              </MDBox>
                            </div>
                          </Grid>
                        </MDBox>
                      </MDBox>
                      <MDTypography fontSize={"18px"} lineHeight={"180%"} sx={{ fontWeight: "500" }}>
                        Add New Table
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={-1}>
                      <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: "500" }}>
                        Table Name
                      </MDTypography>
                      <TextField
                        name=""
                        id=""
                        value={data?.name}
                        onChange={(e) => {
                          setData({
                            ...data,
                            name: e.target.value,
                          });
                        }}
                        className="skillsName"
                        style={{ width: "50%" }}
                      />
                      <MDTypography fontSize={"15px"} lineHeight={"120%"} sx={{ fontWeight: 400, color: "black", width: "46%", marginTop: 0 }}>
                        The name is how it appears on your site
                      </MDTypography>
                    </MDBox>

                    <MDBox mt={2}>
                      <MDTypography fontSize={"15px"} lineHeight={"180%"} sx={{ fontWeight: 500 }}>
                        Description (Optional)
                      </MDTypography>
                      <TextField
                        id="outlined-multiline-static"
                        label=""
                        InputLabelProps={{ shrink: false }}
                        multiline
                        rows={5}
                        sx={{ width: "50%" }}
                        value={data?.description}
                        onChange={(e) => setData({ ...data, description: e.target.value })}
                      />
                      <MDTypography fontSize={"15px"} lineHeight={"120%"} sx={{ fontWeight: 400, color: "black", width: "46%", marginTop: 1 }}>
                        A description of teh contents of your table:
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDTypography px={3} mt={"10px"} fontSize={"16px"} sx={{ fontWeight: 500, height: "48px", border: "1px solid #A9A8A8", display: "flex", alignItems: "center", borderBottom: "none" }}>
                  Table Information
                </MDTypography>
                {data && <DynamicTable data={data?.data} getTableData={updateTableData} />}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}

export default CreateTable;
