import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect } from "react";
import "./support.css";
import ChatList from "./chatList";
import ChatInfo from "./chatInfo";
import Chat from "./chat";
import { useFetch } from "hooks/useFetch";
import { BASE_PATH } from "utils";
import usePagination from "hooks/usePagination";
import { useSearchParams } from "react-router-dom";
import { supportTabs, timingOptions } from "./data";

const Support = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { page, limit, setPage, setLimit } = usePagination(undefined, { searchParams, setSearchParams });
  const { data, loading } = useFetch(`${BASE_PATH}/tickets?offset=${page * limit}&limit=${limit || 10}`);
  const ticketId = searchParams.get("ticketId");
  const tab = searchParams.get("tab");
  const search = searchParams.get("search");
  const timing = searchParams.get("timing");
  const setSearch = (value) => {
    searchParams.set("search", value);
    setSearchParams(searchParams);
  };
  const setTab = (value) => {
    searchParams.set("tab", value);
    setSearchParams(searchParams);
  };
  const setTiming = (value) => {
    searchParams.set("timing", value);
    setSearchParams(searchParams);
  };
  const handleSetChat = (ticketId) => {
    searchParams.set("ticketId", ticketId);
    setSearchParams(searchParams);
  };
  useEffect(() => {
    if (!tab) {
      setTab(supportTabs[0].value);
    }
    if (!timing) {
      setTiming(timingOptions[0].value);
    }
  }, [tab, timing]);
  const { data: chatData, loading: chatDataLoading, refetch: chatRefetch } = useFetch(`${BASE_PATH}/tickets/${ticketId}`);
  console.log({ data, chatData });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="supportLayout">
        <ChatList
          tab={tab}
          setTab={setTab}
          data={data}
          loading={loading}
          page={page}
          limit={limit}
          handleSetChat={handleSetChat}
          search={search}
          setSearch={setSearch}
          timing={timing}
          setTiming={setTiming}
          setPage={setPage}
          setLimit={setLimit}
        />
        <Chat data={chatData} loading={chatDataLoading} ticketId={ticketId} refetch={chatRefetch} />
        <ChatInfo data={chatData} loading={chatDataLoading} ticketId={ticketId} refetch={chatRefetch} />
      </div>
    </DashboardLayout>
  );
};

export default Support;
