import { AddIcCall, Email, Work } from "@mui/icons-material";
import { Box, Button, CircularProgress, Stack, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { priorities } from "../data";
import getFullName from "utils/getFullName";
import { axiosRequestPromise } from "Apis";
import { BASE_PATH } from "utils";
import { Toast } from "components/common/Toast";
import userImg from "assets/images/user.png";

const ChatInfo = ({ data, loading, ticketId }) => {
  const chat = data?.data;
  const [priority, setPriority] = useState(priorities[0].label);
  const [toastData, setToastData] = useState({ open: false, message: "", type: "" });
  const [resolved, setResolved] = useState(false);
  const handleSetPriority = async (e) => {
    const value = e?.target?.value;
    const lastValue = priority?.slice();
    setPriority(value);
    try {
      const res = await axiosRequestPromise({ method: "put", url: `${BASE_PATH}/tickets/${ticketId}/priority`, body: { priority: value } });
      if (res?.data?.success) {
        setToastData({ open: true, message: res?.data?.message, type: "success" });
      }
    } catch (error) {
      setPriority(lastValue);
      setToastData({ open: true, message: error.response?.data?.message, type: "error" });
    }
  };
  useEffect(() => {
    setPriority(chat?.priority);
    setResolved(chat?.status === "closed");
  }, [chat]);
  const handleResolveTicket = async (e, checked) => {
    try {
      if (!checked) {
        setToastData({ open: true, message: "You can't open a closed ticket", type: "error" });
        return;
      }
      setResolved(true);
      const res = await axiosRequestPromise({ url: `${BASE_PATH}/tickets/${ticketId}/close`, method: "post" });
      if (res?.data?.success) {
        setToastData({ open: true, message: res?.data?.message, type: "success" });
      }
    } catch (error) {
      setResolved(false);
      setToastData({ open: true, message: error?.response?.data?.message, type: "error" });
    }
  };
  return (
    <div className="chatInfo">
      {loading ? (
        <Box sx={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "Center" }}>
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          <Toast open={toastData.open} message={toastData.message} type={toastData.type} onClose={() => setToastData({ open: "", message: "", type: "" })} />
          <Box sx={{ width: "100%", height: "72px", borderBottom: "1px solid #8d8888" }}></Box>
          <Box sx={{ flexGrow: 1, padding: "10px" }}>
            <Stack spacing={"8px"} direction={"row"} alignItems={"center"} justifyContent={"flex-start"} mb={"10px"}>
              <img style={{ width: "40px", height: "40px", objectFit: "cover", borderRadius: "50%" }} src={chat?.created_by?.profiles?.[0]?.image?.src || userImg} />
              <Typography sx={{ fontSize: "15px", fontWeight: "500", color: "#19114A" }}>{getFullName(chat?.created_by?.first_name, chat?.created_by?.last_name)}</Typography>
            </Stack>
            <Stack spacing={"8px"} direction={"row"} alignItems={"center"} justifyContent={"flex-start"} mb={"10px"}>
              <Email />{" "}
              <Typography className="font-inter" sx={{ fontSize: "13px", fontWeight: "500", color: "#227BE9" }}>
                {chat?.created_by?.email}
              </Typography>
            </Stack>
            <Stack spacing={"8px"} direction={"row"} alignItems={"center"} justifyContent={"flex-start"} mb={"10px"}>
              <AddIcCall />{" "}
              <Typography className="font-inter" sx={{ fontSize: "13px", fontWeight: "500", color: "#19114A" }}>
                {chat?.created_by?.profiles?.[0]?.address?.phone}
              </Typography>
            </Stack>
            <Stack spacing={"8px"} direction={"row"} alignItems={"center"} justifyContent={"flex-start"}>
              <Work />{" "}
              <Typography className="font-inter" sx={{ fontSize: "13px", fontWeight: "500", color: "#19114A" }}>
                Freelancer ID : {chat?.created_by?._id}
              </Typography>
            </Stack>
            <Button variant="contained" sx={{ marginTop: "10px", color: "#FFF", display: "block", width: "70%" }}>
              Send Notification
            </Button>
            <select
              style={{ width: "70%", height: "40px", borderRadius: "5px", border: "1px solid #A9A8A8", paddingLeft: "10px", paddingRight: "10px", outline: "none", marginTop: "10px" }}
              label="Select Priority"
              placeholder="Select Priority"
              value={priority}
              onChange={handleSetPriority}
            >
              {priorities.map((p) => (
                <option key={p.value} value={p.value}>
                  {p.label}
                </option>
              ))}
            </select>
            <Stack direction={"row"} spacing={"10"} alignItems={"center"}>
              <Switch checked={resolved} onChange={handleResolveTicket} />{" "}
              <Typography className="font-inter" sx={{ fontSize: "14px", fontWeight: "400", color: "#19114A" }}>
                Ticket Resolved
              </Typography>
            </Stack>
          </Box>
        </>
      )}
    </div>
  );
};

export default ChatInfo;
